import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { isLogged } from "../app/features/auth/authSlice";
import { useGetProjectQuery, useGetUserProfileQuery } from "../app/features/apisSlice";
import PdContent from "../components/PdContent";
import PdHeader from "../components/PdHeader";
import PdHeaderNft from "../components/PdHeaderNft";
import PdInvestNow from "../components/PdInvestNow";
import PdNft from "../components/PdNft";
import ProjectRightBar from "../components/ProjectRightBar";
import Loader from "../components/Loader";
import ErrorPage from "../components/ErrorPage";
import style from "./style.module.css";
import ProjectNews from "../components/news/ProjectNews";
import { useTranslation } from "react-i18next";
import { selectDefaultLang } from "../app/features/extraReducers";

const ProjectDetails = () => {
  const lang = useSelector(selectDefaultLang)
  const [isKyced, setIsKyced] = useState(false);
  const [showInvestTab, setShowInvestTab] = useState(false);
  const [showNewsTab, setShowNewsTab] = useState(false);
  const [showNftInvestTab, setShowNftInvestTab] = useState(false);
  const [nftId, setNftId] = useState();
  const nftTabRef = useRef(null);
  const investTabRef = useRef(null);
  const sessionId = useRef(Date.now()).current;
  const isLoggedIn = useSelector(isLogged);
  const { slug } = useParams();
  const navItems = [];
  const [t] = useTranslation();

  const { data, isLoading, isSuccess, isError, error, status } = useGetProjectQuery({ slug, sessionId });
  const projectData = data?.projectData;
  const nftData = data?.nftData;
  const newsData = data?.newsData;

  const checkBrokenImage = (event) => {
    event.target.style.display = "none";
    event.onerror = null;
  };

  const { data: userData, isLoading: userIsLoading } = useGetUserProfileQuery({ sessionId });

  // Checked if isKyced
  useEffect(() => {
    !userIsLoading &&
      userData?.kycStatus &&
      userData?.kycStatus?.kyc_status !== "REJECTED"
      ? setIsKyced(true)
      : setIsKyced(false);
  }, [userData, userIsLoading]);

  let showInvest = true;
  if (!isLoading) {
    const loginUrl = `/${lang}/login`
    const signUpUrl = `/${lang}/signup`
    if (projectData?.type === "NFT") showInvest = false;
    if (projectData?.company)
      navItems.push({
        title: t("project.company"),
        content: projectData?.company,
        dataRoom: false,
      });
    if (projectData?.campaign)
      navItems.push({
        title: t("project.campaign"),
        content: projectData?.campaign,
        dataRoom: false,
      });
    if (projectData?.team)
      navItems.push({
        title: t("project.team"),
        content: projectData?.team,
        dataRoom: false,
      });
    if (projectData?.terms) {
      let content = isLoggedIn
        ? projectData?.terms
        : `<h4 class="pb-3">${t("please")} <a href=${signUpUrl} >${t("register")}</a>/<a href=${loginUrl} >${t("login")}</a> ${t("toViewContent")}</h4>`;
      navItems.push({
        title: t("project.T&C"),
        content,
        dataRoom: false,
      });
    }
    if (data?.dataRoom && data?.dataRoom.length > 0) {
      let content = isLoggedIn
        ? data?.dataRoom
        : `<h4 class="pb-3">${t("please")} <a href=${signUpUrl} >${t("register")}</a>/<a href=${loginUrl} >${t("login")}</a> ${t("toViewContent")}</h4>`;
      navItems.push({
        title: t("project.dataRoom"),
        content,
        dataRoom: true,
      });
    }
  }
  if (!isLoading && showInvestTab) {
    if (projectData?.type === t("invest.nft") && !showNftInvestTab)
      nftTabRef.current.click();
    else investTabRef.current.click();
  }
  if (showNftInvestTab) {
    investTabRef.current.click();
    showInvest = true;
  }

  if (isLoading || status === "pending") return <Loader />;
  if (isError) {
    return (
      <div className="pageContent">
        <ErrorPage error={error?.data?.error} />
      </div>
    );
  }

  if (isSuccess) {
    return (
      <>
        {!userIsLoading &&
          (projectData?.type === "NFT" ? (
            <PdHeaderNft
              project={projectData}
              nftData={nftData}
              setShowInvestTab={setShowInvestTab}
              isKyced={isKyced}
            />
          ) : (
            <PdHeader
              project={projectData}
              setShowInvestTab={setShowInvestTab}
              isKyced={isKyced}
            />
          ))}
        <div className="pageContent">
          <Tab.Container defaultActiveKey={`0`}>
            <Container>
              <Nav variant="pills" className={`${style.navPills}`}>
                {navItems.map((item, i) => {
                  return (
                    <Nav.Item
                      key={i}
                      onClick={() => {
                        setShowInvestTab(false);
                      }}
                    >
                      <Nav.Link eventKey={i} className={`${style.navLink}`}>
                        {item.title}
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}

                {newsData.count > 0 && (
                  <Nav.Item>
                    <Nav.Link
                      eventKey="news"
                      className={`${style.navLink}`}
                      onClick={() => setShowNewsTab(true)}
                    >
                      {t("header.news")}
                    </Nav.Link>
                  </Nav.Item>
                )}

                {projectData?.type === "NFT" && (
                  <Nav.Item>
                    <Nav.Link
                      eventKey="nft"
                      className={`${style.navLink}`}
                      ref={nftTabRef}
                      onClick={() => {
                        setShowInvestTab(false);
                        setShowNftInvestTab(false);
                      }}
                    >
                      {t("project.NFTs")}
                    </Nav.Link>
                  </Nav.Item>
                )}
                {isLoggedIn && isKyced && (
                  <Nav.Item className={`${!showInvest ? "d-none" : ""}`}>
                    <Nav.Link
                      eventKey="invest"
                      className={`${style.navLink}`}
                      ref={investTabRef}
                    >
                      {t("investNow")}
                    </Nav.Link>
                  </Nav.Item>
                )}
                {projectData?.logo?.file_path && (
                  <Nav.Item
                    className={`ms-auto d-none d-md-block ${style.navProjectIcon}`}
                  >
                    <img
                      src={projectData?.logo?.file_path}
                      className="img-fluid"
                      onError={checkBrokenImage}
                      title={projectData.projectName}
                      alt={projectData.projectName}
                    />
                  </Nav.Item>
                )}
              </Nav>

              <Row className="mt-5">
                <Col lg="9">
                  <Tab.Content>
                    {navItems.map((item, i) => {
                      return (
                        <Tab.Pane eventKey={i} key={i}>
                          <PdContent
                            content={item.content}
                            dataRoom={item.dataRoom}
                          />
                        </Tab.Pane>
                      );
                    })}
                    {showNewsTab && (
                      <Tab.Pane eventKey="news">
                        <ProjectNews data={newsData} />
                      </Tab.Pane>
                    )}
                    {projectData?.type === "NFT" && (
                      <Tab.Pane eventKey="nft">
                        {isLoggedIn ? (
                          <PdNft
                            nfts={nftData}
                            setShowNftInvestTab={setShowNftInvestTab}
                            setNftId={setNftId}
                            isKyced={isKyced}
                            projectData={projectData}
                          />
                        ) : (
                          <h4 class="pb-3">
                            {t("Please")} <a href={`/${lang}/signup`}>{t("register")}</a>/
                            <a href={`/${lang}/login`}>{t("login")}</a> {t("toViewContent")}
                          </h4>
                        )}
                      </Tab.Pane>
                    )}
                    {isLoggedIn && isKyced && (
                      <Tab.Pane eventKey="invest">
                        <PdInvestNow data={projectData} nftId={nftId} />
                      </Tab.Pane>
                    )}
                  </Tab.Content>
                </Col>
                {projectData?.companyInformation && (
                  <Col lg="3">
                    <ProjectRightBar project={projectData} />
                  </Col>
                )}
              </Row>
            </Container>
          </Tab.Container>
        </div>
      </>
    );
  }
};

export default ProjectDetails;
