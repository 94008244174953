import React, { useState } from "react";
import { Form, Button, Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useUpdateLanguageMutation } from "../app/features/apisSlice";

const ProfileSetting = ({ userData, refetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [language, setLanguage] = useState(userData?.profileData?.language);
  const [t] = useTranslation();

  const [updateLanguage] = useUpdateLanguageMutation();
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg(null);
    setSuccessMsg(null);

    if (language === userData?.profileData?.language) {
      return false;
    }

    setIsLoading(true);

    try {
      const response = await updateLanguage({ language });
      if (response?.data?.user?.language === language) {
        let message = `languageChangedTo${language}`;
        setSuccessMsg(t(`profile.${message}`));
        refetch();
      }
    } catch (err) {
      console.log(err);
      if (!err) setErrorMsg(t("error.noServerResponse"));
      else if (err?.data?.message) setErrorMsg(err?.data?.message);
      else setErrorMsg(t("error.failedToUpdateLanguage"));
    }
    setIsLoading(false);
  };
  return (
    <Card className="mt-4 p-4 shadow">
      <Card.Body>
        <Form onSubmit={handleOnSubmit}>
          <Form.Group>
            <Form.Label>{t("preferredLanguage")}</Form.Label>
            <br />
            <Form.Check
              inline
              label={t("english")}
              name="preferred-language"
              value="en"
              type="radio"
              checked={language === "en" ? true : false}
              onChange={(e) => setLanguage(e.target.value)}
            />
            <Form.Check
              inline
              label={t("italian")}
              value="it"
              name="preferred-language"
              type="radio"
              checked={language === "it" ? true : false}
              onChange={(e) => setLanguage(e.target.value)}
            />
          </Form.Group>
          <Form.Text id="passwordHelpBlock" muted>
            {t("preferredLanguageText")}
          </Form.Text>{" "}
          <br />
          <Button
            variant="primary"
            type="submit"
            className="submitBtnPrimary"
            disabled={isLoading}
          >
            {t("submit")}{" "}
            <Spinner
              className={`${!isLoading ? "d-none" : ""}`}
              variant="light"
              size="sm"
              role="status"
              aria-hidden="true"
              animation="border"
            />
          </Button>
          {successMsg && (
            <p className="text-success" aria-live="assertive">
              {successMsg}
            </p>
          )}
          {errorMsg && (
            <p className="text-danger" aria-live="assertive">
              {errorMsg}
            </p>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ProfileSetting;
