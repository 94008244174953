import React, { useState } from "react";
import ProjectNewsContent from "./ProjectNewsContent";
import { Row, Col } from "react-bootstrap";
import style from "../style.module.css";

const ProjectNews = ({ data }) => {
  const [showContent, setShowContent] = useState(false);
  const [content, setContent] = useState();
  return (
    <>
      <div className="project-text">
        {!showContent && (
          <>
            {data.rows.map((item, i) => (
              <div key={i}>
                <Row
                  onClick={() => {
                    setShowContent(true);
                    setContent(item);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Col md={!item.image ? "12" : "8"}>
                    <h5>{item.title}</h5>
                    <p>{item.summary}...</p>
                    <small>{new Date(item.createdAt).toDateString()}</small>
                  </Col>
                  {item.image && (
                    <Col md="4">
                      <div className={`${style.imgContainerNews}`}>
                        <img
                          src={item.image}
                          alt={item.title}
                          className={`${style.projectNewsImage}`}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
                <hr />
              </div>
            ))}
          </>
        )}
        {showContent && (
          <ProjectNewsContent data={content} setShowContent={setShowContent} />
        )}
      </div>
    </>
  );
};

export default ProjectNews;
