import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/styles.css";
import "./index.css";

import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { persistStore } from 'redux-persist'
import './i18n';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
// eslint-disable-next-line
const persistor = persistStore(store);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);
