import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, Table, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetOrdersQuery,
  useExecuteTradeMutation,
} from "../app/features/secondaryMarketApiSlice";
import { selectDefaultLang, setTradeData } from "../app/features/extraReducers";
import Loader from "./Loader";
import ErrorPage from "./ErrorPage";
import Swal from "sweetalert2";
import image from "../assets/img/avatar_xs.png";
import { BeNiceNumberWithCurrency, BeNiceNumber } from "../helpers/CustomHelper";
import { useTranslation } from "react-i18next";

const TradeOrdersList = ({reRender}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector(selectDefaultLang)
  const timestampRef = useRef(Date.now()).current;
  const [loading, setLoading] = useState(null);
  const { data, isLoading, isError, error, refetch } = useGetOrdersQuery({
    sessionId: timestampRef
  });
  const [t] = useTranslation();

  useEffect(() => {
    if (reRender) refetch();
  }, [reRender, refetch]);

  const Header = [t("order.user"), t("order.date"), t("order.quantity"), t("order.price"), t("order.value")];

  const confirmTradeOrder = async (data) => {
    handleConfirmAlert(function (confirmed) {
      if (confirmed) handleTrade(data);
    });
  };

  const handleConfirmAlert = async (callback) => {
    await Swal.fire({
      title: t(`order.areYouSure`),
      text: t(`order.youWantToExecuteThisTrade`),
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: t("no"),
      confirmButtonColor: "#198754",
      cancelButtonColor: "#d33",
      confirmButtonText: t("yes"),
    }).then((result) => {
      if (result.isConfirmed) callback(result.isConfirmed);
    });
  };

  const [executeTrade] = useExecuteTradeMutation();
  const handleTrade = async (data) => {
    setLoading(data.id);

    let tradeDate = {
      projectId: data.project_id,
      id: data.id,
    };
    try {
      let { trasactionObj, contractName, commission } = await executeTrade(
        tradeDate
      ).unwrap();
      if (contractName && trasactionObj && commission) {
        dispatch(
          setTradeData({
            trasactionObj,
            commission,
            user: data.user_id,
            type: data.order_type,
            account: contractName,
            ticker: data.tickerName,
            price: Number(data.value).toFixed(2),
            tradeId: data.id,
          })
        );
        navigate(`/${lang}/exchange/payment`);
      } else {
        Swal.fire(t("order.unableToExecute"), t("order.somethingIsMissing"), "error");
      }
    } catch (err) {
      console.log("Execute trade error: ", err);
      Swal.fire(t("error.noServerResponse"), "", "error");
    }

    setLoading(null);
  };

  if (isLoading) return <Loader />;
  if (isError) return <ErrorPage error={error?.data?.error} />;
  if (data?.buyOrderBook || data?.sellOrderBook) {
    return (
      <Row>
        <Col md="6" className="mb-3">
          <h5 className="text-success">{t("order.buyOrders")}</h5>
          <Card className="shadow">
            <Card.Body>
              <Table hover striped responsive>
                <thead>
                  <tr>
                    {Header.map((head, index) => {
                      return <td key={index}>{head}</td>;
                    })}
                    <th></th>
                  </tr>
                </thead>
                {(() => {
                  if (data?.buyOrderBook && data?.buyOrderBook.length > 0) {
                    return (
                      <tbody>
                        {data?.buyOrderBook.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <img
                                  src={image}
                                  alt="UserPicture"
                                  className="rounded-circle"
                                />
                              </td>
                              <td>{new Date(data.createdAt).toDateString()}</td>
                              <td>{BeNiceNumber(data.quantity)}</td>
                              <td>{BeNiceNumberWithCurrency(data.price)}</td>
                              <td>{BeNiceNumberWithCurrency(data.value)}</td>
                              <td className="text-center">
                                {loading !== data.id ? (
                                  <Button
                                    className="btn btn-primary btn-sm bg-success m-0"
                                    onClick={() => confirmTradeOrder(data)}
                                  >
                                    {t("order.trade")}
                                  </Button>
                                ) : (
                                  <Spinner
                                    variant="success"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    );
                  } else {
                    return (
                      <tbody>
                        <tr>
                          <td colSpan={6} className="text-center">{t("order.noRecordFound")}</td>
                        </tr>
                      </tbody>
                    );
                  }
                })()}
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" className="mb-3">
          <h5 className="text-danger">{t("order.sellOrders")}</h5>
          <Card className="shadow">
            <Card.Body>
              <Table hover striped responsive>
                <thead>
                  <tr>
                    {Header.map((head, index) => {
                      return <td key={index}>{head}</td>;
                    })}
                    <th></th>
                  </tr>
                </thead>
                {(() => {
                  if (data?.sellOrderBook && data?.sellOrderBook.length > 0) {
                    return (
                      <tbody>
                        {data?.sellOrderBook.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <img
                                  src={image}
                                  alt="UserPicture"
                                  className="rounded-circle"
                                />
                              </td>
                              <td>{new Date(data.createdAt).toDateString()}</td>
                              <td>{BeNiceNumber(data.quantity)}</td>
                              <td>{BeNiceNumberWithCurrency(data.price)}</td>
                              <td>{BeNiceNumberWithCurrency(data.value)}</td>
                              <td className="text-center">
                                {loading !== data.id ? (
                                  <Button
                                    className="btn btn-primary btn-sm bg-danger m-0"
                                    onClick={() => confirmTradeOrder(data)}
                                  >
                                    {t("order.trade")}
                                  </Button>
                                ) : (
                                  <Spinner
                                    variant="danger"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    );
                  } else {
                    return (
                      <tbody>
                        <tr>
                          <td colSpan={6} className="text-center">{t("order.noRecordFound")}</td>
                        </tr>
                      </tbody>
                    );
                  }
                })()}
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
};

export default TradeOrdersList;
