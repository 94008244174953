export const formatCurrencyValue =(currencyValue) =>  {
    const [currency, amount] = currencyValue.split(' ');
    const numericAmount = parseFloat(amount.replace(/[^\d.]/g, ''));
  
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    });
  
    return `${formatter.format(numericAmount)}`;
  }