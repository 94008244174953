import React from "react";
import style from "./style.module.css";

const ProjectRightBar = ({project: {projectName, companyInformation}}) => {
  return (
    <>
      <div className={style.projectRightBar}>
        <h4>{projectName}</h4>
        <div className={`mt-4 ${style.shortDescr}`}>
          {typeof companyInformation === "string" && (
            <div dangerouslySetInnerHTML={{ __html:companyInformation  }}></div>
          )}

          {typeof companyInformation === "object" && (
            <>
              {companyInformation.map((info, index) => {
                return (
                  <p key={index}>
                    {info?.label && (
                      <>
                        <strong>{info?.label}:</strong>
                        <br />
                      </>
                    )}
                    {info?.value && info.value}
                  </p>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default ProjectRightBar;
