import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Container,
  Button,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { useGetProjectQuery } from "../../app/features/apisSlice";
import { useFreezeTokenSaveOrderMutation } from "../../app/features/secondaryMarketApiSlice";
import Loader from "../../components/Loader";
import ErrorPage from "../../components/ErrorPage";
import image from "../../assets/img/avatar_xs.png";
import TradeOrdersList from "../../components/TradeOrdersList";
import Swal from "sweetalert2";
import style from "../style.module.css";
import TradingViewChartWrap from "../../components/charts/TradingViewChartWrap";
import OpenOrders from "../../components/OpenOrders";
import { useTranslation } from "react-i18next"
;
const TradeProject = () => {
  const { slug } = useParams();
  const timestampRef = useRef(Date.now()).current;
  const [loading, setLoading] = useState(false);
  const [isBuy, setIsBuy] = useState(true);
  const { data, isLoading, isError, error } = useGetProjectQuery({
    slug,
    sessionId: timestampRef,
  });
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(1.6);
  const [totalValue, setTotalValue] = useState(0);
  const handleTradeType = () => {
    isBuy ? setIsBuy(false) : setIsBuy(true);
  };
  const [isRender, setIsRender] = useState(false);
  const [freezeTokenSaveOrder] = useFreezeTokenSaveOrderMutation();
  const [t] = useTranslation();

  const handleConfirmAlert = async (callback) => {
    await Swal.fire({
      title: t("order.areYouSure"),
      text: `${t("youWant")} ${isBuy ? t("exchange.buy") : t("exchange.sell")} ${t("thisToken")}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: t("no"),
      confirmButtonColor: "#198754",
      cancelButtonColor: "#d33",
      confirmButtonText: t("yes"),
    }).then((result) => {
      if (result.isConfirmed) callback(result.isConfirmed);
    });
  };

  const handleMessageAlert = (message, type) => Swal.fire(message, "", type);

  const confirmTradeOrder = async (e) => {
    e.preventDefault();
    if (price <= 0) Swal.fire(t("exchange.valueShouldBeGreaterThanZero"));
    else
      handleConfirmAlert(function (confirmed) {
        if (confirmed) handleTradeOrder();
      });
  };

  const handleTradeOrder = async () => {
    setLoading(true);
    setIsRender(false);

    let alertMessage;
    let type;
    let formData = {
      quantity,
      amount: price,
      projectId: data?.projectData?.id,
      orderType: isBuy ? t("buy") : t("sell"),
    };

    try {
      let response = await freezeTokenSaveOrder(formData).unwrap();
      console.log(response);
      alertMessage = `${t("youWant")} ${isBuy ? t("exchange.buy") : t("exchange.sell")} ${t("thisToken")}?`
      type = "success";
      setIsRender(true);
    } catch (err) {
      console.log(err);
      type = "error";
      if (!err) alertMessage = t("error.noServerResponse");
      else if (err?.data?.message) alertMessage = err?.data?.message;
      else alertMessage = t("error.failedToAddTheOrder");
    }

    handleMessageAlert(alertMessage, type);
    setLoading(false);
  };

  useEffect(() => {
    if (quantity > 0 && price > 0)
      setTotalValue(Number(quantity * price).toFixed(2));
  }, [quantity, price]);

  if (isLoading) return <Loader />;
  if (isError) return <ErrorPage error={error?.data?.error} />;

  return (
    <>
      <PageTitle
        title={`${data?.projectData?.projectName} - ${t("exchange.secondaryMarket")}`}
      />
      <div className="pageContent tradeWrapper">
        <Container>
          <h4>
            {t("exchange.edsxIsOneOfTheFirstCompaniesWorldwide")}
          </h4>
          <h5 className="mb-5">
            {t("exchange.edsxHasTakenAllNecessaryMeasures")}
          </h5>
          <Row>
            <Col md="6" className="mb-5">
              <TradingViewChartWrap
                height="265"
                projectId={data?.projectData?.id}
                spinnerType="bg"
              />
            </Col>
            <Col md="6" className="mb-5" >
              <Card className="shadow tradeWrapper">
                <Card.Body className="p-0">
                  <Row>
                    <Col sm="12" md="3" className={`${style.bidUserImg}`}>
                      <div>
                        <img
                          src={image}
                          alt="UserPicture"
                          className="rounded-circle"
                        />
                      </div>
                    </Col>
                    <Col sm="12" md="9" className={`${style.bidAskWrap}`}>
                      <Form onSubmit={confirmTradeOrder}>
                        <div className="toggle-switch mb-3">
                          <Form.Check
                            type="checkbox"
                            className="toggle-switch-checkbox"
                            checked={isBuy}
                            onChange={handleTradeType}
                          />
                          <label
                            className={`toggle-switch-label ${
                              isBuy ? "checked" : ""
                            }`}
                            onClick={handleTradeType}
                          >
                            <span
                              className="toggle-switch-inner"
                              data-yes="Buy"
                              data-no="Sell"
                            ></span>
                            <span
                              className={`toggle-switch-switch ${
                                isBuy ? "bg-success" : "bg-danger"
                              }`}
                            >
                              {isBuy ? t("exchange.Buy") : t("exchange.Sell")}
                            </span>
                          </label>
                        </div>
                        <Row className="align-items-end">
                          <Form.Group as={Col} className="mb-3" sm="12" md="4" >
                            <Form.Label >{t("exchange.quantityYouWantToTrade")}</Form.Label>
                            <Form.Control
                              type="number"
                              value={quantity}
                              className={`${style.bid_ask_form}`}
                              onChange={(e) => setQuantity(e.target.value)}
                              min="1"
                            />
                          </Form.Group>

                          <Form.Group as={Col} className="mb-3" sm="12" md="4">
                            <Form.Label>{t("order.price")}</Form.Label>
                            <Form.Control
                              type="number"
                              value={price}
                              className={`${style.bid_ask_form}`}
                              onChange={(e) => setPrice(e.target.value)}
                            />
                          </Form.Group>

                          <Form.Group as={Col} className="mb-3" sm="12" md="4">
                            <Form.Label>{t("order.value")}</Form.Label>
                            <Form.Control
                              type="text"
                              value={totalValue}
                              className={`${style.bid_ask_form}`}
                              readOnly
                            />
                          </Form.Group>
                        </Row>
                        <Button
                          type="submit"
                          className={`${style.btnTrade} ${
                            isBuy ? "bg-success" : "bg-danger"
                          }`}
                        >
                          {isBuy ? t("exchange.Buy") : t("exchange.Sell")}{" "}
                          <Spinner
                            className={`${!loading ? "d-none" : ""}`}
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                          <i
                            className={`fa fa-arrow-right${
                              loading ? " d-none" : ""
                            }`}
                          ></i>
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {data?.projectData && (
            <TradeOrdersList project={data?.projectData} reRender={isRender} />
          )}
          <OpenOrders />
        </Container>
      </div>
    </>
  );
};

export default TradeProject;
