import React from "react";
import style from "./style.module.css";

const PdContent = ({ content, dataRoom }) => {
  const get_file_extension = (url) =>
    url.split(/[#?]/)[0].split(".").pop().trim();

  if (dataRoom) {
    return (
      <>
        {content && typeof content !== "string" ? (
          <div className="project-text">
            {content.map((item, i) => (
              <a
                href={item.path}
                key={i}
                className="d-flex justify-content-start align-items-center mb-3"
                target="_blank"
                rel="noreferrer"
              >
                <div className={style.fileType}>
                  {get_file_extension(item.path)}
                </div>
                <div className={style.fileName}>{item.name}</div>
              </a>
            ))}
          </div>
        ) : (
          <div
            className="project-text"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        )}
      </>
    );
  } else {
    return (
      <div
        className="project-text"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    );
  }
};

export default PdContent;
