import React, { useState, useEffect } from "react";
import { Form, Button, Card, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useChangePasswordMutation } from "../app/features/auth/authApiSlice";
import { logout } from "../app/features/auth/authSlice";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDefaultLang } from "../app/features/extraReducers";

const ChangePassword = () => {
  const lang = useSelector(selectDefaultLang);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [successMsg, setSuccessMsg] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null);
  const [validMatch, setValidMatch] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
  const [changePassword] = useChangePasswordMutation();
  const [t] = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(newPassword));
    setValidMatch(newPassword === matchPassword);
    // eslint-disable-next-line
  }, [newPassword, matchPassword]);

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    setSuccessMsg(null)
    setErrorMsg(null)
    const data = {
      oldPassword,
      newPassword,
      language: lang
    };
    try {
      const response = await changePassword(data).unwrap();
      Swal.fire({
        icon: "success",
        title:response.message,
        focusConfirm: false,
        confirmButtonAriaLabel: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(() => {
        dispatch(logout());
      });
      // setSuccessMsg(response.message)
      // setOldPassword("");
      // setNewPassword("");
      // setMatchPassword("");
    } catch (err) {
      if(err?.data?.error === 'Unauthorized') {
        dispatch(logout());
      }
      if (!err) setErrorMsg(t("error.noServerResponse"));
      else if (err?.data?.message) setErrorMsg(err?.data?.message);
      else setErrorMsg(t("error.failedToChangePassword"));
    }
    setLoading(false)
  };
  return (
    <>
      <Card className="mt-4 p-4 shadow">
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label
                className={`${style.formLabel} ${style.profileFormLabel}`}
              >
                {t("oldPassword")}
              </Form.Label>
              <Form.Control
                type="password"
                placeholder={t("enterOldPassword")}
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value)
                  setSuccessMsg(null)
                  setErrorMsg(null)
                }}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label
                className={`${style.formLabel} ${style.profileFormLabel}`}
              >
                {t("newPassword")}
              </Form.Label>
              <Form.Control
                type="password"
                placeholder={t("enterNewPassword")}
                onChange={(e) => {
                  setNewPassword(e.target.value)
                  setSuccessMsg(null)
                  setErrorMsg(null)
                }}
                value={newPassword}
                isInvalid={newPassword && !validPassword}
                required
              />
              <Form.Control.Feedback type="invalid">
                <i className="fa fa-info-circle"></i> {t("characters")}.
                <br />
                {t("useLetterNumberCharacter")}
                <br />
                {t("specialCharacters")}:{" "}
                <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span>{" "}
                <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span>{" "}
                <span aria-label="percent">%</span>
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label
                className={`${style.formLabel} ${style.profileFormLabel}`}
              >
                {t("confirmPassword")}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("confirmPassword")}
                onChange={(e) => {
                  setMatchPassword(e.target.value)
                  setSuccessMsg(null)
                  setErrorMsg(null)
                }}
                value={matchPassword}
                isInvalid={matchPassword && !validMatch}
                required
              />

              <Form.Control.Feedback type="invalid">
                {t("enterSamePassword")}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className="submitBtnPrimary"
              disabled={!validMatch || !validPassword ? true : false}
            >
              {t("submit")}{" "}
              <Spinner
                className={`${!loading ? "d-none" : ""}`}
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            </Button>
            {successMsg && (
              <p className="text-success" aria-live="assertive">
                {successMsg}
              </p>
            )}
            {errorMsg && (
              <p className="text-danger" aria-live="assertive">
                {errorMsg}
              </p>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default ChangePassword;
