import React from "react";
import { Container, Nav, Row, Col } from "react-bootstrap";
import image from "../assets/img/logo.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDefaultLang } from "../app/features/extraReducers";
import financeAwardImg from "../assets/img/finance-award-crop.webp";

const Footer = () => {
  const lang = useSelector(selectDefaultLang);
  const [t] = useTranslation();
  return (
    <>
      <footer>
        <Container>
          <Row className="pt-5 pb-5">
            <Col md={3} sm={12} className="col-link">
              <a
                href={`${process.env.REACT_APP_REAL_SITE}/${
                  lang === "it" ? "it/" : ""
                }`}
              >
                <img
                  src={image}
                  alt={process.env.REACT_APP_TITLE}
                  className="logo"
                />
              </a>
              <div className="f-site-title">
                {t("footer.europeanDigitalAssetsExchange")}
              </div>
              <div className="f-site-description">
                {t("footer.address")} <br />
                CHE-332.390.942
              </div>
            </Col>
            <Col md={2} sm={12} className="col-link">
              <Nav className="flex-column info-column">
                <Nav.Item>
                  <Nav.Link className="f-title" disabled>
                    EDSX
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href={`${process.env.REACT_APP_REAL_SITE}/${
                      lang === "it"
                        ? "it/tutorial-per-investitori/"
                        : "are-you-an-investor"
                    }`}
                  >
                    {t("footer.investors")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href={`${process.env.REACT_APP_REAL_SITE}/${
                      lang === "it"
                        ? "it/sei-una-attivita-commerciale/"
                        : "are-you-a-business"
                    }`}
                  >
                    {t("footer.businesses")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href={`${process.env.REACT_APP_REAL_SITE}/${
                      lang === "it" ? "it/contattaci/" : "contacts"
                    }`}
                  >
                    {t("header.contactUs")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={2} sm={12} className="col-link">
              <Nav className="flex-column">
                <Nav.Item>
                  <Nav.Link className="f-title" disabled>
                    {t("footer.support")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href={`${process.env.REACT_APP_REAL_SITE}/${
                      lang === "it" ? "it/faq-italiano/" : "faq"
                    }`}
                  >
                    {t("footer.FAQ")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href={`${process.env.REACT_APP_REAL_SITE}/${
                      lang === "it" ? "it/glossary-italiano/" : "glossary"
                    }`}
                  >
                    {t("footer.glossary")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={2} sm={12} className="col-link">
              <Nav className="flex-column">
                <Nav.Item>
                  <Nav.Link className="f-title" disabled>
                    {t("footer.legalInfo")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href={`${process.env.REACT_APP_REAL_SITE}/${
                      lang === "it"
                        ? "it/termini-e-condizioni"
                        : "terms-conditions"
                    }`}
                  >
                    {t("footer.terms&Conditions")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href={`${process.env.REACT_APP_REAL_SITE}/${
                      lang === "it"
                        ? "it/politica-sulla-privacy/"
                        : "privacy-policy"
                    }`}
                  >
                    {t("footer.privacyPolicy")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href={`${process.env.REACT_APP_REAL_SITE}/${
                      lang === "it"
                        ? "it/cookies-policy-italiano"
                        : "cookies-policy"
                    }`}
                  >
                    {t("footer.cookiesPolicy")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href={`${process.env.REACT_APP_REAL_SITE}/${
                      lang === "it"
                        ? "it/avviso-sui-rischi/"
                        : "risk-disclaimer"
                    }`}
                  >
                    {t("footer.riskDisclaimer")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={3} sm={12} className="col-link">
              <img
                src={financeAwardImg}
                alt="Finance Award"
                style={{ width: "208px", height: "190px" }}
              />
            </Col>
          </Row>
          <Row className="social">
            <p className="text-center text-light">
              {t("footer.followSocialMedia")}
            </p>
            <Nav className="justify-content-center">
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  href="https://www.facebook.com/EuroDSX/"
                >
                  {/* <i className="fab fa-facebook-square"></i> */}
                  <img
                    src="/facebook.webp"
                    alt="Facebook"
                    style={{ borderRadius: "5px" }}
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link target="_blank" href="https://twitter.com/edsx_swiss">
                  {/* <i className="fab fa-twitter"></i> */}
                  <img
                    src="/x-twitter.webp"
                    alt="Twitter"
                    style={{ borderRadius: "5px" }}
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  href="https://www.linkedin.com/company/eurodaxx"
                >
                  {/* <i className="fab fa-linkedin"></i> */}
                  <img
                    src="/linkedin.webp"
                    alt="LinkedIn"
                    style={{ borderRadius: "5px" }}
                  />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>
        </Container>
        <Container fluid>
          <Row className="signature">
            <span>
              ©2019 {process.env.REACT_APP_TITLE}. {t("footer.rightsReserved")}
            </span>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
