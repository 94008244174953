import { apiSlice } from "../api/apiSlice";

const GET_TERMSANDCONSITION = "/user/site-contents/terms.html";
const GET_PRIVACYPOLICY = "/user/site-contents/privacypolicy.html";
const GET_RISKDISCLAIMER = "/user/site-contents/riskdisclaimer.html";
const GET_COOKIEPOLICY = "/user/site-contents/cookie.html";
const POST_INVESTMENT = "/user/investment";

const GET_PROJECTS_URL = "/project/project-list";
const GET_PROJECT_URL = "/project/get/";
const GET_USERPROFILE = "/user/get-profile";
const POST_UPDATEPROFILE = "/user/update-profile";
const POST_UPDATEUSERPROFILE = "/project/update-profile";
const CONFIRM_UPDATEPROFILE = "/user/confirm-update-profile";
const GET_USERINFORMATION = "/user/information";
const GET_USERNFT = "/user/get-user-nft";
const GET_NFT_PRICE_LIST = "/project/nft-price";
const NFT_STATUS_UPDATE = "/project/nft-status-update";

const SET_PAYMENT = "/user/payment";
const INSERT_PUBLIC_KEY = "/transaction/public-key";
const GET_STRIPE_PUBLICKEY = "/user/get-public-key";
const CREATE_PAYMENT_INTENT = "/user/create-payment-intent";
const CONFIRM_PAYMENT = "/user/payment-confirmation";
const PUT_UPDATEINVESTMENT = "/transaction/update";
const WIRE_INVESTMENTCONFIRM = "/user/wire-transfer-investment";
const CRYPTO_INVESTMENTCONFIRM = "/user/crypto-investment";
const RESEND_EMAIL = "/user/resend-verification-mail";

const GET_KYCURL = "/kyc/get-kyc-url";
const GET_KYCUPDATEURL = "/kyc/get-kyc-update-url";
const GET_COUNTRIES = "/kyc/get-countries";
const POST_EXCLUDECOUNTRIES = "/kyc/kyc-exclusion-control";

const UPDATE_LANGUAGE = '/user/language';

export const allApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTermsAndConditions: builder.query({
      query: () => GET_TERMSANDCONSITION,
    }),
    getPrivacyPolicy: builder.query({
      query: () => GET_PRIVACYPOLICY,
    }),
    getRiskDisclaimer: builder.query({
      query: () => GET_RISKDISCLAIMER,
    }),
    getCookiePolicy: builder.query({
      query: () => GET_COOKIEPOLICY,
    }),
    getProjects: builder.query({
      query: (data) =>
        GET_PROJECTS_URL +
        `?market=${data?.marketType}&title=${data?.title}&marketphase=${data?.marketPhase}&page=${data?.page}&datalimit=${data?.datalimit}`,
    }),
    getProject: builder.query({
      query: ({ slug }) => GET_PROJECT_URL + slug,
    }),
    getUserProfile: builder.query({
      query: () => GET_USERPROFILE,
    }),
    updateProfile: builder.mutation({
      query: (data) => {
        return {
          url: POST_UPDATEPROFILE,
          method: "POST",
          body: data,
        };
      },
    }),
    getUserInformation: builder.query({
      query: ({ id }) => GET_USERINFORMATION + `?id=${id}`,
    }),
    updateUserProfile: builder.mutation({
      query: (data) => {
        return {
          url: POST_UPDATEUSERPROFILE,
          method: "PUT",
          body: { ...data },
        };
      },
    }),
    setPayment: builder.mutation({
      query: (data) => ({
        url: SET_PAYMENT,
        method: "POST",
        body: { ...data },
      }),
    }),
    insertPublicKey: builder.mutation({
      query: (data) => ({
        url: INSERT_PUBLIC_KEY,
        method: "POST",
        body: { ...data },
      }),
    }),
    getStripePublicKey: builder.mutation({
      query: (data) => ({
        url: GET_STRIPE_PUBLICKEY,
        method: "POST",
        body: { ...data },
      }),
    }),
    createpaymentintent: builder.mutation({
      query: (data) => ({
        url: CREATE_PAYMENT_INTENT,
        method: "POST",
        body: { ...data },
      }),
    }),
    confirmPayment: builder.mutation({
      query: (data) => ({
        url: CONFIRM_PAYMENT,
        method: "POST",
        body: { ...data },
      }),
    }),
    getkycurl: builder.mutation({
      query: (type) => {
        return {
          url: GET_KYCURL + `?entityType=${type}`,
          method: "GET",
        };
      },
    }),
    getKYCUpdateUrl: builder.mutation({
      query: () => {
        return {
          url: GET_KYCUPDATEURL,
          method: "GET",
        };
      },
    }),
    createInvestment: builder.mutation({
      query: (data) => {
        return {
          url: POST_INVESTMENT,
          method: "POST",
          body: { ...data },
        };
      },
    }),
    updateInvestmentStatus: builder.mutation({
      query: (id) => {
        return {
          url: `${PUT_UPDATEINVESTMENT}/${id}`,
          method: "PUT",
        };
      },
    }),
    wireInvestmentConfirmation: builder.mutation({
      query: (data) => {
        return {
          url: WIRE_INVESTMENTCONFIRM,
          method: "POST",
          body: { ...data },
        };
      },
    }),
    cryptoInvestmentConfirmation: builder.mutation({
      query: (data) => {
        return {
          url: CRYPTO_INVESTMENTCONFIRM,
          method: "POST",
          body: { ...data },
        };
      },
    }),
    resendEmail: builder.mutation({
      query: () => {
        return {
          url: RESEND_EMAIL,
          method: "POST",
          body: {},
        };
      },
    }),
    getCountries: builder.query({
      query: () => GET_COUNTRIES,
    }),
    excludeCountries: builder.mutation({
      query: (data) => ({
        url: POST_EXCLUDECOUNTRIES,
        method: "POST",
        body: { ...data },
      }),
    }),
    confirmUpdateProfile: builder.mutation({
      query: (data) => ({
        url: CONFIRM_UPDATEPROFILE,
        method: "POST",
        body: { ...data },
      }),
    }),
    userNft: builder.mutation({
      query: () => ({
        url: GET_USERNFT,
        method: "GET",
      }),
    }),
    nftPriceList: builder.mutation({
      query: (nftId) => {
        return {
          url: GET_NFT_PRICE_LIST + `?nftId=${nftId}`,
          method: "GET",
        };
      },
    }),
    nftChangeStatus: builder.mutation({
      query: (data) => {
        return {
          url: NFT_STATUS_UPDATE,
          method: "POST",
          body: { ...data },
        };
      },
    }),
    updateLanguage: builder.mutation({
      query: (data) => {
        return {
          url: UPDATE_LANGUAGE,
          method: "POST",
          body: { ...data },
        };
      }
    })
  }),
});

export const {
  useCryptoInvestmentConfirmationMutation,
  useWireInvestmentConfirmationMutation,
  useUpdateInvestmentStatusMutation,
  useConfirmUpdateProfileMutation,
  useCreatepaymentintentMutation,
  useGetStripePublicKeyMutation,
  useGetTermsAndConditionsQuery,
  useUpdateUserProfileMutation,
  useCreateInvestmentMutation,
  useExcludeCountriesMutation,
  useInsertPublicKeyMutation,
  useGetUserInformationQuery,
  useNftChangeStatusMutation,
  useGetKYCUpdateUrlMutation,
  useConfirmPaymentMutation,
  useGetRiskDisclaimerQuery,
  useGetPrivacyPolicyQuery,
  useUpdateProfileMutation,
  useGetCookiePolicyQuery,
  useNftPriceListMutation,
  useResendEmailMutation,
  useGetUserProfileQuery,
  useSetPaymentMutation,
  useGetCountriesQuery,
  useGetkycurlMutation,
  useGetProjectsQuery,
  useGetProjectQuery,
  useUserNftMutation,
  useUpdateLanguageMutation
} = allApiSlice;
