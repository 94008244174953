import React, { useState } from "react";
import { Form, Button, Card, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import { useUpdateProfileMutation } from "../app/features/apisSlice";
import { useTranslation } from "react-i18next";

const ProfileEdit = ({ userData, refetch }) => {
  const [values, setValues] = useState({
    email: userData?.profileData?.email,
    firstName: userData?.profileData?.first_name,
    lastName: userData?.profileData?.last_name,
  });
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [t] = useTranslation();

  const validators = async (values) => {
    let errors = {};
    if (!values.firstName) {
      errors = { firstName: t("error.firstNameIsRequired") };
    }

    if (!values.lastName) {
      errors = { lastName: t("error.lastNameIsRequired") };
    }

    if (!values.email) {
      errors = { email: t("error.emailIsRequired") };
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors = { email: t("error.invalidEmailAddress") };
    }

    return errors;
  };

  const kycStatus = ["OPENED", "UNDER_REVIEW", "ACCEPTED", "UPDATE_REQUESTED", "REJECTED"]
  const [updateProfile] = useUpdateProfileMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      if (userData?.kycStatus && kycStatus.includes(userData?.kycStatus?.kyc_status)) {
        let message = t("profile.kycFilled")
        setErrors({ message });
        return;
      }
      if (
        values.firstName.trim() !== userData?.profileData?.first_name ||
        values.lastName.trim() !== userData?.profileData?.last_name ||
        values.email.trim() !== userData?.profileData?.email
      ) {
        const errors = await validators(values);
        if (Object.keys(errors).length === 0) {
          let data = {
            name: values.firstName.trim(),
            lastName: values.lastName.trim(),
            email: values.email.trim(),
            password,
          };
          const response = await updateProfile(data).unwrap();
          Swal.fire(`${response.message}`, "", "success");
          setErrors({});
          setPassword("");
          refetch();
        } else {
          setErrors(errors);
        }
      } else {
        setErrors({});
        Swal.fire(t("error.nothingToUpdate"), "", "error");
      }

    } catch (error) {
      console.log(error.message);
      if (!error) Swal.fire(t("error.noServerResponse"), "", "error");
      else if (error?.data?.message)
        Swal.fire(`${error?.data?.message}`, "", "error");
      else Swal.fire(t("error.failedToUpdatePleaseTryAgainLater"), "", "error");
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Card className="mt-4 p-4 shadow">
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="pl-2">{t("firstName")}</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                placeholder={t("enterName")}
                onChange={(e) =>
                  setValues({ ...values, firstName: e.target.value })
                }
                value={values.firstName}
                isInvalid={errors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName && errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="pl-2">{t("surname")}</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                placeholder={t("enterSurname")}
                onChange={(e) =>
                  setValues({ ...values, lastName: e.target.value })
                }
                value={values.lastName}
                isInvalid={errors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName && errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="pl-2">{t("email")}</Form.Label>
              <Form.Control
                type="text"
                name="email"
                placeholder={t("enterEmailAddress")}
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
                value={values.email}
                isInvalid={errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email && errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className="submitBtnPrimary"
              disabled={isSubmitting}
            >
              {t("save")}{" "}
              <Spinner
                className={`${!isSubmitting ? "d-none" : ""}`}
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
              />
            </Button>
            <p className="text-danger">{errors.message && errors.message}</p>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProfileEdit;