import React, { useRef, useEffect } from "react";
import { createChart, CrosshairMode } from "lightweight-charts";

const TradingView = ({ height, data }) => {
  const chartContainerRef = useRef();
  const chart = useRef();
  const resizeObserver = useRef();

  useEffect(() => {
    chart.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: height,
      layout: {
        backgroundColor: "#253248",
        textColor: "#fff",
      },
      grid: {
        vertLines: {
          color: "#334158",
        },
        horzLines: {
          color: "#334158",
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      priceScale: {
        borderColor: "#485c7b",
      },
      timeScale: {
        borderColor: "#485c7b",
      },
    });
    const candleSeries = chart.current.addCandlestickSeries({
      upColor: "#4bffb5",
      downColor: "#ff4976",
      borderDownColor: "#ff4976",
      borderUpColor: "#4bffb5",
      wickDownColor: "#838ca1",
      wickUpColor: "#838ca1",
    });

    candleSeries.setData(data);
    return () => chart.current.remove();
  }, [data, height]);

  useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      chart.current.applyOptions({ width, height });
      setTimeout(() => {
        chart.current.timeScale().fitContent();
      }, 0);
    });

    chartContainerRef.current &&  resizeObserver.current.observe(chartContainerRef.current);

    return () => resizeObserver.current.disconnect();
  }, []);


  console.log(resizeObserver.current , 'resizeObserverresizeObserverresizeObserver');

  return (
    <>
      <div className="chart-wrapper">
        <div
          ref={chartContainerRef}
          className="chart-container"
          id="chartContainer"
        />
        <a href="https://www.tradingview.com/" target="_blank" rel="noreferrer">
          <div className="watermark"></div>
        </a>
      </div>
    </>
  );
};

export default TradingView;
