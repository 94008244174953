import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Modal, Spinner } from "react-bootstrap";
import { isLogged } from "../app/features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import image from "../assets/img/tiny-img.png";
import { useNftPriceListMutation } from "../app/features/apisSlice";
import { selectDefaultLang, setNftPriceList } from "../app/features/extraReducers";
import { useTranslation } from "react-i18next"

const PdNft = ({ nfts, setNftId, setShowNftInvestTab, isKyced, projectData }) => {
  const lang = useSelector(selectDefaultLang)
  const errorRef = useRef(null);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLogged);
  const [modalContent, setModalContent] = useState(true);
  const [contentType, setContentType] = useState(true);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNFT, setSelectedNFT] = useState();
  const [errMsg, setErrMsg] = useState(""); 
  const handleShow = (type, content) => {
    setModalContent(content);
    setContentType(type);
    setShow(true);
  };
  const [t] = useTranslation();
  const [fetchNftPriceList] = useNftPriceListMutation();

  const getNftPriceList = async (id) => {
    setErrMsg();
    try {
      setIsLoading(true);
      setNftId(id);
      setSelectedNFT(id);
      const response = await fetchNftPriceList(id);
      await dispatch(setNftPriceList(response.data));
      await setShowNftInvestTab(true);
      setIsLoading(false);
    } catch (err) {
      console.log(err)
      if (!err) setErrMsg(t("error.noServerResponse"));
      else if (err?.data?.error) setErrMsg(err.data.error);
      else if (err?.data?.message) setErrMsg(err.data.message);
      else setErrMsg(t("error.investmentFailed"));
      setIsLoading(false);
    }
  };

  const nftList = [];
  nfts.forEach((list) => {
    if (list.isSold === false ) {
      nftList.push(list);
    }
  });
  const checkBrokenImage = (event) => {
    event.target.src = image;
    event.onerror = null;
  };

  return (
    <>
      <h2>{t("nft.projectNFTs")}</h2>
      {errMsg && (
        <p
          ref={errorRef}
          className="text-danger text-center"
          aria-live="assertive"
        >
          {errMsg}
        </p>
      )}
      <p>{t("nft.checkAllAvailableNFTsForThisProjectAndSelectOneToInvest")}</p>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>{t("nft.shape")}</th>
            <th>{t("nft.price")}</th>
            <th>{t("nft.carat")}</th>
            <th>{t("nft.cut")}</th>
            <th>{t("nft.color")}</th>
            <th>{t("nft.clarity")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {nftList.map((nft, i) => {
            return (
              <tr key={i}>
                <td>{nft?.shape}</td>
                <td>{projectData?.currency} {parseFloat(nft?.price).toFixed(2)}</td>
                <td>{nft?.carat}</td>
                <td>{nft?.cut}</td>
                <td>{nft?.color}</td>
                <td>{nft?.clarity}</td>
                <td className="nftBtn d-flex">
                  <Button
                    onClick={() => handleShow("view", nft?.image)}
                    className="bg-info"
                    size="sm"
                    variant="primary"
                  >
                    {t("nft.view")}
                  </Button>{" "}
                  <Button
                    onClick={() => handleShow("report", nft?.giaReport)}
                    className="bg-info"
                    size="sm"
                    variant="primary"
                  >
                    {t("nft.giaReport")}
                  </Button>{" "}
                  {(() => {
                    if (isLoggedIn && isKyced) {
                      return (
                        <Button
                          size="sm"
                          variant="primary"
                          onClick={() => getNftPriceList(nft?.id, nft?.price)}
                        >
                          {t("nft.buyNow")}{" "}
                          {isLoading && nft?.id === selectedNFT && (
                            <Spinner size="sm" animation="border" />
                          )}
                        </Button>
                      );
                    } else if (!isKyced) {
                      return (
                        <Link to={`/${lang}/profile`} className="btn btn-primary btn-sm">
                          {t("nft.buyNow")}
                        </Link>
                      );
                    } else {
                      return (
                        <Link to={`/${lang}/login`} className="btn btn-primary btn-sm">
                          {t("nft.buyNow")}
                        </Link>
                      );
                    }
                  })()}{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Modal show={show} size="lg" onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {contentType === "view" ? t("nft.view") : t("nft.giaReports")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {contentType === "view" ? (
            <img
              src={modalContent}
              onError={checkBrokenImage}
              className="img-fluid"
              height="430px"
              alt="NFT"
            />
          ) : (
            <iframe
              src={modalContent}
              style={{ width: "100%" }}
              height="450px"
              title={t("nft.nftDocument")}
            ></iframe>
          )}
          <br />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PdNft;
