import React from "react";
import { Row, Col, Card, Badge, Stack } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import style from "./style.module.css";
import image from "../assets/img/tiny-img.png";
import { useTranslation } from "react-i18next";

const Project = ({ projects }) => {
  let projectsList = projects
    .slice()
    .sort(
      (a, b) =>
        new Date(b.date_start).getTime() - new Date(a.date_start).getTime()
    );

  const [t] = useTranslation();
  // const navigate = useNavigate();
  const checkBrokenImage = (event) => {
    event.target.src = image;
    event.onerror = null;
  };
  const checkBrokenIconImage = (event) => {
    event.target.style.display = "none";
    event.onerror = null;
  };

  // eslint-disable-next-line
  const compareDate = (projectEndDate) => {
    let q = new Date();
    let m = q.getMonth();
    let d = q.getDay();
    let y = q.getFullYear();
    let date = new Date(y, m, d);
    let projectDate = new Date(projectEndDate);
    return date <= projectDate ? true : false;
  };

  const AmountRaised = ({ currency, amount }) => {
    let symbol = "€";
    if (currency === "CHF") symbol = "₣";
    if (currency === "USD") symbol = "$";

    if (amount > 1000000000000)
      amount = Math.round(amount / 1000000000000) + " tln";
    else if (amount > 1000000000)
      amount = Math.round(amount / 1000000000) + " bln";
    else if (amount > 1000000) amount = Math.round(amount / 1000000) + " mln";
    else if (amount > 1000) amount = Math.round(amount / 1000) + " k";

    return (
      <Badge pill bg="dark" className={`ms-auto ${style.projectStatus2}`}>
        {symbol}
        {amount} {t("project.raised")}
      </Badge>
    );
  };

  const handleredirect = (project) => {
    // let projectLink = `/project/${project.slug}`;
    // if (
    //   project.marketPhase === "Coming Soon" ||
    //   project.marketPhase === "Completed"
    // ) {
    //   projectLink = "#";
    // }
    // let checkData = compareDate(project.endDate);
    // if (checkData) {
    //   projectLink = "/404";
    // }
    // navigate(projectLink);
  };

  return (
    <Row xxl="4" xl="4" lg="4" md="2" sm="2" xs="1">
      {projectsList.map((project, i) => {
        return (
          <Col className="mb-3" key={i}>
            <Card className={`mb-3 ${style.projectCard}`}>
              {project?.marketPhase && (
                <Badge
                  pill
                  className={`ms-auto ${style.projectStatus} bg-pink`}
                >
                  {project?.marketPhase}
                </Badge>
              )}
              {(project?.marketPhase === "Completed" ||
                project?.marketPhase === "Closed") && (
                <AmountRaised
                  currency={project?.currency}
                  amount={project.invested}
                />
              )}
              <div className={`${style.imgContainer}`}>
                <Card.Img
                  alt={project?.projectName}
                  variant="top"
                  onError={checkBrokenImage}
                  src={
                    project?.background ? project?.background?.file_path : image
                  }
                  className={`${style.projectImage}`}
                  onClick={() => handleredirect(project)}
                  style={{ cursor: "pointer" }}
                />
              </div>
              {(project?.type?.SecurityType || project.logo) && (
                <Stack
                  direction="horizontal"
                  gap={2}
                  className={`${style.projectType}`}
                >
                  {project?.type?.SecurityType && (
                    <div>
                      <Badge pill className="bg-primary">
                        {project?.type?.SecurityType}
                      </Badge>
                    </div>
                  )}
                  {project.logo && (
                    <div className="ms-auto">
                      <img
                        alt="Project Icon"
                        onError={checkBrokenIconImage}
                        src={project.logo.file_path}
                        className={`${style.projectIconImg}`}
                      />
                    </div>
                  )}
                </Stack>
              )}
              <Card.Body
                className={`pt-0 ${style.projectCardBody}`}
                onClick={() => handleredirect(project)}
                style={{ cursor: "pointer" }}
              >
                <Card.Title className={`${style.projectCardTitle}`}>
                  {project?.projectName}
                </Card.Title>
                {project?.briefDescription && (
                  <Card.Text className="text-justify">
                    {project?.briefDescription}
                  </Card.Text>
                )}
              </Card.Body>
              {(project?.marketType || project?.website) && (
                <Card.Footer className={`${style.projectFooter}`}>
                  <Stack direction="horizontal" gap={2}>
                    {project?.marketType && (
                      <div>
                        <Badge pill className="projectMarket bg-primary">
                          {project?.marketType}
                        </Badge>
                      </div>
                    )}
                    {project?.website && (
                      <div className="ms-auto">
                        <a
                          href={project?.website}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-globe"></i> {t("project.visitWebsite")}
                        </a>
                      </div>
                    )}
                  </Stack>
                </Card.Footer>
              )}
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default Project;
