import React, { useRef, useState } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import Project from "../components/Project";
import { useGetProjectsQuery } from "../app/features/apisSlice";
import Loader from "../components/Loader";
import ErrorPage from "../components/ErrorPage";
import Pagination from "../components/Pagination";
import { useTranslation } from "react-i18next";

const InvestmentOpportunities = () => {
  const timestampRef = useRef(Date.now()).current;
  const [marketFilter, setMarketFilter] = useState("");
  const [searchProject, setSearchProject] = useState("")
  const [fmarketPhase, setFMarketPhase] = useState("")
  const [offset, setOffset] = useState(1);
  const postPerPage = 10;
  const [t] = useTranslation();
  const { data, isLoading, isSuccess, isError, error } = useGetProjectsQuery({
    title: searchProject,
    marketType: marketFilter,
    marketPhase: fmarketPhase,
    page: offset,
    datalimit: postPerPage,
    sessionId: timestampRef,
  });
  const handelMarketFilter = (event) => {
    if (marketFilter === event?.target?.value) setMarketFilter("");
    else setMarketFilter(event?.target?.value);
  };
  const marketName = [t("invest.ico"), t("invest.sto"), t("invest.nft")];
  const marketPhase = [
    { key: "comingSoon", value: "Coming Soon" },
    { key: "preListing", value: "Pre-listing" },
    { key: "primaryMarket", value: "Primary Market" },
    { key: "exchange", value: "Exchange" },
    { key: "completed", value: "Completed" },
  ];

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="pageHeader">
        <Container fluid>
          <Row className="align-items-center">
            <Col lg="4" sm="12" className="mbsm-3">
              <Form.Control placeholder={t("invest.search")} onChange={(e) => setSearchProject(e.target.value)} />
            </Col>
            <Col lg="4" sm="12" className="d-flex justify-content-between">
              {marketName.map((item, index) => {
                return (
                  <Form.Check
                    type="switch"
                    key={`marketName${index}`}
                    checked={marketFilter === item ? true : false}
                    onChange={handelMarketFilter}
                    value={item}
                    label={`${item}s`}
                  />
                );
              })}
            </Col>
            <Col lg="4" sm="12">
              <Form.Group
                as={Row}
                controlId="formPlaintextEmail"
                className="align-items-center"
              >
                <Form.Label column lg="4" className="text-end">
                  {t("invest.marketPhase")}
                </Form.Label>
                <Col lg="8">
                  <Form.Select onChange={(e) => setFMarketPhase(e.target.value)}>
                    <option key={`marketPhase0`} value="">{t("invest.all")}</option>
                    {marketPhase.map((item, index) => {
                      return (
                        <option
                          key={`marketPhase` + index + 1}
                          value={item.value}
                        >
                          {t(`invest.${item.key}`)}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="pageContent">
        <Container fluid>
          {isSuccess && data?.data?.count !== 0 && (
            <Project projects={data?.data?.rows} />
          )}
          {isError &&
            (
              <ErrorPage error={error?.data?.error} />
            )}
          {isSuccess && data?.data?.count === 0 && (
            <ErrorPage error={t("invest.sorryNoProjectsToShow")} />
          )}
          <Pagination
            dataCount={data?.data?.count}
            postPerPage={postPerPage}
            offset={offset}
            setOffset={setOffset}
          />
        </Container>
      </div>
    </>
  );
};

export default InvestmentOpportunities;
