import React, { useEffect, useState } from "react";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";
import { useCreatepaymentintentMutation, useGetStripePublicKeyMutation } from "../../app/features/apisSlice";
import { useTranslation } from "react-i18next";

const StripeBox = (props) => {
  const investmentData = props?.data;
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState();
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const [errMsg, setErrMsg] = useState();
  const [getStripePublicKey] = useGetStripePublicKeyMutation();
  const [createpaymentintent] = useCreatepaymentintentMutation();
  const [t] = useTranslation();

  const getPublicKey = async () => {
    try {
      let response = await getStripePublicKey({ "projectid": investmentData?.id }).unwrap();
      if (response?.publishableKey)
        setStripePromise(loadStripe(response?.publishableKey))
    } catch (error) {
      console.log("PublicKey: ", error)
      setErrMsg(t("error.unableToLoadPaymentWindow"));
    }
  }
  
  const getClientSecret = async () => {
    // let amount = Number(parseFloat(investmentData?.investedAmount).toFixed(2)) + 3.5
    // let amount = Math.round((investmentData?.investedAmount * 100) + (investmentData?.investedAmount * 100 * 0.035))
    let data = {
      price: investmentData?.investedAmount,
      currency: investmentData?.investedCurrency,
      description: investmentData?.projectName,
      projectId: investmentData?.id,
      id: investmentData?.transactionId //transactionId
    }
    try {
      let response = await createpaymentintent(data).unwrap();
      if (!clientSecret) {
        setClientSecret(response?.paymentIntent?.client_secret);
      }
    } catch (err) {
      setErrMsg(err.response.data.message);
    }
    setTimeout(() => {
      setShowLoader(false);
    }, 5000);
  };

  useEffect(() => {
    if (!stripePromise) getPublicKey();
    if (stripePromise && isInitialRender) {
      setIsInitialRender(false)
      getClientSecret();
    }
    // eslint-disable-next-line
  }, [stripePromise, isInitialRender])

  return (
    <Row className="mt-5">
      <Col lg="6" md="8" sm="12">
        <h5 className="fw-bold">{t("payment.project")}: {investmentData?.projectName}</h5>
        <p className="fs-6 fw-bold">
          {t("payment.investmentAmount")}: {investmentData?.investedAmount}
            {investmentData?.investedCurrency}
          <br />
          {t("payment.transactionCharge")}: 5.5% 
          <br />                                                                                            
          {/* Total amount:{Number(investmentData?.investedAmount) + 3.5}
          {investmentData?.investedCurrency} */}
          {t("payment.totalAmount")}:{Math.round((investmentData?.investedAmount * 100) + (investmentData?.investedAmount * 100 * 0.055))/100}
          {investmentData?.investedCurrency}
        </p>
        <Card className="shadow p-3 bg-midgrey">
          {errMsg ? (
            <p className="fs-6 text-danger">{errMsg}</p>
          ) : (
            <>
              <Spinner
                className={`mx-auto ${showLoader ? "d-block" : "d-none"}`}
                variant="primary"
                size="lg"
                role="status"
                aria-hidden="true"
                animation="border"
              />
              <Card.Body className={`${!showLoader ? "d-block" : "d-none"}`}>
                  {stripePromise && clientSecret && (
                    <Elements stripe={stripePromise} options={{clientSecret}}>
                      <StripeCheckoutForm data={investmentData}/>
                    </Elements>
                  )}
              </Card.Body>
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default StripeBox;
