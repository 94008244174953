import React, { useState, useEffect } from "react";
import { Container, Row, Col, Badge, Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLogged } from "../app/features/auth/authSlice";
import Swal from "sweetalert2";
import style from "./style.module.css";
import { useTranslation } from "react-i18next"
import { selectDefaultLang } from "../app/features/extraReducers";

const PdHeaderNft = ({setShowInvestTab, isKyced, project: { projectName, type, endDate }, nftData}) => {
    const lang = useSelector(selectDefaultLang)
    const navigate = useNavigate();
    const [dayLeft, setDayLeft] = useState("");
    const [isInitialRender, setIsInitialRender] = useState(true);
    const isLoggedIn = useSelector(isLogged);
    const nftSold = nftData.filter(list => list.isSold === true)
    const [t] = useTranslation();

    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            let enDate = new Date(endDate);
            let curDate = new Date();
            let difference = enDate.getTime() - curDate.getTime();
            let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
            TotalDays = TotalDays <= 0 ? 0 : TotalDays;
            setDayLeft(TotalDays);
        }
        // eslint-disable-next-line
    }, [dayLeft, isInitialRender]);

    const handleInvestButtonClick = () => {
        const profileUrl = `/${lang}/profile`;
        if (isLoggedIn && isKyced) setShowInvestTab(true);
        else if (isLoggedIn && !isKyced) {
            Swal.fire({
                html: `<b>${t("beforeYouCanStartInvesting")}</b>`,
                showConfirmButton: true,
                confirmButtonText:
                    `<a href=${profileUrl} style="color:#fff">Dashboard</a>`,
                confirmButtonColor: "#222890",
                focusCancel: true,
                showCancelButton: true,
                cancelButtonColor: "#A21B29",
            });
        } else navigate(`/${lang}/login`);
    };

    return (
        <>
            <div className={`${style.pageHeader}`}>
                <Container>
                    <Row className="ml-right">
                        <Col lg="6">
                            <div className={`${style.pageTitle}`}>{projectName}</div>
                            <h5 className={`mt-4 ${style.projectSubHeading}`}>
                                <Badge bg="secondary" pill className="text-light">
                                    {type}
                                </Badge>
                            </h5>
                        </Col>
                        <Col lg="6">
                            <div className={`w-50 p-3 bg-white shadow mt-lg-1 mt-md-3 mt-sm-3 float-end`}>
                                <Row className="align-items-center">
                                    <Col
                                        lg="6"
                                        md="6"
                                        sm="6"
                                        className={`text-center ${style.investCol2}`}
                                    >
                                        <span className={`${style.daysCount}`}>
                                            {nftSold.length}
                                        </span>
                                        <br />
                                        {t("nftSold")}
                                    </Col>
                                    <Col
                                        lg="6"
                                        md="6"
                                        sm="6"

                                        className={`align-items-right text-center ${style.investCol3} `}
                                    >
                                        <Button
                                            variant="primary"
                                            className={`${style.hBtnInvest}`}
                                            onClick={() => handleInvestButtonClick()}
                                        >
                                            {t("investNow")}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default PdHeaderNft;
