import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useUpdateInvestmentStatusMutation } from "../app/features/apisSlice";
import { selectDefaultLang, selectInvestmentData } from "../app/features/extraReducers";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";

const PaymentStatus = () => {
  const navigate = useNavigate();
  const lang = useSelector(selectDefaultLang)
  const [errMsg, setErrMsg] = useState(null);
  const [initialize, setInitialize] = useState(true)
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  
  const investmentData = useSelector(selectInvestmentData);
  const [updateInvestmentStatus] = useUpdateInvestmentStatusMutation();
  const transactionId = investmentData?.transactionId;
  const [t] = useTranslation();

  if (!investmentData) navigate(`/${lang}/investment-opportunities`);

  const updateStatus = async () => {
    setErrMsg("");
    try {
      await updateInvestmentStatus(transactionId).unwrap();
      navigate(`${lang}/payment-success`);
    } catch (err) {
      if (!err) setErrMsg(t("error.noServerResponse"));
      else if (err.data.error) setErrMsg(err.data.error);
      else setErrMsg(t("error.failedToUpdatePaymentStatus"));
    }
  };

  let paymentStatus = searchParams.get("redirect_status");
  useEffect(() => {
    if (paymentStatus === "succeeded" && initialize && transactionId) {
      setInitialize(false)
      updateStatus();
    }
    // eslint-disable-next-line 
  }, [paymentStatus, navigate, setInitialize, initialize, transactionId]);

  return (
    <>
      <div className="pageContent error-page d-flex justify-content-center align-items-center flex-column p-5">
        {errMsg ? (
            <h2 className="text-danger">{ errMsg }</h2>
        ) : (
          <>
            <Loader />
            <h2>{t("payment.pleaseWaitWhileWeAreCheckingForPaymentStatus")}</h2>
          </>
        )}
      </div>
    </>
  );
};

export default PaymentStatus;
