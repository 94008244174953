import React, { useState } from "react";
import { Row, Col, Button, Form, Modal, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Reaptcha from "reaptcha";
import TermsAndConditions from "../Pages/TermsAndConditions";
import RiskDisclaimer from "../Pages/RiskDisclaimer";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import CookiePolicy from "../Pages/CookiePolicy";
import { useSelector } from "react-redux";
import { selectDefaultLang } from "../app/features/extraReducers";
import PageTitle from "../components/PageTitle";
import {
  useFbSignupMutation,
  useGoogleSignupMutation,
} from "../app/features/auth/authApiSlice";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";

const Policies = () => {
  const [captcha, setCaptcha] = useState(null)
  const lang = useSelector(selectDefaultLang)
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const navigate = useNavigate();

  // Access query parameters
  const firstName = queryParams.get('firstName');
  const lastName = queryParams.get('lastName');
  const provider = queryParams.get('provider');
  const email = queryParams.get('email');
  const providerUserId = queryParams.get('id');
  const displayName = queryParams.get('displayName');

  if (!provider) {
    window.location.href = `/${lang}/signup`;
  }
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  // Handle Modal
  const [modalType, setModalType] = useState(true);
  const [show, setShow] = useState(false);
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false);
  const [t] = useTranslation();

  const handleClose = () => setShow(false);
  function handleShow(type) {
    setModalType(type);
    setShow(true);
  }

  const [fbSignup] = useFbSignupMutation();
  const [googleSignup] = useGoogleSignupMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      googleUserId: providerUserId,
      name: firstName,
      sureName: lastName,
      email: email,
      accesstoken: "",
      language: lang,
    }
    data.social = (provider === "google") ? "Google" : "Facebook"
    const response = (provider === "google") ? await googleSignup(data) : await fbSignup(data)
    if (response?.error) {
      if (response?.error?.data?.message) setErrMsg(response?.error?.data?.message);
      else setErrMsg(t("error.noServerResponse"));
    } else {
      setSuccessMsg(response?.data?.message);
      setTimeout(() => {
        navigate(`/${lang}/login`)
      }, 3000);
    }

    setLoading(false);
    e.target.reset();
    captcha.reset();
    setVerifiedCaptcha(false)
  };

  return (
    <>
      <PageTitle title={`Hello ${displayName}`} />
      <div className="pageContent policyContent">
        <Row className="m-3 mt-0">
          <Col lg="7" md="10" sm="12">
            {successMsg && (
              <p className="text-success" aria-live="assertive">
                {successMsg} <br/><Link to={`/${lang}/login`}>{t("loginNow")}</Link>
              </p>
            )}
            {errMsg && (
              <p className="text-danger" aria-live="assertive">
                {errMsg}
              </p>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Check type="checkbox">
                  <Form.Check.Input type="checkbox" required />
                  <Form.Check.Label>
                    {t("AcceptThe")}{" "}
                    <Button
                      onClick={() => handleShow("termsandconditions")}
                      className={`${style.likeAnchor}`}
                    >
                      {t("termsAndConditions")}
                    </Button>
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check type="checkbox">
                  <Form.Check.Input type="checkbox" required />
                  <Form.Check.Label>
                    {t("AcceptThe")}{" "}
                    <Button
                      onClick={() => handleShow("riskdisclaimer")}
                      className={`${style.likeAnchor}`}
                    >
                      {t("footer.riskDisclaimer")}
                    </Button>
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check type="checkbox">
                  <Form.Check.Input type="checkbox" required />
                  <Form.Check.Label>
                    {t("AcceptThe")}{" "}
                    <Button
                      onClick={() => handleShow("privacypolicy")}
                      className={`${style.likeAnchor}`}
                    >
                      {t("footer.privacyPolicy")}
                    </Button>{" "}
                    {t("and")}{" "}
                    <Button
                      onClick={() => handleShow("cookiepolicy")}
                      className={`${style.likeAnchor}`}
                    >
                      {t("cookiePolicy")}
                    </Button>
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>

              <Reaptcha
                ref={(e) => setCaptcha(e)}
                sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                onVerify={() => { setVerifiedCaptcha(true) }}
                onExpire={() => { setVerifiedCaptcha(false) }}
              />

              <Button
                variant="primary"
                type="submit"
                className={`${style.btnLogin} m-0 mt-4`}
                disabled={!verifiedCaptcha}
              >
                {t("register")}{" "}
                <Spinner
                  className={`${!loading ? "d-none" : ""}`}
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />
              </Button>
            </Form>
          </Col>
        </Row>
      </div>

      <Modal show={show} size="lg" onHide={handleClose} centered>
        <Modal.Body className="p-0">
          <button
            type="button"
            className={`btn-close ${style.modalClose}`}
            aria-label="Close"
            onClick={handleClose}
          ></button>
          {(() => {
            if (modalType === "termsandconditions") {
              return <TermsAndConditions />;
            } else if (modalType === "riskdisclaimer") {
              return <RiskDisclaimer />;
            } else if (modalType === "privacypolicy") {
              return <PrivacyPolicy />;
            } else if (modalType === "cookiepolicy") {
              return <CookiePolicy />;
            }
          })()}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Policies;
