import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ProjectNewsContent = ({ data, setShowContent }) => {
  const [t] =  useTranslation();
  return (
    <>
      <Button onClick={() => setShowContent(false)} className="mb-3" size="sm">
        <i className="fa-solid fa-arrow-left"></i> {t("project.backToNews")}
      </Button>
      <h5 className="mb-0">{data.title}</h5>
      <p className="text-muted mb-3">
        <small>{new Date(data.createdAt).toDateString()}</small>
      </p>
      {data.image && (
        <img src={data.image} alt={data.title} style={{ maxWidth: "100%" }} />
      )}
      <small>{data.date}</small>
      <div
        className="project-text mt-3"
        dangerouslySetInnerHTML={{ __html: data.content }}
      ></div>
    </>
  );
};

export default ProjectNewsContent;
