import React from "react";
import { Container } from "react-bootstrap";
import PageTitle from "../components/PageTitle";
import { useGetTermsAndConditionsQuery } from "../app/features/apisSlice";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDefaultLang } from "../app/features/extraReducers";

const TermsAndConditions = () => {
  const { status, error } = useGetTermsAndConditionsQuery();
  const [t] = useTranslation();

  const lang = useSelector(selectDefaultLang);

  if (status === "pending") return <Loader />;
  return (
    <>
      <PageTitle title={t("termsAndConditions")} />
      <div className="pageContent">
        {lang === "en" && (
          <Container
            dangerouslySetInnerHTML={{ __html: error.data }}
          ></Container>
        )}

        {lang === "it" && (
          <Container>
            <div className="modal-body">
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  PREGHIAMO DI LEGGERE QUESTI TERMINI DI SERVIZIO CON
                  ATTENZIONE. ACCEDENDO O UTILIZZANDO I SERVIZI OPPURE FACENDO
                  CLIC SUL PULSANTE “CREA UN ACCOUNT”, ACCETTI DI ESSERE
                  LEGALMENTE LEGATO DA QUESTI TERMINI DI SERVIZIO E DA TUTTE LE
                  CONDIZIONI IN ESSI INCORPORATE PER RIFERIMENTO.
                </span>
              </p>
              <table
                style={{
                  margin: 0,
                  padding: 0,
                  color: "#4f5362",
                  fontFamily: '"Gotham Pro"',
                }}
              >
                <tbody style={{ margin: 0, padding: 0 }}>
                  <tr style={{ margin: 0, padding: 0 }}>
                    <td style={{ margin: 0, padding: 0 }}>
                      <p
                        style={{
                          marginBottom: "1rem",
                          color: "#4f4f6f",
                          fontFamily: '"Gotham Pro Light"',
                          lineHeight: "1.6",
                        }}
                      >
                        <b>SI PREGA DI CONSIDERARE CHE:</b>
                      </p>
                      <ul
                        style={{
                          marginTop: 10,
                          paddingLeft: 20,
                          color: "#4f4f6f",
                          fontFamily: '"Gotham Pro Light"',
                          lineHeight: "1.6",
                        }}
                      >
                        <li
                          style={{
                            marginBottom: 10,
                            paddingLeft: 25,
                            listStyle: "none",
                            position: "relative",
                          }}
                        >
                          <b>
                            EDSX NON È REGOLAMENTATA COME PIATTAFORMA DI SCAMBIO
                            DI CRIPTOVALUTE DA FINMA
                          </b>
                        </li>
                        <li
                          style={{
                            marginBottom: 10,
                            paddingLeft: 25,
                            listStyle: "none",
                            position: "relative",
                          }}
                        >
                          <b>
                            EVENTUALI DEPOSITI EFFETTUATI SU EDSX NON SONO
                            COPERTI DALLA GARANZIA DEI DEPOSITI
                          </b>
                        </li>
                        <li
                          style={{
                            marginBottom: 10,
                            paddingLeft: 25,
                            listStyle: "none",
                            position: "relative",
                          }}
                        >
                          <b>
                            EVENTUALI DEPOSITI EFFETTUATI SU EDSX NON SONO
                            INVESTITI NÉ REMUNERATI
                          </b>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  1. S<b>ommario dei Termini del Servizio</b>
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  Il presente Contratto di Adesione (“Contratto”) è stipulato
                  tra:
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                (1){" "}
                <span style={{ fontWeight: 400 }}>
                  EDSX, una società costituita in Svizzera, Zug il cui ufficio
                  registrato è in Baarerstrasse 82, 6302 Zug, c/o AbaFin
                  Treuhand AG, Svizzera;
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                e
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                (2){" "}
                <span style={{ fontWeight: 400 }}>
                  Voi, in qualità di membro di EDSX.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Il presente Contratto costituisce un accordo contrattuale
                  vincolante tra Lei e noi e definisce i nostri e i SuoI diritti
                  e obblighi in relazione alla Sua adesione a EDSX e
                  all’utilizzo della piattaforma. Accettando il presente
                  Contratto, Lei indica di accettare e di attenersi a tutti i
                  termini in esso contenuti.
                </span>
              </p>
              <table
                style={{
                  margin: 0,
                  padding: 0,
                  color: "#4f5362",
                  fontFamily: '"Gotham Pro"',
                }}
              >
                <tbody style={{ margin: 0, padding: 0 }}>
                  <tr style={{ margin: 0, padding: 0 }}>
                    <td style={{ margin: 0, padding: 0 }}>
                      <p
                        style={{
                          marginBottom: "1rem",
                          color: "#4f4f6f",
                          fontFamily: '"Gotham Pro Light"',
                          lineHeight: "1.6",
                        }}
                      >
                        <span
                          style={{
                            margin: 0,
                            padding: 0,
                            fontWeight: "bolder",
                            fontFamily: '"Gotham Pro Bold"',
                          }}
                        >
                          <b>SI PREGA DI CONSIDERARE CHE:&nbsp;</b>
                        </span>
                      </p>
                      <ul
                        style={{
                          marginTop: 10,
                          paddingLeft: 20,
                          color: "#4f4f6f",
                          fontFamily: '"Gotham Pro Light"',
                          lineHeight: "1.6",
                        }}
                      >
                        <li
                          style={{
                            marginBottom: 10,
                            paddingLeft: 25,
                            listStyle: "none",
                            position: "relative",
                          }}
                        >
                          <b>
                            EDSX NON È REGOLAMENTATA COME PIATTAFORMA DI SCAMBIO
                            DI CRIPTOVALUTE DA FINMA
                          </b>
                        </li>
                        <li
                          style={{
                            marginBottom: 10,
                            paddingLeft: 25,
                            listStyle: "none",
                            position: "relative",
                          }}
                        >
                          <b>
                            EVENTUALI DEPOSITI EFFETTUATI SU EDSX NON SONO
                            COPERTI DALLA GARANZIA DEI DEPOSITI
                          </b>
                        </li>
                        <li
                          style={{
                            marginBottom: 10,
                            paddingLeft: 25,
                            listStyle: "none",
                            position: "relative",
                          }}
                        >
                          <b>
                            EVENTUALI DEPOSITI EFFETTUATI SU EDSX NON SONO
                            INVESTITI NÉ REMUNERATI
                          </b>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Questa sintesi dei nostri Termini di Servizio fornisce una
                  panoramica dei termini chiave applicabili all’uso del nostro
                  sito web e dei servizi di trading. Speriamo che questa sezione
                  sintetica possa essere d’aiuto, tuttavia consigliamo di
                  leggere attentamente i Termini di Servizio completi in quanto
                  forniscono importanti informazioni sul funzionamento dei
                  nostri servizi. Si prega di notare che EDSX è una piattaforma
                  decentralizzata peer-to-peer per il trading di security token
                  (o asset digitali). Ci riferiamo ai nostri servizi online dove
                  puoi avviare l’esecuzione delle transazioni come “
                </span>
                <b>EDSX</b>
                <span style={{ fontWeight: 400 }}>“.</span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  EDSX ti fornisce una piattaforma dove puoi abbinare i tuoi
                  ordini con gli ordini di altri utenti dei nostri servizi su
                  tua iniziativa. Sulla blockchain, gli utenti possono anche
                  predeterminare una transazione con un utente specifico o con
                  un account specifico.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Su EDSX un ordine non può essere parzialmente eseguito (AON –
                  “all or nothing” – Tutto o Niente) o eseguito da ordini di
                  abbinamento multipli.
                </span>
              </p>
              <ul
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>I Nostri Servizi</span>
                </li>
              </ul>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  EDSX ti fornisce un modo semplice e conveniente per scambiare
                  valuta a corso legale (come dollari USA ed euro) con asset
                  digitali (come security, azioni, obbligazioni, ecc.) e
                  viceversa, e per scambiare un tipo di valuta digitale con un
                  altro tipo di asset digitale. Puoi anche utilizzare i nostri
                  Servizi per acquistare e vendere asset digitali direttamente
                  da e attraverso noi. Inoltre, la gamma di servizi disponibili
                  per te può dipendere in parte dal paese da cui accedi a EDSX.
                </span>
              </p>
              <ul
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Idoneità e Uso Accettabile
                  </span>
                </li>
              </ul>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  Devi soddisfare determinati requisiti di idoneità per
                  utilizzare EDSX. Ad esempio, devi essere maggiorenne e ci sono
                  determinate località da cui potresti non essere in grado di
                  utilizzare alcuni o tutti i servizi EDSX. Inoltre, ci sono
                  alcune cose che non puoi fare quando utilizzi EDSX, come
                  intraprendere attività illegali, mentire o fare qualsiasi cosa
                  che potrebbe causare danni ai nostri servizi o sistemi. Fai
                  riferimento alla sezione su un uso accettabile per ulteriori
                  dettagli.
                </span>
              </p>
              <ul
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Rischi legati al Trading
                  </span>
                </li>
              </ul>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  Effettuare operazioni di trading può comportare dei rischi.
                  Non utilizzare EDSX o nessuna delle opzioni di trading se non
                  comprendi questi rischi.
                </span>
              </p>
              <ul
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Altri Importanti Termini Legali
                  </span>
                </li>
              </ul>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Nel completo Termini di Servizio sono presenti condizioni
                  legali importanti, tra cui le tue responsabilità di
                  indennizzo, i nostri disclaimer di responsabilità e garanzia,
                  e il tuo accordo ad arbitrare la maggior parte delle
                  controversie. Leggi attentamente questi termini. Se avessi
                  domande potrai sempre contattarci tramite supporto a&nbsp;
                </span>
                <a href="mailto:info@edsx.ch">
                  <span style={{ fontWeight: 400 }}>info@edsx.ch</span>
                </a>
                <span style={{ fontWeight: 400 }}>.</span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2. <b>Termini completi del servizio</b>
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Questo Accordo di Membership (l’”Accordo”) è stipulato tra:
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                (1) &nbsp;
                <span style={{ fontWeight: 400 }}>
                  EDSX, una società costituita in Svizzera, Zug con sede legale
                  in Baarerstrasse 82, 6302 Zug, c/o AbaFin Treuhand AG,
                  Svizzera;
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                e
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                (2){" "}
                <span style={{ fontWeight: 400 }}>
                  Tu, in qualità di membro EDSX.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Il presente Accordo costituisce un contratto vincolante tra
                  voi e noi e definisce i diritti e gli obblighi di entrambe le
                  parti rispetto alla vostra membership di EDSX e all’utilizzo
                  della piattaforma. Accettando il presente Accordo, state
                  indicando di accettare e di essere vincolati a tutti i suoi
                  termini.
                </span>
              </p>
              <table
                style={{
                  margin: 0,
                  padding: 0,
                  color: "#4f5362",
                  fontFamily: '"Gotham Pro"',
                }}
              >
                <tbody style={{ margin: 0, padding: 0 }}>
                  <tr style={{ margin: 0, padding: 0 }}>
                    <td style={{ margin: 0, padding: 0 }}>
                      <p
                        style={{
                          marginBottom: "1rem",
                          color: "#4f4f6f",
                          fontFamily: '"Gotham Pro Light"',
                          lineHeight: "1.6",
                        }}
                      >
                        <span
                          style={{
                            margin: 0,
                            padding: 0,
                            fontWeight: "bolder",
                            fontFamily: '"Gotham Pro Bold"',
                          }}
                        >
                          &nbsp;
                        </span>
                        <strong>SI PREGA DI CONSIDERARE CHE:</strong>
                      </p>
                      <ul
                        style={{
                          marginTop: 10,
                          paddingLeft: 20,
                          color: "#4f4f6f",
                          fontFamily: '"Gotham Pro Light"',
                          lineHeight: "1.6",
                        }}
                      >
                        <li
                          style={{
                            marginBottom: 10,
                            paddingLeft: 25,
                            listStyle: "none",
                            position: "relative",
                          }}
                        >
                          <b>
                            EDSX NON È REGISTRATA COME INTERMEDIARIO FINANZIARIO
                            DA FINMA
                          </b>
                        </li>
                        <li
                          style={{
                            marginBottom: 10,
                            paddingLeft: 25,
                            listStyle: "none",
                            position: "relative",
                          }}
                        >
                          <b>
                            EVENTUALI DEPOSITI EFFETTUATI SU EDSX NON SONO
                            COPERTI DAL FONDO DI GARANZIA DEI DEPOSITI
                          </b>
                        </li>
                        <li
                          style={{
                            marginBottom: 10,
                            paddingLeft: 25,
                            listStyle: "none",
                            position: "relative",
                          }}
                        >
                          <b>
                            EVENTUALI DEPOSITI EFFETTUATI SU EDSX NON VENGONO
                            INVESTITI NÉ REMUNERATI
                          </b>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                <span style={{ fontWeight: 400 }}>
                  I presenti Termini di servizio e qualsiasi clausola in essi
                  espressamente incorporata (“Termini”) si applicano al tuo
                  accesso e utilizzo dei siti web e delle applicazioni mobili
                  forniti da EDSX e dalle sue piattaforme interamente
                  controllate (collettivamente, “
                </span>
                <b>EDSX</b>
                <span style={{ fontWeight: 400 }}>” o “</span>
                <b>noi</b>
                <span style={{ fontWeight: 400 }}>
                  “), nonché ai servizi di trading e vendita diretta forniti da
                  EDSX come descritti nei presenti Termini (collettivamente, i “
                </span>
                <b>Nostri Servizi</b>
                <span style={{ fontWeight: 400 }}>“).</span>
              </p>
              <p>&nbsp;</p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>Definizioni chiave</span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  I termini non altrimenti definiti nei presenti Termini avranno
                  il significato seguente:
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    “Account esterno” è sinonimo di “Conto finanziario”.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    “Conto per Asset Digitali” indica qualsiasi indirizzo o
                    conto di Asset Digitali di tua proprietà o gestito da te che
                    è mantenuto al di fuori dei Servizi e non è di proprietà,
                    controllato o gestito da EDSX
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    “Asset Digitale” indica criptovalute o titoli finanziari che
                    possono&nbsp; &nbsp; &nbsp;essere acquistati, venduti o
                    negoziati tramite i Servizi.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    “Account EDSX” indica un account utente accessibile tramite
                    i Servizi dove, tuttavia, i Fondi non possono essere
                    memorizzati all’interno della piattaforma (è necessario
                    avere un Account esterno).
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    “Valuta a corso legale” o “FIAT” indica qualsiasi valuta
                    nazionale, come l’euro, che può essere utilizzata in
                    relazione a un acquisto o una vendita di Asset Digitali
                    tramite i Servizi e non include alcun Asset Digitale.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    “Fondi” indica Valuta Digitale e/o Valuta a corso legale.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    “Conto finanziario” indica qualsiasi conto finanziario di
                    cui sei il beneficiario economico mantenuto da un terzo al
                    di fuori dei Servizi, inclusi, a titolo esemplificativo,
                    conti di servizi di pagamento di terze parti o conti
                    mantenuti presso istituzioni finanziarie di terze parti.
                  </span>
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.1. <b>Idoneità</b>
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  EDSX potrebbe non rendere i Servizi disponibili in tutti i
                  mercati e giurisdizioni e potrebbe limitare o vietare l’uso
                  dei Servizi da determinate giurisdizioni estere (“Luoghi
                  soggetti a restrizioni”). Se ti stai registrando per
                  utilizzare i Servizi per conto di un’entità giuridica,
                  dichiari e garantisci che (i) tale entità giuridica è
                  debitamente costituita e validamente esistente ai sensi delle
                  leggi applicabili della giurisdizione di sua costituzione; e
                  che (ii) sei debitamente autorizzato da tale entità giuridica
                  ad agire per suo conto.
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  Dichiari e garantisci inoltre che: (a) hai un’età legale per
                  stipulare un contratto vincolante (almeno 18 anni in
                  Svizzera); (b) non sei mai stato precedentemente sospeso o
                  rimosso dall’utilizzo dei Nostri Servizi; (c) hai pieni poteri
                  e autorità per stipulare il presente contratto e nel farlo non
                  violerai alcun altro contratto di cui sei parte; (d) non
                  risiedi, non sei sotto il controllo o non sei cittadino di (i)
                  eventuali Luoghi soggetti a restrizioni, o (ii) qualsiasi
                  paese nei confronti del quale l’ONU ha posto un embargo per
                  beni o servizi; e (f){" "}
                  <span style={{ textDecoration: "underline" }}>
                    non utilizzerai i Nostri Servizi se eventuali leggi
                    applicabili nel tuo paese te lo vietano secondo questi
                    Termini.
                  </span>
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  Dovrai informarci non appena ragionevolmente possibile qualora
                  dovessi smettere di essere idoneo a diventare membro. Se ci
                  informerai, o se saremo noi a scoprirlo, che hai smesso di
                  essere idoneo come membro, la tua membership sarà sospesa. Se
                  in seguito dovessi tornare ad essere idoneo a diventare membro
                  e ce lo comunicherai, la tua membership EDSX potrà essere
                  riattivata a nostra discrezione.
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.2. EDSX Account
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Numero di account EDSX.</b>
                  <span style={{ fontWeight: 400 }}>
                    {" "}
                    Puoi diventare membro EDSX una sola volta. Non tentare di
                    creare più membership per te stesso utilizzando diversi
                    indirizzi email o altre informazioni identificative. Se
                    tentassi di farlo, la tua membership potrebbe essere
                    terminata.&nbsp;
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Rappresentanti.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Come membro, se sei una società, una realtà non
                    costituita o un’entità governativa, devi designare almeno
                    una persona fisica ad agire per tuo conto. Ci riferiamo a
                    questa persona come tuo “Rappresentante”. Il tuo
                    Rappresentante è la persona che ha fornito il proprio nome
                    come tuo rappresentante designato nel modulo di iscrizione
                    sulla piattaforma. Se volessi sostituire il tuo
                    Rappresentante in qualsiasi momento, potrai farlo facendolo
                    notificare a noi dal Rappresentante uscente o da uno dei
                    tuoi direttori o funzionari. Il tuo Rappresentante non è un
                    membro individuale (salvo per quanto si iscrive
                    separatamente a tale titolo) e ha diritti e obblighi ai
                    sensi del presente Accordo solo nella misura in cui agisca
                    effettivamente o pretenda di agire per tuo conto. In tutto
                    l’Accordo, qualsiasi riferimento a “tu” che si riferisca a
                    un’azione specifica attraverso la piattaforma o a qualsiasi
                    altra azione che solo una persona fisica possa fisicamente
                    compiere, deve essere interpretato come un riferimento al
                    Rappresentante che agisce per tuo conto, mentre qualsiasi
                    altro riferimento a “tu” deve essere interpretato come
                    riferito a te come società membra, membro non incorporato o
                    membro governativo.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Accesso.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;L’accesso alla piattaforma EDSX viene eseguito
                    utilizzando un token di autenticazione. Il token di
                    autenticazione può essere una coppia nome utente e password,
                    impostata da te, o un token di autenticazione fornito da una
                    piattaforma esterna su cui hai un account. La tua membership
                    EDSX è personale e non è trasferibile. Fintanto che viene
                    utilizzato il token di autenticazione corretto, daremo per
                    scontato che sei tu a condurre attività sulla piattaforma.
                    Potresti avere uno o più token di autenticazione e potremmo
                    o meno chiederti token di autenticazione aggiuntivi per
                    consentirti di eseguire azioni specifiche sulla piattaforma.
                    Non potrai consentire a nessun’altra persona o dispositivo
                    di accedere ai tuoi token di autenticazione (inclusa, a
                    titolo esemplificativo, la condivisione o la divulgazione
                    accidentale dei tuoi token di autenticazione). Nel caso in
                    cui lo facessi, sarai pienamente responsabile di tutte le
                    azioni sulla piattaforma compiute dalla persona o dal
                    dispositivo cui hai consentito l’accesso e da qualsiasi
                    altra persona o dispositivo cui quella persona o quel
                    dispositivo abbiano consentito l’accesso, e noi non saremo
                    responsabili nei tuoi confronti per eventuali perdite, danni
                    o costi derivanti dal tuo consentire l’accesso ai tuoi token
                    di autenticazione. Dovresti tener presente che potremmo
                    sospendere o interrompere il tuo accesso alla piattaforma in
                    circostanze specifiche come descritto di seguito.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Informazioni e sicurezza del Account EDSX.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Al fine di effettuare qualsiasi operazione tramite i
                    Servizi, devi creare un Account EDSX e fornire le
                    informazioni richieste. Quando crei un Account EDSX, accetti
                    di: (a) creare una password robusta che non utilizzi per
                    nessun altro sito web o servizio online; (b) fornire
                    informazioni accurate e veritiere; (c) mantenere e
                    aggiornare tempestivamente le informazioni del tuo Account
                    EDSX; (d) mantenere la sicurezza del tuo Account EDSX
                    proteggendo la tua password e limitando l’accesso al tuo
                    Account EDSX; (e) notificarci immediatamente se scopri o
                    sospetti qualsiasi violazione della sicurezza relative al
                    tuo Account EDSX; e (f) assumerti la responsabilità di tutte
                    le attività che verranno effettuate tramite il tuo Account
                    EDSX e accettare tutti i rischi di qualsiasi accesso
                    autorizzato o non autorizzato al tuo Account EDSX, nella
                    massima misura consentita dalla legge. I tuoi token di
                    autenticazione sono i metodi che utilizziamo per
                    identificarti e devi mantenerli al sicuro in ogni momento.
                    Devi notificarci immediatamente se scopri o sospetti che la
                    sicurezza del tuo nome utente o password, o degli account
                    che utilizzi per accedere alla piattaforma, possa essere
                    stata violata. Se ricevessimo una comunicazione da te o
                    determinassimo noi stessi che la sicurezza di uno dei tuoi
                    token di autenticazione potrebbe essere stata violata, non
                    potrai accedere alla piattaforma fino a quando non saranno
                    state adottate misure per verificare la tua identità.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Emittenti.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Gli emittenti sono gli unici responsabili per la loro
                    pagina di landing page dove un investitore può
                    effettivamente investire. Le landing page degli emittenti
                    sono gestite completamente dagli emittenti. Un emittente è
                    l’unico responsabile per qualsiasi contenuto pubblicato
                    sulla piattaforma, inclusi profilo, informazioni
                    sull’attività per la quale sta cercando capitali,
                    informazioni pubblicate in relazione a un processo
                    post-investimento e qualsiasi altra informazione pubblicata.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Membri/utenti.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Sei l’unico responsabile per qualsiasi contenuto
                    pubblichi sulla piattaforma, incluso il tuo profilo,
                    messaggi ad altri membri, post sul forum e qualsiasi altra
                    informazione pubblichi. Non puoi pubblicare, trasmettere o
                    condividere informazioni sulla piattaforma di cui non sei
                    proprietario o per le quali non hai il permesso di
                    visualizzare, pubblicare o postare. Né noi né altri membri
                    EDSX saranno ritenuti responsabili per i tuoi contenuti e
                    accetti di indennizzare noi e gli altri membri EDSX per
                    qualsiasi perdita, danno o costo derivante da o in relazione
                    a qualsiasi contenuto che pubblichi sulla piattaforma.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Interazioni con Altri Membri EDSX.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Sei l’unico responsabile delle tue interazioni con gli
                    altri membri EDSX. Ci riserviamo il diritto, ma non
                    l’obbligo, di monitorare azioni e dispute tra te e altri
                    membri EDSX e ci riserviamo il diritto di rimuovere
                    qualsiasi comunicazione che pubblichi e che riteniamo
                    offensiva, molesta, spam o comunque inappropriata (inclusa
                    qualsiasi discussione sull’instaurare un’azione legale).
                  </span>
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.3. <b />
                </span>
                <b>Relazione con te</b>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Membership.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Con la stipula del presente Accordo, diventerai un
                    membro EDSX e rimarrai membro EDSX fino a quando la tua
                    membership non verrà terminata o sospesa. I nostri obblighi
                    nei tuoi confronti come membro sono indicati nel presente
                    Accordo. Non sei nostro cliente o committente.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Nessuna consulenza.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Non forniamo consulenze o raccomandazioni
                    relativamente a qualsiasi aspetto delle transazioni
                    effettuate attraverso la piattaforma, ad eccezione dei
                    consigli sull’uso tecnico della piattaforma. Ciò significa,
                    tra l’altro, che non possiamo fornirti alcuna consulenza in
                    materia di investimenti, legale, fiscale o di altro tipo in
                    relazione alla tua membership o agli investimenti che
                    effettui attraverso la piattaforma e nulla contenuto nella
                    piattaforma o nelle nostre comunicazioni è da intendersi
                    come consiglio o raccomandazione. Se avessi bisogno o
                    desiderassi ricevere consulenze, dovresti consultare un
                    appropriato consulente professionale in materia finanziaria,
                    legale, fiscale o di altro tipo.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>
                    Nessuna offerta al pubblico e nessuna garanzia sulle
                    informazioni contenute sulla piattaforma.
                  </b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;L’opportunità di investire in un’attività ti è offerta
                    dall’emittente e non da EDSX. EDSX fornisce uno spazio
                    virtuale all’emittente per organizzare la documentazione
                    dell’offerta e per utilizzare smart contract per gestire
                    security token e criptovalute. Nulla sul nostro sito web
                    deve essere interpretato come una sollecitazione, un’offerta
                    o una raccomandazione per acquisire o disporsi di qualsiasi
                    investimento o per intraprendere qualsiasi altra
                    transazione. Nulla contenuto nel sito web di EDSX
                    costituisce consulenza o raccomandazione in materia di
                    investimenti, legale, fiscale o di altro tipo né può essere
                    preso in considerazione nell’assumere una decisione di
                    investimento o di altro tipo. Dovresti avvalerti di
                    un’appropriata consulenza professionale specifica prima di
                    prendere qualsiasi decisione di investimento. Sebbene EDSX
                    si impegni ragionevolmente per ottenere informazioni da
                    fonti ritenute attendibili, EDSX non garantisce
                    l’accuratezza, l’affidabilità o la completezza delle
                    informazioni o opinioni contenute nel sito web. Le
                    informazioni e opinioni contenute nel sito web sono fornite
                    da EDSX a solo scopo personale e informativo e sono soggette
                    a modifiche senza preavviso. Le informazioni generalmente
                    accessibili come i prezzi delle azioni e dei tassi di cambio
                    sono considerate temporanee e non vincolanti. Un prezzo non
                    costituisce un’offerta o una sollecitazione di un’offerta di
                    acquisto.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Luogo delle attività.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Stipulando il presente Accordo, accetti che tutte le
                    attività che svolgiamo con te avvengano in Svizzera,
                    indipendentemente dal luogo in cui possa trovarti
                    fisicamente al momento in cui utilizzi la piattaforma o
                    interagisca con noi in altro modo. Ciò significa che accetti
                    che le nostre attività siano soggette unicamente alle leggi
                    svizzere e non alle leggi di qualsiasi altro paese in cui tu
                    possa trovarti fisicamente in un dato momento e, di
                    conseguenza, che qualsiasi ricorso eventualmente proposto
                    nei nostri confronti debba essere proposto secondo la legge
                    svizzera. Tuttavia, accetti comunque di rispettare tutte le
                    leggi e normative applicabili al tuo utilizzo della
                    piattaforma in qualsiasi giurisdizione in cui possa
                    trovarti. Accetti che in nessuna circostanza formulerai
                    pretese secondo le quali le nostre attività si svolgano, o
                    siano soggette, alle leggi di qualsiasi Paese diverso dalla
                    Svizzera.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Autorizzazione all’investimento.&nbsp;</b>
                  <span style={{ fontWeight: 400 }}>
                    La decisione se autorizzarti ad agire come investitore
                    spetta interamente all’emittente, che può decidere per
                    qualsiasi motivo di non autorizzarti.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Territori esteri.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;I membri EDSX residenti o situati in territori al di
                    fuori della Svizzera (“membri esteri”) potrebbero non
                    diventare membri autorizzati all’investimento se il loro
                    accesso alle opportunità di investimento e gli investimenti
                    contravvenissero a eventuali normative locali o altri
                    requisiti regolamentari. Ai membri esteri potrebbe essere
                    richiesto di conformarsi a requisiti aggiuntivi o
                    alternativi per diventare membri autorizzati
                    all’investimento.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Controlli sull’identità e antiriciclaggio.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Ai sensi delle norme svizzere e di altre normative
                    applicabili, abbiamo determinate responsabilità nel
                    verificare l’identità dei membri che agiscono come
                    investitori (anche se generalmente non gestiamo direttamente
                    alcun flusso monetario). Al fine di adempiere a tali
                    responsabilità, potremmo utilizzare servizi di verifica
                    dell’identità di terze parti per confermare la tua identità
                    quando richiederai per la prima volta il trasferimento di
                    denaro sul conto dell’emittente. Nel caso in cui tale
                    servizio non fosse in grado di verificare la tua identità
                    con un livello di sicurezza sufficiente, potresti essere
                    invitato a inviarci versioni fisiche o scannerizzate di
                    determinati documenti di identità. Potremmo aver bisogno di
                    eseguire controlli dell’identità aggiuntivi su di te.
                    Stipulando il presente Accordo, acconsenti espressamente
                    alla nostra esecuzione di tali controlli e all’impossibilità
                    di trasferire denaro sul Conto Emittente o effettuare
                    investimenti finché detti controlli non saranno completati.
                    Non saremo responsabili per eventuali perdite, danni o costi
                    derivanti dall’esecuzione di tali controlli o
                    dall’impossibilità da parte tua di trasferire denaro o
                    effettuare investimenti durante l’esecuzione dei controlli o
                    a seguito del mancato o insoddisfacente completamento degli
                    stessi.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Tassazione.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;I tuoi investimenti potrebbero essere soggetti a
                    imposizione fiscale. Il trattamento fiscale dipende dalle
                    circostanze individuali e può essere soggetto a modifiche in
                    futuro. Per esempio, potresti essere tenuto al pagamento
                    delle imposte sui dividendi o gli utili ricevuti dagli
                    investimenti. Il pagamento delle imposte è interamente a tuo
                    carico, non forniremo dichiarazioni o informazioni
                    riguardanti la tua posizione o gli obblighi fiscali. In
                    aggiunta, potresti beneficiare di determinate agevolazioni
                    fiscali sugli investimenti effettuati. L’eventuale
                    disponibilità di tali agevolazioni sarà descritta nel
                    &nbsp;relativo contratto di investimento. Se hai dubbi in
                    materia fiscale, dovresti consultare un consulente
                    professionale.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Informazioni sulla Piattaforma</b>
                  <span style={{ fontWeight: 400 }}>
                    . Oltre alle informazioni fornite in ciascuna campagna,
                    potremmo mettere a disposizione sulla piattaforma
                    informazioni sull’investimento o la gestione di imprese in
                    generale o altre materie che riteniamo possano essere
                    rilevanti o di interesse per te. Ci riferiamo a queste come
                    “informazioni aggiuntive”. Nessuna delle informazioni
                    aggiuntive, incluse le informazioni sui rendimenti storici e
                    i contenuti delle newsfeed, può essere considerata a
                    garanzia o indicazione di alcun particolare risultato e le
                    informazioni aggiuntive non costituiscono alcuna forma di
                    consiglio, raccomandazione o sponsorizzazione da parte
                    nostra. Non possiamo assicurarti che le informazioni
                    aggiuntive siano complete, accurate, aggiornate o prive di
                    errori e non saremo responsabili per eventuali perdite,
                    danni o costi nel caso in cui non lo siano. Nella misura in
                    cui le informazioni aggiuntive contengano link a siti web di
                    terzi o contatti di terzi, non assumiamo alcuna
                    responsabilità per la disponibilità o accuratezza di tali
                    siti web o contatti o per gli atti di tali terzi.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Comunicazioni sulle Attività della Piattaforma</b>
                  <span style={{ fontWeight: 400 }}>
                    . Di tanto in tanto potremmo inviarti email contenenti
                    informazioni sulle attività sulla piattaforma, incluse nuove
                    campagne e l’andamento di quelle in corso. Eventuali email
                    che ti inviassimo in merito alle attività sulla piattaforma
                    non costituiscono consigli o raccomandazioni ad investire.
                    Potremmo anche inviarti di tanto in tanto email relative
                    alla tua membership, campagne, investimenti, alla nostra
                    attività, al presente Accordo o ad altre materie che
                    riteniamo rilevanti per te. Faremo del nostro meglio per
                    garantire che tali email siano relativamente poco frequenti,
                    ma potremmo inviarti tali email a nostra discrezione.
                  </span>
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.4.{" "}
                </span>
                <b>Informativa sulla Privacy</b>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  Si prega di fare riferimento alla nostra Informativa sulla
                  Privacy per informazioni su come raccogliamo, usiamo e
                  condividiamo i tuoi dati.
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.5.{" "}
                </span>
                <b>Condizioni Generali</b>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  La presente Sezione si applica: (i) a tutte le negoziazioni
                  completate tramite i Servizi, (ii) all’acquisto e/o vendita di
                  Asset Digitali direttamente da EDSX o l’emittente tramite i
                  Servizi.
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Condizioni e restrizioni.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Potremmo in qualsiasi momento imporre condizioni o
                    restrizioni sul tuo utilizzo dei Servizi senza preavviso.
                    Per esempio, potremmo: limitare il numero di ordini aperti
                    che puoi stabilire tramite i Servizi, sospendere il
                    servizio, sospendere o bloccare un utente, modificare in
                    tutto o in parte i termini di servizio senza preavviso, o
                    potremmo limitare le negoziazioni da determinate località.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Nessuna partnership o agenzia.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Il presente Accordo non deve essere interpretato in
                    modo da creare una partnership o joint venture tra te e noi.
                    Nulla nel presente Accordo deve essere interpretato in modo
                    da costituire te e noi agenti uno dell’altro.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Mercato primario.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Ogni emittente gestisce autonomamente la propria
                    pagina e i titoli vengono venduti direttamente
                    dall’emittente all’investitore.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Accuratezza delle informazioni.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Devi fornire tutte le informazioni richieste quando
                    crei un Account EDSX o quando visualizzi qualsiasi schermata
                    all’interno dei Servizi. Dichiari e garantisci che eventuali
                    informazioni fornite tramite i Servizi siano accurate e
                    complete.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Bacheca. Operazioni non in garanzia.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Nell’area bacheca le operazioni non in garanzia
                    vengono completate autonomamente tra le parti. EDSX non
                    assume alcuna responsabilità per il rischio della
                    controparte. Ogni parte deve negoziare e concordare tutti
                    gli aspetti della transazione (importo, prezzo, tempistiche,
                    contratti aggiuntivi, ecc.) e eseguirla senza alcun supporto
                    da parte di EDSX. EDSX non assume alcuna responsabilità in
                    questo tipo di transazione.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Bacheca. Operazioni in garanzia in valuta FIAT.</b>
                  <span style={{ fontWeight: 400 }}>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      Nell’area bacheca, EDSX fornisce un servizio di garanzia
                      per il completamento della negoziazione in valuta FIAT. Le
                      seguenti opzioni sono disponibili:&nbsp;
                    </span>
                  </span>
                  <i>
                    <span style={{ fontWeight: 400 }}>
                      Proposta d’acquisto.
                    </span>
                  </i>
                  <span style={{ fontWeight: 400 }}>
                    {" "}
                    L’acquirente invia il denaro sul conto in garanzia indicato
                    dalla piattaforma. La piattaforma detiene il denaro in
                    garanzia fintanto che l’utente mantiene la proposta
                    d’acquisto sulla bacheca. La parte che vende i token invierà
                    direttamente questi all’acquirente, dando comunicazione alla
                    piattaforma. La piattaforma verificherà tramite lo scanner
                    sulla blockchain il ricevimento dei token sul wallet
                    assegnato e rilascerà il denaro sulle coordinate bancarie
                    fornite dal venditore.
                  </span>
                  <p>
                    <i>
                      <span style={{ fontWeight: 400 }}>
                        Proposta di vendita
                      </span>
                    </i>
                    <span style={{ fontWeight: 400 }}>
                      . Quando viene inserita la proposta di vendita, i token
                      vengono bloccati dal smart contract (nel wallet del
                      venditore) che li trattiene per la durata dell’offerta
                      sulla piattaforma. Una volta che l’acquirente ha cliccato
                      sul tasto “compra”, è obbligato all’acquisto a quel
                      prezzo, la proposta di vendita verrà nascosta al pubblico
                      e l’acquirente dovrà inviare il denaro direttamente a EDSX
                      entro massimo 10 giorni. Gli acquirenti che non rispettano
                      questa regola potrebbero essere sanzionati. Una volta
                      ricevuto il denaro, EDSX lo invierà al venditore e i token
                      all’acquirente.
                    </span>
                  </p>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <strong>Bacheca. Smart contract criptovaluta.</strong>
                  <span style={{ fontWeight: 400 }}>
                    Lo smart contract è un algoritmo messo a disposizione delle
                    parti, il cui codice può essere verificato prima
                    dell’utilizzo (è gratuito e open source), e le parti possono
                    decidere di utilizzarlo o meno secondo la loro libera
                    volontà.&nbsp; &nbsp; &nbsp;
                  </span>
                  <p>
                    <i>
                      <span style={{ fontWeight: 400 }}>
                        Proposta d’acquisto.
                      </span>
                    </i>
                    <span style={{ fontWeight: 400 }}>
                      &nbsp;Quando viene inserita la proposta d’acquisto, la
                      criptovaluta viene inviata allo smart contract in attesa
                      del venditore. Quando il venditore invia i corrispondenti
                      token, lo smart contract rilascia i token all’acquirente e
                      la criptovaluta al venditore.
                    </span>
                  </p>
                  <p>
                    <i>
                      <span style={{ fontWeight: 400 }}>
                        Proposta di vendita.
                      </span>
                    </i>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      Quando viene inserita la proposta di vendita, i token
                      vengono bloccati dallo smart contract (nel wallet del
                      venditore) in attesa dell’acquirente. Quando l’acquirente
                      invia la corrispondente criptovaluta, lo smart contract
                      rilascia i token all’acquirente e la criptovaluta al
                      venditore.
                    </span>
                  </p>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <strong>
                    Cancellazioni (in caso di operazioni in garanzia).
                  </strong>
                  <span style={{ fontWeight: 400 }}>
                    Puoi cancellare solo un ordine stabilito tramite i Servizi
                    se tale cancellazione avviene prima che EDSX o lo smart
                    contract esegua la transazione. Una volta eseguito il tuo
                    ordine, non puoi modificare, ritirare o cancellare la tua
                    autorizzazione per il completamento di tale transazione.
                    Sebbene possiamo, a nostra esclusiva discrezione annullare
                    una negoziazione in determinate condizioni eccezionali, non
                    si ha diritto all’annullamento di una negoziazione.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Fondi insufficienti.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Se avessi un importo insufficiente di Fondi per
                    completare un ordine tramite i Servizi, potremmo cancellare
                    l’intero ordine al netto di eventuali commissioni dovute a
                    EDSX in relazione alla nostra esecuzione della negoziazione
                    (come descritto di seguito).
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Commissioni.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;EDSX può in ogni momento introdurre o modificare le
                    commissioni a propria esclusiva discrezione per l’utilizzo
                    dei Servizi.
                  </span>
                  <br />
                  <b />
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Tasse.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Spetta a te determinare quali eventuali tasse si
                    applicano alle negoziazioni completate tramite i Servizi e a
                    te presentare la dichiarazione e versare le corrette imposte
                    all’autorità fiscale competente. Riconosci che EDSX non è
                    responsabile di stabilire se si applicassero tasse alle tue
                    negoziazioni o di riscuotere, presentare dichiarazioni,
                    trattenere o versare eventuali imposte derivanti da
                    qualsiasi negoziazione.
                  </span>
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.6. <b />
                </span>
                <b>Account Esterno</b>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Per completare un ordine o una negoziazione tramite i Servizi,
                  devi detenere un Account Esterno identificato tramite i
                  Servizi. Potrebbe esserti richiesto di verificare e
                  controllare l’Account Esterno. Come già descritto, l’Account
                  Esterno da te utilizzato potrebbe addebitarti delle
                  commissioni, EDSX non è responsabile per eventuali commissioni
                  dell’Account Esterno o per la gestione e la sicurezza di
                  qualsiasi Account Esterno. Sei l’unico responsabile del tuo
                  utilizzo di qualsiasi Account Esterno e accetti di rispettare
                  tutti i termini e condizioni applicabili a qualsiasi Account
                  Esterno. I tempi associati a una negoziazione possono
                  dipendere in parte dalle prestazioni di terzi responsabili
                  della manutenzione dell’Account Esterno applicabile e EDSX non
                  fornisce alcuna garanzia sui tempi di invio o ricezione di
                  fondi e/o asset digitali.
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.7. Mercato primario
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                  value={1}
                >
                  <b>Campagne.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Se diventi membro, una volta effettuato l’accesso alla
                    piattaforma, potrai visualizzare le società disponibili per
                    l’investimento. Inizialmente vedrai le informazioni
                    riassuntive sulle società che attualmente cercano capitale
                    sulla piattaforma, e ci riferiamo a queste informazioni come
                    “sommario della campagna”. Puoi cliccare su qualsiasi
                    sommario della campagna per accedere alla pagina
                    dell’emittente e vedere ulteriori informazioni sulla
                    società, che definiamo “campagna dettagliata” (un sommario
                    della campagna e una campagna dettagliata insieme
                    costituiscono la “campagna” di una società; le campagne
                    dettagliate sono gestite direttamente dall’emittente). La
                    campagna dettagliata è l’informazione sulla base della quale
                    prenderai una decisione di investimento riguardo alla
                    società.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>La Nostra Revisione e Approvazione.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Facciamo del nostro meglio per garantire che le
                    informazioni presentate dall’emittente siano “equanime,
                    chiare e non fuorvianti”, tuttavia gli emittenti rimangono
                    gli unici responsabili delle informazioni presentate.
                    Dovresti considerare che, nel caso di dichiarazioni di
                    fatto, le prove che esaminiamo sono fornite dall’impresa, e
                    sebbene prestiamo ragionevole cura nella nostra revisione,
                    non le sottoponiamo a verifica, il che significa che
                    potremmo non essere in grado, e non saremo responsabili se
                    non riuscissimo a identificare prove o informazioni
                    falsificate o alterate o dichiarazioni deliberatamente
                    fuorvianti o inesatte.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>
                    Nessuna raccomandazione; nessuna affidabilità su altre
                    informazioni.
                  </b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;La nostra revisione di una campagna non significa che
                    stiamo raccomandando di fare un investimento nella società,
                    che crediamo che l’impresa avrà successo o che assumiamo
                    alcuna responsabilità o saremo in alcun modo responsabili
                    nei tuoi confronti se l’impresa non avrà successo. La
                    decisione di investimento rispetto a qualsiasi investimento
                    attraverso la pagina di destinazione dell’emittente è tua e
                    solo tua. Formalmente, non abbiamo esaminato o approvato
                    alcuna informazione sulla società. Il forum di discussione e
                    le sezioni documenti, e qualsiasi documento aggiuntivo
                    disponibile per il download non costituiscono parte della
                    campagna. Qualsiasi risposta a domande nel forum di
                    discussione e nelle sezioni documenti da parte della società
                    e qualsiasi documento scaricato su richiesta costituiscono
                    “comunicazioni occasionali” e dovrebbero essere trattate
                    allo stesso modo come se avessi una conversazione via email
                    uno-a-uno con la società &nbsp;in questione senza alcun
                    coinvolgimento da parte nostra. Inoltre, nessuna altra
                    informazione esposta al di fuori della campagna – inclusi
                    nei documenti forniti dalla società, in o tramite qualsiasi
                    newsfeed fornito sulla piattaforma EDSX, sul sito web della
                    società, nei profili dei social media o altrove –
                    costituisce parte della campagna, e non è stata esaminata o
                    approvata da noi. Non ci assumiamo alcuna responsabilità per
                    le dichiarazioni fatte nel forum di discussione e nelle
                    sezioni documenti dalla società, per qualsiasi informazione
                    fornita in o tramite qualsiasi newsfeed sulla piattaforma
                    EDSX, o per documenti o altre informazioni fornite da o
                    sulla società al di fuori della campagna e non dovresti fare
                    affidamento su tali informazioni per prendere la tua
                    decisione di investire. Per quanto riguarda qualsiasi
                    newsfeed fornito sulla piattaforma, tale contenuto è fornito
                    solo a scopo informativo. EDSX non si assume alcun obbligo
                    di aggiornare i newsfeed e può modificare o eliminare il
                    contenuto in qualsiasi momento senza preavviso.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Confidenzialità e Uso Appropriato delle Campagne.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Le campagne sono presentate sulla piattaforma per
                    consentirti di prendere le tue decisioni riguardo
                    l’investimento e non per altri scopi. Sebbene tu non sia
                    legalmente obbligato a mantenere riservate le informazioni
                    presentate nelle campagne, non potrai usarle in alcun modo
                    per competere con o impedire in altro modo il successo della
                    società specifica, e se qualora lo facessi, potremmo
                    intraprendere azioni legali contro di te per eventuali danni
                    che avessi causato alla nostra reputazione, al nostro
                    business o in altro modo.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    <strong>Selezione degli investimenti.</strong> Dopo aver
                    visionato una campagna, potrai scegliere di investire
                    nell’attività cliccando sull’apposito pulsante e ti verrà
                    chiesto di indicare quanto vorresti investire. In tal caso
                    il vostro rapporto sarà regolato tra voi e l’emittente e per
                    questo motivo vi verrà presentato anche un Accordo di
                    Investimento in relazione all’investimento.
                  </span>
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.8. <b>Post-investimento</b>
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                  value={1}
                >
                  <span style={{ fontWeight: 400 }}>
                    <strong>Informazioni e Diritti.</strong> Una volta stipulato
                    un Accordo di Investimento con un emittente e ricevuti i
                    relativi token, l’investimento sarà riportato nella sezione
                    “Portafoglio” del tuo conto. Sarai in grado di visualizzare
                    informazioni sulla società attraverso la piattaforma (le
                    relazioni con gli investitori sono gestite dall’emittente) e
                    potrai accedere a informazioni ed esercitare altri
                    determinati diritti, tutto come stabilito nell’Accordo di
                    Investimento.
                  </span>
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.9. <b />
                </span>
                <b>Ordini e negoziazioni sulla bacheca</b>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Nel post-investimento, gli emittenti avranno l’opportunità di
                  creare una bacheca per i loro titoli.
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  La presente Sezione si applica unicamente quando utilizzi i
                  Servizi per negoziare Asset Digitali in Contropartita
                  Monetaria o viceversa, o per negoziare Asset Digitali in
                  un’altra forma di Asset Digitali.
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                  value={1}
                >
                  <b>Rapporto indipendente.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Riconosci e acconsenti che: (a) EDSX non opera come
                    tuo broker, intermediario, agente o consulente o in alcuna
                    veste fiduciaria e (b) nessuna comunicazione o informazione
                    fornita da EDSX deve essere considerata o interpretata come
                    una consulenza.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Conferma della negoziazione.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Una volta eseguita la tua negoziazione tramite i
                    Servizi (sulla blockchain), una conferma sarà resa
                    disponibile elettronicamente tramite i Servizi con i
                    dettagli della negoziazione. Riconosci e acconsenti che il
                    mancato invio di tale conferma da parte dei Servizi non
                    pregiudichi o invalidi i termini di tale negoziazione.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Volatilità di mercato.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;In particolare, durante periodi di elevato volume,
                    scarsa liquidità, rapida variazione o volatilità sul mercato
                    per Asset Digitali o Contropartita Monetaria, comprendi che
                    non siamo responsabili per tali fluttuazioni di prezzo. In
                    caso di interruzione di mercato o evento di forza maggiore,
                    EDSX può adottare una o più delle seguenti misure: (a)
                    sospendere l’accesso ai Servizi; o (b) impedirti di
                    completare azioni tramite i Servizi. Di seguito a tali
                    eventi, al momento della ripresa della negoziazione,
                    riconosci che le quotazioni di mercato prevalenti possano
                    differire significativamente dalle quotazioni presenti prima
                    di tali eventi.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Regolamento delle negoziazioni.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Fatte salve le condizioni e le regole di questi
                    Termini, saremo impegnati ragionevolmente ad eseguire le
                    negoziazioni ad hoc entro dieci (10) giorni dalla data in
                    cui gli utenti hanno concordato di eseguire una negoziazione
                    tramite i Servizi.
                  </span>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.10. <b>Informativa sui rischi</b>
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Rischi di negoziazione.</b>&nbsp;
                  <span style={{ fontWeight: 400 }}>
                    Riconosci e accetti di accedere e utilizzare i Servizi a tuo
                    rischio e pericolo. Il rischio di perdita nel trading di
                    coppie di asset digitali e coppie di asset digitali e valuta
                    legale può essere sostanziale. &nbsp;Dovresti pertanto
                    valutare attentamente se tale negoziazione sia adatta a te
                    alla luce delle tue circostanze e risorse finanziarie.
                    Dovresti essere consapevole dei seguenti punti
                  </span>
                  :<br />
                  <ul
                    style={{
                      paddingLeft: 0,
                      fontFamily: '"Gotham Pro Light"',
                      lineHeight: "1.6",
                    }}
                  >
                    <li style={{ listStyle: "decimal" }}>
                      <span style={{ fontWeight: 400 }}>
                        Tutte le posizioni in Asset Digitali comportano rischi
                      </span>
                      .
                    </li>
                    <li style={{ listStyle: "decimal" }}>
                      <span style={{ fontWeight: 400 }}>
                        Potresti trovare difficile o impossibile liquidare una
                        posizione. Ciò può verificarsi, per esempio, in caso di
                        scarsa liquidità sul mercato
                      </span>
                      .
                    </li>
                    <li style={{ listStyle: "decimal" }}>
                      <span style={{ fontWeight: 400 }}>
                        Potresti subire una perdita totale dei Fondi nel tuo
                        Account EDSX o Account Esterno
                      </span>
                      .<br style={{ margin: 0, padding: 0 }} />
                      <span style={{ fontWeight: 400 }}>
                        TUTTI I PUNTI SOPRA ELENCATI SI APPLICANO A OGNI TIPO DI
                        NEGOZIAZIONE DI COPPIE DI ASSET DIGITALI E COPPIE DI
                        ASSET DIGITALI E CONTROPARTITE MONETARIE
                      </span>
                      .
                    </li>
                  </ul>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <span
                    style={{
                      margin: 0,
                      padding: 0,
                      fontWeight: "bolder",
                      fontFamily: '"Gotham Pro Bold"',
                    }}
                  >
                    <b>Rischi di trasmissione via Internet.</b>
                  </span>{" "}
                  Riconosci l’esistenza di rischi relativi all’utilizzo di un
                  sistema di negoziazione basato su Internet inclusi, tra gli
                  altri, malfunzionamenti di hardware, blockchain, software e
                  connessioni Internet.&nbsp;
                  <span style={{ textDecoration: "underline" }}>
                    Riconosci che EDSX non sarà responsabile per eventuali
                    problemi di comunicazione, interruzioni, errori, distorsioni
                    o ritardi che potresti riscontrare nella negoziazione
                    tramite i Servizi, indipendentemente dalla causa scatenant.
                  </span>
                </li>
              </ol>
              <p>&nbsp;</p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.11.{" "}
                </span>
                <b>Condizioni di vendita degli Asset Digitali</b>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  La presente Sezione si applica unicamente quando utilizzi i
                  Servizi per acquistare o vendere Asset Digitali direttamente
                  da EDSX (ad esempio criptovaluta EURO), un servizio
                  disponibile solo in giurisdizioni limitate.
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Prezzi; disponibilità.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Tutti i prezzi riflettono i tassi indicati sulla
                    bacheca applicabili all’acquisto o alla vendita di Asset
                    Digitali utilizzando la Contropartita Monetaria o un’altra
                    forma di Asset Digitali indicata nel tuo ordine d’acquisto.
                    Tutti gli acquisti e le vendite di Asset Digitali da parte
                    di EDSX sono soggetti a disponibilità e ci riserviamo il
                    diritto di sospendere l’acquisto e la vendita di Asset
                    Digitali senza preavviso.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Previsioni di acquisto</b>
                  <span style={{ fontWeight: 400 }}>
                    . Prima di completare il tuo acquisto o vendita di Asset
                    Digitali da EDSX, ti forniremo comunicazione dell’ammontare
                    di Asset Digitali che intendi acquistare o vendere e
                    dell’importo da versare a EDSX per ricevere tali Asset
                    Digitali o Contropartita Monetaria. Accetti di rispettare
                    eventuali termini e condizioni forniti all’interno di tale
                    comunicazione per completare la tua transazione di acquisto.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Errori.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;In caso di errore, sia attraverso i nostri Servizi,
                    nella conferma d’ordine, nella lavorazione del tuo acquisto
                    o altro, ci riserviamo il diritto di correggere tale errore
                    e rivedere di conseguenza la tua transazione d’acquisto
                    (incluso l’addebito del prezzo corretto) o di annullare
                    l’acquisto e rimborsare l’eventuale importo ricevuto. Il tuo
                    unico rimedio in caso di errore consiste nell’annullare
                    l’ordine d’acquisto e ottenere il rimborso di qualsiasi
                    importo addebitato.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Metodo di pagamento.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Solo metodi di pagamento validi specificati da noi
                    possono essere utilizzati per l’acquisto di Asset Digitali.
                    Inviando un ordine di acquisto di Asset Digitali da EDSX,
                    dichiari e garantisci che (a) sei autorizzato ad utilizzare
                    il metodo di pagamento selezionato e (b) autorizzi noi o il
                    nostro processore di pagamento ad addebitare sul metodo di
                    pagamento designato l’importo dovuto. Se il metodo di
                    pagamento da te indicato non può essere verificato, risulta
                    non valido o comunque non accettabile, il tuo ordine di
                    acquisto potrebbe essere automaticamente sospeso o
                    annullato. Ti impegni a risolvere eventuali problemi che
                    dovessimo incontrare al fine di procedere con il tuo ordine
                    di acquisto.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Nessun reso o rimborso.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Tutti gli acquisti e le vendite di Asset Digitali
                    effettuati da EDSX tramite i Servizi sono definitivi. Non
                    accettiamo restituzioni o rimborsi per il tuo acquisto di
                    Asset Digitali da EDSX, salvo quanto diversamente previsto
                    nei presenti Termini.
                  </span>
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.12. <b>Commissioni</b>
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <b>Importo delle commissioni</b>
                  <span style={{ fontWeight: 400 }}>
                    . Accetti di corrispondere a EDSX le commissioni per le
                    negoziazioni completate tramite i nostri Servizi (“
                  </span>
                  <b>Commissioni</b>
                  <span style={{ fontWeight: 400 }}>
                    “) come reso disponibile tramite la Tabella Commissioni
                    e&nbsp;
                  </span>
                  <b>Informazioni sulle coppie (“Tabella Commissioni”)</b>
                  <span style={{ fontWeight: 400 }}>
                    , che potremmo modificare di volta in volta. Le modifiche
                    alla Tabella Commissioni hanno effetto dalla data di
                    esecuzione indicata nella pubblicazione della Tabella
                    Commissioni riveduta sui Servizi e si applicheranno a
                    qualsiasi negoziazione che avvenga successivamente alla data
                    di efficacia di tale Tabella Commissioni rivista.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <b>Commissioni di terzi.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Oltre alle Commissioni, il tuo Account Esterno
                    potrebbe applicare commissioni in relazione all’utilizzo del
                    tuo Account Esterno designato tramite i Servizi. Eventuali
                    commissioni applicate dal fornitore del tuo Account Esterno
                    non saranno visualizzate nelle schermate di transazione
                    contenenti le informazioni sulle Commissioni applicabili.
                    Sei l’unico responsabile per il pagamento di eventuali
                    commissioni applicate dal fornitore dell’Account Esterno.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <b>Pagamento delle commissioni.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Autorizzi noi o il nostro processore di pagamento
                    designato ad addebitarti le Commissioni dovute in relazione
                    alle negoziazioni completate tramite i Servizi.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <b>Costi di riscossione.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Se non pagherai le Commissioni o altre somme dovute a
                    EDSX ai sensi di questi Termini e EDSX affiderà il/i tuo/i
                    account a terzi per il recupero crediti, EDSX addebiterà la
                    percentuale massima consentita dalla legge applicabile per
                    coprire i costi di riscossione di EDSX.
                  </span>
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.13. Accordo di custodia
                  <br />
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Il servizio di custodia sarà eseguito da EDSX quando sia un
                  Venditore che un Acquirente (collettivamente denominati) lo
                  richiederanno durante una transazione sulla bacheca. Le Parti
                  hanno nominato EDSX per detenere la corrispondente
                  criptovaluta, denaro contante o titoli relativi alla
                  transazione. Oppure le Parti hanno concordato di utilizzare
                  uno smart contract per eseguire la transazione (possibile solo
                  quando vengono scambiati titoli digitali contro criptovalute).
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Esistono due diversi casi operativi per le transazioni in
                  valuta FIAT.
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  Mercato secondario. Garanzia FIAT. Proposta d’acquisto.
                  L’Acquirente depositerà un importo in valuta FIAT su un conto
                  bancario indicato da EDSX. Il Venditore dovrà inviare i
                  security token all’indirizzo del portafoglio EDSX (o allo
                  smart contract o all’indirizzo del portafoglio dell’Acquirente
                  indicato) entro cinque giorni. Se il Venditore non rispetterà
                  la scadenza, potrà essere diffidato, sospeso o bandito da
                  EDSX.
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                Una volta confermato il trasferimento sulla blockchain, EDSX
                invierà la valuta FIAT al Venditore.
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
                start={2}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  Mercato secondario. Garanzia FIAT. Proposta di vendita. I
                  token saranno bloccati nel portafoglio del venditore durante
                  la proposta di vendita. L’Acquirente dovrà inviare la valuta
                  FIAT su un conto bancario indicato da EDSX entro cinque
                  giorni. Se l’Acquirente non rispetterà la scadenza, potrà
                  essere diffidato, sospeso o bandito da EDSX.
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Una volta ricevuto il denaro, EDSX lo invierà sul conto
                  bancario del Venditore.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Le transazioni in cui viene utilizzata una criptovaluta (asset
                  digitali contro criptovaluta) saranno regolate completamente
                  attraverso uno smart contract:
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>ate.</span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <i>
                    <span style={{ fontWeight: 400 }}>Mercato secondario.</span>
                  </i>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Smart contract. Proposta d’acquisto. L’Acquirente
                    invierà la criptovaluta allo smart contract. Una volta che
                    il Venditore invierà la quantità corrispondente di security
                    token, lo scambio sarà eseguito. Se il Venditore non
                    rispetterà la scadenza, potrà essere diffidato, sospeso o
                    bandito da EDSX.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <i>
                    <span style={{ fontWeight: 400 }}>Mercato secondario.</span>
                  </i>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Smart contract. Proposta di vendita. Lo smart contract
                    bloccherà i security token nel portafoglio del Venditore.
                    Una volta ricevuta la quantità corrispondente di
                    criptovaluta dall’Acquirente, lo scambio sarà eseguito. Se
                    l’Acquirente non rispetterà la scadenza, potrà essere
                    diffidato, sospeso o bandito da EDSX.
                  </span>
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  Nel caso sorgano disaccordi, le Parti concordano che l’Agente
                  di custodia non sarà ritenuto responsabile per eventuali
                  costi, danni o perdite derivanti dall’adempimento dei propri
                  compiti.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Se tale disaccordo dovesse insorgere tra Venditore e
                  Acquirente, l’Agente di custodia avrà il diritto di esimersi
                  da questo accordo trasmettendo tutti gli accordi e la
                  documentazione alla giurisdizione responsabile di questo
                  accordo che in questo caso è il Tribunale di Zugo in Svizzera.
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  Eventuali e tutti i costi sostenuti dall’Agente di custodia a
                  seguito di tali procedimento saranno a carico del Venditore e
                  dell’Acquirente.
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.13.1. <b>Custodia</b>
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  Tutti i fondi ricevuti in relazione al presente accordo di
                  custodia saranno depositati su un conto bancario selezionato
                  dall’Agente di custodia.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Eventuali commissioni dovute all’Agente di custodia al momento
                  della richiesta di erogazione possono essere detratte
                  dall’importo dell’erogazione della custodia prima
                  dell’erogazione.
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  L’Agente di custodia considererà valide tutte le notifiche e
                  le istruzioni ricevute senza l’obbligo di indagare o mettere
                  in discussione l’ emittente.
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.13.2. <b>Responsabilità</b>
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Nelle seguenti circostanze l’Agente di custodia non sarà
                  ritenuto responsabile o colpevole:
                </span>
              </p>
              <ul
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Per qualsiasi omissione o errore commesso da una parte
                    diversa dall’Agente di custodia.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Eventuali perdite di fondi direttamente correlate alla
                    sospensione, insolvenza o fallimento dei fondi o della banca
                    stessa.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Qualsiasi procedimento legale tra Acquirente e Venditore
                  </span>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      Non saranno maturati interessi durante il periodo di
                      validità del presente Accordo di custodia.
                    </span>
                  </p>
                </li>
              </ul>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.14. <b>Notifiche elettroniche</b>
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <b>Consenso alla consegna elettronica.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Accetti e acconsenti a ricevere elettronicamente tutte
                    le comunicazioni, accordi, documenti, ricevute, notifiche e
                    informative (collettivamente denominate “Comunicazioni”) che
                    EDSX fornisce in relazione al tuo Account EDSX e/o all’uso
                    dei Servizi EDSX. Acconsenti affinché EDSX possa fornirti
                    tali Comunicazioni pubblicandole tramite i Servizi,
                    inviandole via email all’indirizzo email che fornisci e/o
                    inviando un SMS o messaggio di testo al numero di cellulare
                    che fornisci. Le normali tariffe di messaggistica, dati e
                    altre tariffe del tuo gestore potrebbero applicarsi a
                    eventuali Comunicazioni mobili. Tuttavia, le tue interazioni
                    con noi e con altri membri di EDSX avverranno quasi
                    interamente tramite la piattaforma. Se non ti senti a tuo
                    agio nell’utilizzare la piattaforma per queste interazioni,
                    non dovresti sottoscrivere il presente Accordo e diventare
                    membro di EDSX. Dovresti conservare copia delle
                    Comunicazioni elettroniche stampandone una copia cartacea o
                    salvandone una copia elettronica. Puoi anche contattarci
                    tramite supporto ad&nbsp;info@edsx.ch&nbsp;per richiedere
                    copie elettroniche aggiuntive delle Comunicazioni o, dietro
                    pagamento di un corrispettivo, copie cartacee delle
                    Comunicazioni (come descritto di seguito).
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <b>Requisiti hardware e software.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Per accedere e conservare le Comunicazioni
                    elettroniche, avrai bisogno di un computer con connessione
                    Internet e un browser Web aggiornato con abilitati i cookie.
                    Sarà inoltre necessario avere un indirizzo email valido
                    registrato presso EDSX e spazio di archiviazione sufficiente
                    per salvare le Comunicazioni passate o una stampante
                    installata per stamparle.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <b>Revoca del consenso.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Puoi revocare il tuo consenso a ricevere Comunicazioni
                    elettroniche inviando una nota di revoca al
                    supporto&nbsp;info@edsx.ch. Se declini o revochi il consenso
                    a ricevere Comunicazioni elettroniche, EDSX potrebbe
                    sospendere o cessare il tuo utilizzo dei Servizi.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <b>Richiesta di copie cartacee.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;Se, dopo aver acconsentito a ricevere le Comunicazioni
                    in forma elettronica, desideri una copia cartacea di una
                    Comunicazione precedentemente inviatta, puoi richiederla
                    entro 30 giorni dalla data in cui te l’abbiamo fornita
                    contattando support&nbsp;info@edsx.ch. Affinché possiamo
                    inviarti le copie cartacee, è necessario che tu abbia un
                    indirizzo di residenza aggiornato registrato presso EDSX. Ti
                    ricordiamo che EDSX opera esclusivamente online e la
                    produzione di copie cartacee delle Comunicazioni risulta
                    essere molto onerosa. Pertanto, se richiedi copie cartacee,
                    comprendi e accetti che EDSX possa addebitarti un costo di
                    gestione, come descritto nella Tabella Commissioni, per ogni
                    pagina richiesta.
                  </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                  <b>Aggiornamento dei dati di contatto.</b>
                  <span style={{ fontWeight: 400 }}>
                    &nbsp;È tua responsabilità mantenere aggiornati presso EDSX
                    il tuo indirizzo email e/o numero di cellulare in modo che
                    EDSX possa comunicare con te per via elettronica. Comprendi
                    e acconsenti che se EDSX ti inviasse una Comunicazione
                    elettronica ma tu non la ricevessi poiché l’indirizzo email
                    o il numero di cellulare registrati sono errati, obsoleti,
                    bloccati dal tuo provider o comunque non ti è possibile
                    ricevere Comunicazioni elettroniche, EDSX considererà la
                    Comunicazione come avvenuta. Ti ricordiamo che se utilizzi
                    un filtro antispam che blocca o dirotta le email di mittenti
                    non presenti in rubrica, devi aggiungere EDSX alla tua
                    rubrica in modo da poter ricevere le Comunicazioni che ti
                    inviamo. Puoi aggiornare il tuo indirizzo email, numero di
                    cellulare o indirizzo di residenza in qualsiasi momento
                    accedendo al tuo Account EDSX. Se il tuo indirizzo email o
                    numero di cellulare diventassero non validi tanto che le
                    Comunicazioni elettroniche inviate da EDSX venissero
                    rimandate al mittente, EDSX potrebbe considerare il tuo
                    account come inattivo e non potresti completare alcuna
                    transazione tramite i nostri Servizi finché non ci fornirai
                    un indirizzo email o numero di cellulare valido e
                    funzionante.
                  </span>
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.15. <b>Proprietà non reclamate</b>
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  Qualora per qualsiasi motivo EDSX detenesse Fondi sul tuo
                  Account EDSX per tuo conto e non fosse in grado di restituire
                  i tuoi Fondi sul tuo Account Esterno designato dopo un periodo
                  di inattività, allora EDSX potrebbe segnalare e versare tali
                  Fondi in conformità alla normativa svizzera sulle proprietà
                  non reclamate.
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.16.{" "}
                </span>
                <b>Utilizzo accettabile</b>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  Nell’accedere o utilizzare i Servizi, acconsenti a non violare
                  alcuna legge, contratto, diritto di proprietà intellettuale o
                  altri diritti di terzi, né a commettere reati, e ad essere
                  l’unico responsabile della tua condotta durante l’utilizzo dei
                  nostri Servizi. Senza limitare la generalità di quanto sopra,
                  acconsenti a non:
                </span>
              </p>
              <ul
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Utilizzare i nostri Servizi in modo da poter interferire,
                    disturbare, influenzare negativamente o inibire ad altri
                    utenti dal godere pienamente dei nostri Servizi, o che
                    possano danneggiare, disabilitare, sovraccaricare o
                    pregiudicare il funzionamento dei nostri Servizi;
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Utilizzare i nostri Servizi per pagare, supportare o
                    comunque partecipare ad attività illegali; frodi;
                    riciclaggio di denaro; attività terroristiche; o altre
                    attività illegali;
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Utilizzare bot, crawler, estrattori dati o altri mezzi o
                    interfacce automatici non forniti da noi per accedere ai
                    nostri Servizi o estrarne dati;
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Tentare di utilizzare l’account di un altro utente senza
                    autorizzazione;
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Cercare di aggirare eventuali tecniche di filtraggio dei
                    contenuti che adottiamo, o tentare di accedere a servizi o
                    aree dei nostri Servizi a cui non sei autorizzato;
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Sviluppare applicazioni di terze parti che interagiscano con
                    i nostri Servizi senza il nostro previo consenso scritto;
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Fornire informazioni false, inesatte o fuorvianti;
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "none",
                    position: "relative",
                  }}
                >
                  <span style={{ fontWeight: 400 }}>
                    Incoraggiare o indurre terzi a partecipare ad attività
                    vietate ai sensi della presente Sezione.
                  </span>
                </li>
              </ul>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.17. <b>Feedback</b>
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  Avremo diritti esclusivi, anche su tutti i diritti di
                  proprietà intellettuale, su eventuali osservazioni,
                  suggerimenti, idee o altre informazioni o materiali
                  riguardanti EDSX o i nostri Servizi che ci fornisci, tramite
                  email, pubblicazione tramite i nostri Servizi o altro
                  (“Feedback”). Eventuali Feedback che ci comunichi saranno non
                  riservati e diventeranno di esclusiva proprietà di EDSX.
                  Saremo pienamente autorizzati all’utilizzo e diffusione
                  illimitati di tale Feedback per qualsiasi scopo, commerciale o
                  meno, senza menzione o corrispettivo per te. Rinunci a
                  qualsiasi diritto sul Feedback (inclusi eventuali diritti
                  d’autore o morali). Non inviarci Feedback se ti aspetti di
                  essere pagato o desideri mantenere o rivendicare diritti,
                  poiché la tua idea potrebbe essere già stata suggerita e non
                  vogliamo incorrere in controversie. Abbiamo anche il diritto
                  di rivelare la tua identità a terzi che sostengono che un tuo
                  contenuto costituisca violazione dei loro diritti di proprietà
                  intellettuale o della loro privacy. Abbiamo il diritto di
                  rimuovere eventuali tuoi post sul nostro sito web se, a nostro
                  insindacabile giudizio, il tuo post non rispetta gli standard
                  di contenuto indicati in questa sezione.
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.18.{" "}
                </span>
                <b>
                  Diritti d’autore e altri diritti di proprietà intellettuale
                </b>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Salvo indicazione contraria da parte nostra, tutti i diritti
                  d’autore e altri diritti di proprietà intellettuale su tutti i
                  contenuti e altri materiali contenuti sul nostro sito web o
                  forniti in connessione con i Servizi, inclusi, senza
                  limitazione, il logo EDSX ed EDSX e tutti i disegni, testi,
                  grafici, immagini, informazioni, dati, software, file audio,
                  altri file e la selezione e disposizione degli stessi
                  (collettivamente “Materiali EDSX”) sono di proprietà esclusiva
                  di EDSX o dei nostri licenziatari o clienti o fornitori e sono
                  protetti dalle leggi svizzere e internazionali sul diritto
                  d’autore e altre leggi sui diritti di proprietà intellettuale.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  Concediamo a titolo non esclusivo e non sub-licenziabile una
                  limitata licenza per accedere e utilizzare i Materiali EDSX
                  per il tuo uso personale o interno aziendale. Tale licenza è
                  soggetta a questi Termini e non consente (a) alcuna rivendita
                  dei Materiali EDSX; (b) la distribuzione, rappresentazione
                  pubblica o visualizzazione pubblica di alcun Materiale EDSX;
                  (c) la modifica o altri usi derivati dei Materiali EDSX, o di
                  alcuna loro parte; o (d) qualsiasi utilizzo dei Materiali EDSX
                  diverso dalle finalità previste. La licenza concessa ai sensi
                  di questa Sezione terminerà automaticamente se sospenderemo o
                  interromperemo il tuo accesso ai Servizi.
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.19. Marchi commerciali
                  <br />
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  “EDSX”, il logo EDSX, ed eventuali altri nomi, loghi, slogan
                  di prodotti o servizi EDSX che possano comparire sui nostri
                  Servizi sono marchi commerciali di EDSX in Svizzera e in
                  Europa e in altri paesi e non possono essere copiati, imitati
                  o utilizzati, in tutto o in parte, senza il nostro previo
                  consenso scritto. Non puoi utilizzare alcun marchio, nome
                  prodotto o servizio di EDSX senza il nostro previo consenso
                  scritto, incluso senza limitazioni eventuali metatag o altro
                  “testo nascosto” utilizzando marchi, nomi prodotto o servizio
                  di EDSX. Inoltre, l’aspetto e la presentazione dei nostri
                  Servizi, inclusi tutti le intestazioni pagina, grafiche
                  personalizzate, icone pulsanti e script, sono marchio, marchio
                  di servizio e/o immagine commerciale di EDSX e non possono
                  essere copiati, imitati o utilizzati, in tutto o in parte,
                  senza il nostro previo consenso scritto. Tutti gli altri
                  marchi, marchi registrati, nomi prodotto e nomi societari o
                  loghi menzionati tramite i nostri Servizi sono di proprietà
                  dei rispettivi titolari. Il riferimento a prodotti, servizi,
                  processi o altre informazioni, nominativi, marchi, produttori,
                  fornitori o altro non costituisce né implica promozione,
                  sponsorizzazione o raccomandazione da parte nostra.
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.20.{" "}
                </span>
                <b>Contenuti di terze parti</b>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                Nell’utilizzare i nostri Servizi, potresti visualizzare
                contenuti forniti da terze parti, inclusi collegamenti a pagine
                web di tali parti, inclusi ma non limitati a collegamenti
                Facebook e Twitter (“Contenuti di terze parti”). Non
                controlliamo, promuoviamo o adottiamo alcun Contenuto di terze
                parti e non avremo alcuna responsabilità per Contenuti di terze
                parti, incluso senza limitazioni materiale che potrebbe
                risultare fuorviante, incompleto, errato, offensivo, indecente o
                comunque oggettivamente discutibile. Inoltre, i tuoi rapporti
                commerciali o la corrispondenza con tali terzi sono
                esclusivamente tra te e le terze parti. Non siamo responsabili
                né responsabili di eventuali perdite o danni di qualsiasi tipo
                subiti a seguito di tali rapporti e comprendi che il tuo
                utilizzo di Contenuti di terze parti e le tue interazioni con
                terzi sono a tuo rischio e pericolo.
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.21. <b>Reclami</b>
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                  value={1}
                >
                  <span style={{ fontWeight: 400 }}>
                    Reclamo iniziale. Se avessi un reclamo rispetto a qualsiasi
                    aspetto della piattaforma, dovresti segnalarcelo
                    immediatamente inviando una email con la parola “reclamo”
                    nell’oggetto dall’indirizzo email con cui hai registrato la
                    tua membership EDSX a&nbsp;info@edsx.ch. Invieremo una prima
                    risposta alla tua email entro e non oltre cinque giorni
                    lavorativi dal ricevimento e questa risposta indicherà se
                    riteniamo il reclamo risolto oppure se stiamo investigando
                    ulteriormente la questione. Qualora stessimo investigando
                    ulteriormente, forniremo una risposta finale entro non oltre
                    otto settimane dal ricevimento della tua email. Potremmo
                    dover effettuare delle domande per comprendere i dettagli
                    del tuo reclamo e qualsiasi domanda e risposta verrà inviata
                    via email all’indirizzo associato alla tua membership EDSX.
                  </span>
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.22. <b>Sospensione; Recesso</b>
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Recesso da parte tua.</b>
                  <span style={{ fontWeight: 400 }}>
                    Se non desideri più essere membro EDSX, puoi recedere dalla
                    membership in qualsiasi momento notificandoci la decisione
                    via email a&nbsp;info@edsx.ch.
                  </span>
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <b>Recesso da parte nostra.</b>
                  <span style={{ fontWeight: 400 }}>
                    Potremmo recedere immediatamente dalla tua membership EDSX,
                    informandoti contestualmente del recesso, se:
                  </span>
                  <br />
                  <ol
                    style={{
                      marginTop: 10,
                      paddingLeft: 20,
                      color: "#4f4f6f",
                      fontFamily: '"Gotham Pro Light"',
                      lineHeight: "1.6",
                    }}
                  >
                    <li
                      style={{
                        marginBottom: 10,
                        paddingLeft: 25,
                        listStyle: "decimal",
                      }}
                    >
                      Hai violato in modo serio o persistente i termini del
                      presente Accordo senza porvi rimedio entro un ragionevole
                      periodo di tempo dopo la nostra richiesta;
                    </li>
                    <li
                      style={{
                        marginBottom: 10,
                        paddingLeft: 25,
                        listStyle: "decimal",
                      }}
                    >
                      Hai violato o tentato di violare la legge, o ci hai messo
                      nella condizione di poterlo fare;
                    </li>
                    <li
                      style={{
                        marginBottom: 10,
                        paddingLeft: 25,
                        listStyle: "decimal",
                      }}
                    >
                      Stai utilizzando la Piattaforma in modo dannoso per EDSX,
                      ad es. arrecando danno alla Piattaforma o alla nostra
                      reputazione;
                    </li>
                    <li
                      style={{
                        marginBottom: 10,
                        paddingLeft: 25,
                        listStyle: "decimal",
                      }}
                    >
                      Ci hai fornito false informazioni;
                    </li>
                    <li
                      style={{
                        marginBottom: 10,
                        paddingLeft: 25,
                        listStyle: "decimal",
                      }}
                    >
                      Sei stato offensivo con chiunque di EDSX o con altri
                      membri EDSX;
                    </li>
                    <li
                      style={{
                        marginBottom: 10,
                        paddingLeft: 25,
                        listStyle: "decimal",
                      }}
                    >
                      Se ne ricorrono i presupposti di legge, regolamento,
                      autorità o organismo governativo o regolatorio.
                    </li>
                    <li
                      style={{
                        marginBottom: 10,
                        paddingLeft: 25,
                        listStyle: "decimal",
                      }}
                    >
                      <span style={{ fontWeight: 400 }}>
                        Ci possono essere altre circostanze che includono
                        l’utilizzo della piattaforma in qualsiasi modo (1) che
                        causi o sia probabile causi interruzioni o danni al Sito
                        Web o ad esso, (2) a scopi fraudolenti o in relazione a
                        reati, (3) per inviare, usare o riutilizzare materiale
                        illegale, offensivo, abusivo, indecente, diffamatorio,
                        osceno o minaccioso o che comunque leda terzi o contenga
                        virus informatici, propaganda politica, sollecitazione
                        commerciale, catene di Sant’Antonio, mailing di massa o
                        spam o (4) causare disturbo, disagio o ansia
                        ingiustificata o (5) che possa dar luogo a
                        responsabilità civili o penali per noi.
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                Inoltre, in caso di Eventi di Forza Maggiore, violazione del
                presente accordo o di altri eventi che rendano antieconomica per
                EDSX la fornitura dei Servizi, potremmo discrezionalmente e
                senza responsabilità nei tuoi confronti, con o senza preavviso,
                sospendere il tuo accesso a tutta o parte dei nostri Servizi.
                Potremmo recedere dal tuo accesso ai Servizi a nostra
                discrezione, immediatamente e senza preavviso, cancellando o
                disattivando il tuo Account EDSX e tutte le relative
                informazioni e file in esso contenuti senza alcuna
                responsabilità nei tuoi confronti, ad esempio in caso di
                violazione da parte tua di qualsiasi termine dei presenti
                Termini. In caso di recesso, EDSX cercherà di restituire
                eventuali Fondi custoditi nel tuo Account EDSX non dovuti ad
                EDSX, salvo che EDSX ritenga che tu abbia commesso frode,
                negligenza o altra condotta scorretta.
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                Se la tua membership EDSX dovesse essere revocata o sospesa, non
                potrai più utilizzare la piattaforma.
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.23.{" "}
                </span>
                <b>Sospensione dei Servizi</b>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                Potremmo discrezionalmente e senza alcuna responsabilità nei
                tuoi confronti, con o senza preavviso in qualsiasi momento,
                modificare o interrompere temporaneamente o permanentemente
                qualsiasi parte dei nostri Servizi.
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.24. Esclusione di garanzie
                  <br />
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                SALVO QUANTO ESPRESSAMENTE PREVISTO IN SENSO CONTRARIO PER
                ISCRITTO DA PARTE NOSTRA, I NOSTRI SERVIZI SONO FORNITI NELLA
                MODALITÀ “COSI’ COME SONO” E “COME DISPONIBILI”. ESCLUDIAMO
                ESPRESSAMENTE QUALSIASI GARANZIA, ESPECIFICA O IMPLICITA,
                INCLUSE A TITOLO MERAMENTE ESEMPLIFICATIVO GARANZIE IMPLICITE DI
                COMMERCIABILITÀ, IDONEITÀ AD UN PARTICOLARE SCOPO, TITOLO DI
                PROPRIETÀ E NON VIOLAZIONE RELATIVE AI NOSTRI SERVIZI, ALLE
                INFORMAZIONI, AI CONTENUTI E AI MATERIALI IN ESSI CONTENUTI.
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                RICONOSCI CHE LE INFORMAZIONI MEMORIZZATE O TRASFERITE
                ATTRAVERSO I NOSTRI SERVIZI POTREBBERO DIVENIRE
                IRRIMEDIABILMENTE PERSE O CORROTTE O TEMPORANEAMENTE NON
                DISPONIBILI A CAUSA DI NUMEROSE CAUSE, COMPRESI GUASTI SOFTWARE,
                MODIFICHE AI PROTOCOLLI DA PARTE DI FORNITORI TERZI,
                INTERRUZIONI DI RETE, EVENTI DI FORZA MAGGIORE O ALTRE
                CATASTROFI, COMPRESI ATTACCHI DDOS DI TERZI, MAINTENANCE
                PROGRAMMATO O NON PROGRAMMATO, O ALTRE CAUSE IN O FUORI DAL
                NOSTRO CONTROLLO. SEI L’UNICO RESPONSABILE DEL BACKUP E DEL
                MANTENIMENTO DI COPIE DUPLICATE DI QUALSIASI INFORMAZIONE
                MEMORIZZATA O TRASFERITA ATTRAVERSO I NOSTRI SERVIZI.
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.25. Limitazione di responsabilità
                  <br />
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                (a) FATTA ECCEZIONE PER QUANTO DIVERSO DISPOSTO DALLA LEGGE, IN
                NESSUN CASO EDSX, I NOSTRI AMMINISTRATORI, SOCI, COLLABORATORI,
                DIPENDENTI O AGENTI POTRANNO ESSERE RESPONSABILI PER DANNI
                SPECIALI, INDIRETTI O CONSEGUENTI, O ALTRI DANNI DI QUALUNQUE
                TIPO, COMPRESI A TITOLO MERAMENTE ESEMPLIFICATIVO PERDITA DI
                UTILIZZO, PERDITA DI PROFITTI O PERDITA DI DATI, SIA IN SEDE
                CONTRATTUALE CHE EXTRACONTRATTUALE (COMPRESA LA NEGLIGENZA),
                DERIVANTI O IN QUALSIASI MODO CONNESSI ALL’UTILIZZO O
                ALL’IMPOSSIBILITÀ DI UTILIZZO DEI NOSTRI SERVIZI O DEI MATERIALI
                EDSX, COMPRESI SENZA LIMITI I DANNI CAUSATI O DERIVANTI DAL
                FIDARSI DA PARTE DELL’UTENTE DI ALCUNA INFORMAZIONE OTTENUTA DA
                EDSX, O CHE DERIVINO DA ERRORI, OMISSIONI, INTERRUZIONI,
                ELIMINAZIONE DI FILE O EMAIL, DIFETTI, VIRUS, RITARDI NELLE
                OPERAZIONI O TRASMISSIONI O MANCATI ADEMPIMENTI, SIANO ESSI O
                MENO DERIVANTI DA EVENTI DI FORZA MAGGIORE, GUASTI DI
                COMUNICAZIONE, FURTI, DESTRUZIONI O ACCESSI NON AUTORIZZATI AI
                RECORDS, PROGRAMMI O SERVIZI DI EDSX.
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                NON SAREMO RESPONSABILI NEI VOSTRI CONFRONTI PER EVENTUALI
                PERDITE O DANNI RELATIVI A MATERIE PER LE QUALI LA
                RESPONSABILITÀ È ESPRESSAMENTE ESCLUSA AI SENSI DEL PRESENTE O
                DI ALTRI ACORDI DI PIATTAFORMA, OPPURE DERIVANTI O CONNESSI A
                ERRORI O INESATTEZZE NEI DATI INSERITI DA TE O DA ALTRI MEMBRI
                EDSX O A FALSI DICHIARAZIONI, COLPEVOLI OMISSIONI O ALTRI ATTI
                DI ALTRI MEMBRI EDSX. NON SAREMO RESPONSABILI NEI VOSTRI
                CONFRONTI PER DANNI INDIRETTI, CONSEQUENZIALI, SPECIALI O
                PUNITIVI, COSTI O SPESE, PERDITE O DANNI NON PREVEDIBILI E
                PREVENTIVABILI, PERDITA DI REDDITO, PERDITA DI AFFARI, PERDITA O
                SPRECO DI TEMPO DA PARTE VOSTRA O DEI VOSTRI DIPENDENTI, DANNO
                ALLA REPUTAZIONE, EROSIONE DEL BUON NOME O PERDITA, DANNO O
                CORRUZIONE DI DATI.
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                ACCONSENTI CHE EVENTUALI AZIONI LEGALI CONTRO DI NOI SONO
                POSSIBILI SOLTANTO SU BASE INDIVIDUALE E CHE NON INTRAPRENDERAI
                NÉ TI UNIRAI A AZIONI COLLETTIVE CONTRO DI NOI. IL PRESENTE
                ACCORDO NON LIMITA LA NOSTRA RESPONSABILITÀ PER LESIONI
                PERSONALI O MORTE, FRODE O ALTRE MATERIE PER LE QUALI
                L’ESCLUSIONE O LA LIMITAZIONE DI RESPONSABILITÀ NON SIA
                CONSENTITA DALLA LEGGE O DAI REGOLAMENTI APPLICABILI.
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                (b) NEL LIMITE MASSIMO CONSENTITO DALLA LEGGE APPLICABILE, LA
                RESPONSABILITÀ COMPLESSIVA DI EDSX (COMPRESI I NOSTRI
                AMMINISTRATORI, SOCI, COLLABORATORI, MEMBRI, DIPENDENTI E
                AGENTI), SIA CONTRATTUALE CHE EXTRACONTRATTUALE (COMPRESA LA
                NEGLIGENZA, ATTIVA, PASSIVA O IMPUTATA), PRODOTTA, O DERIVANTE
                DALL’UTILIZZO O DALL’IMPOSSIBILITÀ DI UTILIZZO DI EDSX O DEL
                PRESENTE ACCORDO, NON POTRÀ ECCEDERE QUANTO PAGATO DA TE AD EDSX
                COME COMMISSIONI NEI 12 MESI PRECEDENTI LA DATA DI EVENTUALE
                PRETESA CHE DESSE LUOGO A TALE RESPONSABILITÀ.
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.26. Indennizzo
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                Accetti di difendere, indennizzare e tenere indenne EDSX (e
                ciascuno dei nostri amministratori, direttori, soci,
                collaboratori, membri, dipendenti, agents e affiliati) da
                qualsiasi pretesa, richiesta, azione, danno, perdita, costo o
                spesa, inclusi a titolo esemplificativo spese legali
                ragionevoli, derivanti o relativi a (a) il tuo utilizzo o la tua
                condotta in relazione ai nostri Servizi; (b) eventuali feedback
                forniti; (c) la tua violazione dei presenti Termini; o (d) la
                tua violazione di diritti di terzi. Qualora fossi tenuto ad
                indennizzare, avremo il diritto, a nostra esclusiva discrezione,
                di gestire eventuali procedimenti (a nostre spese) e decidere se
                transigere.
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.27. Legge applicabile; Arbitrato
                  <br />
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                LEGGI ATTENTAMENTE IL PARAGRAFO SEGUENTE PERCHÉ IMPONE
                L’ARBITRATO PER CONTROVERSIE CON NOI E LIMITA IL MODO IN CUI
                PUOI RICHIEDERE UN RIMEDIO.
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                Tu ed EDSX convenite di ricorrere ad arbitrati per qualsiasi
                controversia derivante dai presenti Termini o dall’uso dei
                Servizi, fatta eccezione per controversie in cui una delle parti
                richieda tutela legale o rimedi in caso di uso illecito di
                diritti d’autore, marchi, denominazioni sociali, loghi, segreti
                commerciali o brevetti. L’ARBITRATO IMPEDISCE DI CITARE IN
                GIUDIZIO O CHIEDERE UN PROCESSO GIUDIZIARIO. Tu ed EDSX vi
                impegnate a darvi notizia scritta di eventuali controversie
                entro trenta (30) giorni dal sorgere. La notifica ad EDSX deve
                essere inviata a info@edsx.ch. Inoltre, tu ed EDSX convenite
                che: (a) cercherete una risoluzione extragiudiziale prima di
                qualsiasi richiesta di arbitrato; (b) l’arbitrato avverrà a
                Zugo, Svizzera; (c) l’arbitrato sarà condotto riservatamente da
                un solo arbitro in conformità alle leggi svizzere; e (d) il
                Tribunale di Zugo ha giurisdizione esclusiva su eventuali
                impugnazioni della sentenza arbitrale e su qualsiasi causa fra
                le parti non soggetta ad arbitrato. Fatta eccezione per le
                procedure e rimedi collettivi di cui sotto, l’arbitro ha facoltà
                di disporre qualsiasi possibile soluzione disponibile in
                tribunale. Qualsiasi controversia fra le parti sarà regolata dai
                presenti Termini e dalle leggi del Canton Zugo e dal diritto
                svizzero applicabile, senza dare effetto a principi di diritto
                internazionale che possano prevedere l’applicazione della legge
                di un’altra giurisdizione. Sia nel caso la controversia sia
                decisa tramite arbitrato o in tribunale, tu ed EDSX non
                intenterete l’una contro l’altra un’azione collettiva, arbitrato
                collettivo o un procedimento o azione rappresentativa.
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.28. Varie
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                  value={1}
                >
                  Integrazione contrattuale; Gerarchia delle fonti. Il presente
                  contratto contiene l’intero accordo fra le parti e sostituisce
                  ogni precedente intesa o comprensione relativa ai Servizi. Il
                  presente contratto non altera i termini o condizioni di
                  eventuali altri accordi scritti o elettronici che tu possa
                  aver stipulato con EDSX per i Servizi o per altri prodotti o
                  servizi EDSX o per altre ragioni. In caso di conflitto fra il
                  presente contratto e altri accordi stipulati con EDSX,
                  prevarranno i termini di detti altri accordi solo se in essi
                  il presente contratto sarà specificamente identificato e
                  dichiarato derogato.
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  Modifiche. Ci riserviamo il diritto di apportare modifiche o
                  variazioni al presente contratto di volta in volta, a nostra
                  esclusiva discrezione. In caso di modifiche, provvederemo a
                  darne comunicazione, per esempio inviando una email,
                  pubblicando un avviso nella homepage del Sito e/o mediante
                  pubblicazione del testo modificato sulle pagine web ed
                  applicazioni mobili EDSX aggiornando la data “Ultimo
                  Aggiornamento” in alto. Le modifiche si intenderanno efficaci
                  immediatamente per i nuovi utenti dei Servizi. In tutti gli
                  altri casi, le modifiche decorreranno per gli utenti
                  preesistenti dalla prima tra: (i) la data di accettazione da
                  parte degli utenti, o (ii) il decorso di 10 giorni dalla
                  nostra comunicazione senza opposizione. Le eventuali modifiche
                  si applicheranno prospetticamente all’uso dei Servizi
                  successivo alla loro applicazione. Se non accetti le
                  modifiche, dovrai interrompere l’uso dei Servizi e contattarci
                  per chiudere il tuo account.
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  Decadenza. Il nostro mancato o ritardato esercizio di un
                  diritto, potere o prerogativa previsti nel presente contratto
                  non implica la rinuncia agli stessi.
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  Parziale invalidità. L’invalidità o inapplicabilità di una
                  qualsiasi clausola del presente contratto non inficerà la
                  validità o applicabilità delle restanti, che resteranno
                  integralmente efficaci.
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  Ulteriori atti. Ci obblighiamo reciprocamente a compiere tutti
                  gli atti e sottoscrivere tutta la documentazione
                  ragionevolmente necessaria per dare attuazione al presente
                  accordo.
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  Spese. Le spese e costi (inclusi quelli professionali, legali
                  e contabili) relativi alla negoziazione, stipula e attuazione
                  del presente contratto e della relativa documentazione
                  resteranno a nostro carico.
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  Eventi di forza maggiore. EDSX non sarà responsabile per (1)
                  inesattezze, errori, ritardi o omissioni relative a (i)
                  qualsiasi informazione, o (ii) la trasmissione o consegna
                  delle informazioni; (2) perdite o danni derivanti da eventi al
                  di fuori del ragionevole controllo di EDSX, inclusi ad esempio
                  alluvioni, maltempo eccezionale, terremoto, incendi, guerre,
                  sommosse, scioperi, incidenti, azioni delle autorità, guasti
                  di comunicazioni o apparecchiature/software (ciascuno, un
                  “Evento di forza maggiore”).
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  Diritti dei terzi. Salvo diversa previsione nel presente
                  contratto, terzi non contraenti non possono far valere i loro
                  termini e, indipendentemente da qualsiasi clausola, modifiche
                  (inclusi rinunce o transazioni) o recessi contrattuali non
                  richiedono il consenso di terzi.
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  Sottoscrizione. Il presente contratto si intenderà validamente
                  stipulato e vincolante fra noi una volta che tu ne abbia
                  confermato l’accettazione con le modalità previste sulla
                  piattaforma.
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  Cessione. Non potrai cedere o trasferire diritti od obblighi
                  derivanti dal presente contratto senza prima nostro consenso
                  scritto, incluse cessioni per legge o in collegamento a
                  mutamenti di controllo. Potremo invece cedere o trasferire
                  tutti o parte dei nostri diritti derivanti dal presente
                  contratto, anche parzialmente, senza il tuo consenso.
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  Titoli. I titoli delle sezioni hanno mero scopo di chiarezza e
                  non possono essere utilizzati per interpretare o limitare il
                  contenuto delle stesse.
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  Sopravvivenza contrattuale. Le seguenti clausole
                  sopravvivranno a qualsiasi recesso o scadenza del presente
                  contratto: Requisiti di ammissibilità, Account EDSX,
                  Informativa sui rischi, Commissioni, Beni non reclamati,
                  Feedback, Diritti d’autore, Marchi, Contenuti di terzi,
                  Esclusione di garanzie, Limitazione di responsabilità;
                  Indennizzo, Legge applicabile; Arbitrato, Ogni esclusione di
                  responsabilità, accordo sulla responsabilità per perdite e/o
                  spese, esclusioni e la presente Sezione Varie.
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.29 <b>Comunicazioni</b>
                </span>
              </p>
              <ol
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <strong>Comunicazioni da te a noi.</strong> Eventuali
                  comunicazioni da parte tua a noi in relazione al presente
                  contratto, alla tua membership di EDSX o alle tue attività
                  sulla piattaforma dovranno avvenire via email all’indirizzo
                  info@edsx.ch.
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <strong>Comunicazioni da noi a te.</strong> Eventuali
                  comunicazioni da parte nostra a te in relazione al presente
                  contratto, alla tua membership di EDSX o alle tue attività
                  sulla piattaforma potranno avvenire tramite la piattaforma,
                  via email all’indirizzo indicato nel tuo profilo oppure a
                  mezzo posta o corriere all’indirizzo fisico indicato nel tuo
                  profilo.
                </li>
                <li
                  style={{
                    marginBottom: 10,
                    paddingLeft: 25,
                    listStyle: "decimal",
                  }}
                >
                  <strong>Ricevimento delle comunicazioni.</strong> Le
                  comunicazioni effettuate ai sensi del presente paragrafo
                  tramite piattaforma o email si intenderanno ricevute dal
                  destinatario al momento dell’invio. Le comunicazioni
                  effettuate ai sensi del presente paragrafo a mezzo posta o
                  corriere si intenderanno ricevute dal destinatario due giorni
                  lavorativi dopo l’invio. Qualora tu ci comunicassi con
                  modalità diverse da quelle previste nel presente paragrafo e
                  noi di fatto ricevessimo la comunicazione, potremo scegliere
                  discrezionalmente di ritenere la comunicazione ricevuta al
                  momento dell’effettivo ricevimento.
                </li>
              </ol>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.30. <b>Informazioni su di noi</b>
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                  EDSX GmbH Baarerstrasse 82, 6302 Zug, c/o AbaFin Treuhand AG,
                  Svizzera.
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <span
                  style={{
                    margin: 0,
                    padding: 0,
                    fontWeight: "bolder",
                    fontFamily: '"Gotham Pro Bold"',
                  }}
                >
                  2.31.{" "}
                </span>
                <b>Definizioni e interpretazione</b>
              </p>
              <p>
                <b>“Informazioni aggiuntive”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;indica le informazioni che forniamo sulla piattaforma al
                  di fuori di quelle contenute in una campagna;
                </span>
              </p>
              <p>
                <b>“Agente”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;indica, nel caso di un membro societario, di un membro
                  non costituito in società o di un membro governativo, la
                  persona fisica che agisce per conto di tale membro;
                </span>
              </p>
              <p>
                <b>“Accordo”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;indica il presente Accordo di Membership tra te e noi;
                </span>
              </p>
              <p>
                <b>“Società”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;indica una società che sta cercando capitali;
                </span>
              </p>
              <p>
                <b>“Campagna”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;indica il riassunto della campagna e la campagna
                  dettagliata di una società, considerati congiuntamente;
                </span>
              </p>
              <p>
                <b>“Riassunto della campagna”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;indica le informazioni sintetiche relative a una società
                  che sta cercando capitali;
                </span>
              </p>
              <p>
                <b>“Campagna dettagliata”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;indica le informazioni dettagliate relative a una
                  società che sta cercando capitali;
                </span>
              </p>
              <p>
                <b>“Membro societario”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;indica una persona giuridica che diventa membro di EDSX;
                </span>
              </p>
              <p>
                <strong>“Membro non costituito in società”</strong> indica una
                società di persone o un’associazione non costituita in società
                che diventa membro di EDSX;
              </p>
              <p>
                <strong>“Membro governativo”</strong> indica un ente governativo
                che diventa membro di EDSX;
              </p>
              <p>
                <b>“Membro individuale”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;indica una persona fisica che diventa membro di EDSX;
                </span>
              </p>
              <p>
                <b>“Soggetto destinatario dell’investimento”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;indica una o più entità o strutture giuridiche che
                  gestiscono la società nella quale viene effettuato
                  l’investimento;
                </span>
              </p>
              <p>
                <b>“Lead entrepreneur”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;indica, rispetto a un’impresa per la quale è stata
                  creata una campagna o a un soggetto destinatario
                  dell’investimento successivamente all’investimento, la persona
                  che ha la responsabilità primaria per i rapporti tra
                  l’impresa, i suoi investitori e noi;
                </span>
              </p>
              <p>
                <b>“Denaro fiat”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;è una moneta cartacea priva di valore intrinseco emesso
                  e garantito da uno Stato come moneta a corso legale
                  obbligatorio;
                </span>
              </p>
              <p>
                <b>“Gas”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;è la commissione di esecuzione per ogni operazione
                  effettuata su Ethereum, pagata alla rete Ethereum;
                </span>
              </p>
              <p>
                <b>“Operazione in escrow”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;un’operazione sul mercato secondario con uno smart
                  contract o un servizio di escrow fornito da EDSX;
                </span>
              </p>
              <p>
                <b>“Operazione fuori escrow”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;un’operazione sul mercato secondario senza uno smart
                  contract o un servizio di escrow di EDSX e regolata solo dalle
                  parti coinvolte sulla blockchain;
                </span>
              </p>
              <p
                style={{
                  marginBottom: "1rem",
                  color: "#4f4f6f",
                  fontFamily: '"Gotham Pro Light"',
                  lineHeight: "1.6",
                }}
              >
                <b>“Membro estero”</b>
                <span style={{ fontWeight: 400 }}>
                  &nbsp;indica i membri di EDSX residenti o con sede in
                  territori al di fuori della Svizzera;
                </span>
              </p>
              <p>
                <strong>“Persona”</strong> indica una persona fisica o
                giuridica, compresa una società di persone, un’associazione non
                costituita in società o altra entità non costituita in società
                che, non avendo personalità giuridica distinta, agisce ai fini
                del presente Accordo come un corpo unitario;
              </p>
              <p>
                <strong>“Dati personali”</strong> ha il significato indicato
                nell’Informativa sulla Privacy;
              </p>
              <p>
                <strong>“Piattaforma”</strong> indica la piattaforma EDSX
                ospitata sul dominio http://www.edsx.ch e tutte le pagine sui
                suoi sottodomini e può, di volta in volta, comprendere pagine
                ospitate su altri domini identificati da noi come facenti parte
                della piattaforma;
              </p>
              <p>
                <strong>“Accordo di piattaforma”</strong> indica qualsiasi altro
                accordo che stipuli in relazione al tuo utilizzo della
                piattaforma;
              </p>
              <p>
                <strong>“Processo post-investimento”</strong> indica, per un
                investitore, le informazioni, i diritti, i pagamenti e i
                processi di trasferimento una volta terminata la fase di mercato
                primario;
              </p>
              <p>
                <strong>“Mercato primario”</strong> dove i titoli vengono
                venduti per la prima volta;
              </p>
              <p>
                <strong>“Informativa sulla privacy”</strong> indica
                l’informativa sulla privacy di EDSX, accessibile da ogni pagina
                del Sito e di cui sei informato fornendo i tuoi dati personali;
              </p>
              <p>
                <strong>“Profilo”</strong> indica il profilo che compili per
                aderire a EDSX;
              </p>
              <p>
                <strong>“Termini del servizio”</strong> indica i termini del
                servizio per il Sito, accessibili da ogni pagina del Sito e ai
                quali ti ritieni vincolato utilizzando il Sito;
              </p>
              <p>
                <strong>“Marchio”</strong> “EDSX” è un marchio svizzero
                registrato.
              </p>
              <p>
                <strong>“Mercato secondario”</strong> indica lo scambio di
                titoli già emessi in un mercato basato su una bacheca p2p di
                annunci di acquisto e vendita. Nel presente documento il mercato
                secondario viene utilizzato come sinonimo di bacheca;
              </p>
              <p>
                <strong>“Titoli”</strong> indica un certificato o altro
                strumento finanziario che ha valore monetario e può essere
                scambiato. In genere i titoli sono classificati come titoli di
                capitale, come le azioni, o titoli di debito, come obbligazioni
                e debiti;
              </p>
              <p>
                <strong>“Azioni”</strong> indica azioni o altre partecipazioni
                al capitale o assimilabili al capitale di un’impresa;
              </p>
              <p>
                <strong>“Accordo di sottoscrizione”</strong> indica un accordo
                di sottoscrizione e/o azionario e/o altri accordi stipulati dal
                soggetto destinatario dell’investimento in relazione a un
                investimento;
              </p>
              <p>
                <strong>“Noi, nostro”</strong> indica EDSX, eventuali sue
                controllate e qualsiasi holding company e, ove il contesto lo
                consenta, i suoi concessionari, beneficiari e delegati
                autorizzati;
              </p>
              <p>
                <strong>“Sito”</strong> indica, per EDSX, il sito ospitato su
                http://www.edsx.ch, compresi tutti i sottodomini;
              </p>
              <p>
                <strong>“Giorno lavorativo”</strong> indica qualsiasi giorno
                diverso da sabato, domenica o festivo svizzero o
                internazionale/nazionale;
              </p>
              <p>
                <strong>“Tu, il tuo”</strong> indica te, in quanto membro di
                EDSX, che sei parte contrattuale del presente Accordo.
                <br />
                Fatto salvo quanto sopra, i termini usati nel presente Accordo,
                laddove il contesto lo consenta, hanno lo stesso significato
                previsto dalle Norme FINMA.
              </p>
              <p>
                Salvo che il contesto richieda diversamente, le parole al
                singolare includono il plurale e viceversa, e l’uso del maschile
                include femminile e neutro e viceversa.
                <br />
                La parola “inclusi” o “include” non è esclusiva, pertanto deve
                essere interpretata come seguita dalle parole “senza
                limitazione”.
                <br />I riferimenti a un paragrafo specifico indicano un
                paragrafo del presente Accordo salvo diversa precisazione.
                <br />
                Qualsiasi uso dei termini “devi”, “dovrai”, “puoi solo”, “non
                puoi” o termini simili implica che, stipulando il presente
                Accordo, tu accetti expressis verbis di essere vincolato
                all’azione o impegno cui tali termini si riferiscono.
              </p>{" "}
            </div>
          </Container>
        )}
      </div>
    </>
  );
};

export default TermsAndConditions;
