import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Layout from "./components/Layout";
// Pages
import Login from "./Forms/Login";
import Signup from "./Forms/Signup";
import ForgotPassword from "./Forms/ForgotPassword";
import VerifyAccount from "./Forms/VerifyAccount";
import Welcome from "./Pages/Welcome";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import RiskDisclaimer from "./Pages/RiskDisclaimer";
import CookiePolicy from "./Pages/CookiePolicy";
import Profile from "./Pages/Profile";
import Projects from "./Pages/InvestmentOpportunities";
import NoMatch from "./Pages/NoMatch";
import ProjectDetails from "./Pages/ProjectDetails";
import Thankyou from "./Pages/ThankYou";
import PaymentSuccess from "./Pages/PaymentSuccess";
import RequireAuth from "./app/features/auth/RequireAuth";
import Policies from "./Forms/Policies";
import ResetPassword from "./Forms/ResetPassword";
import PaymentStatus from "./Pages/PaymentStatus";
import Logout from "./Pages/Logout";
import Exchange from "./Pages/secondary-market/Exchange";
import IssuerDashboard from "./Pages/issuer/IssuerDashboard";
import TradeProject from "./Pages/secondary-market/TradeProject";
import TradePayment from "./Pages/secondary-market/TradePayment";
import ProfileChange from "./Forms/ProfileChange";
import TransferNft from "./Forms/TransferNft";
import NftTransferSuccess from "./Pages/NftTransferSuccess";

const AllRoutes = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const lang = pathSegments[1];

    if (lang && ['en', 'it'].includes(lang)) {
      i18n.changeLanguage(lang);
    } else {
      i18n.changeLanguage('en');
      if (location.pathname === '/') {
        navigate('/login', { replace: true });
      } else {
        navigate('/en' + location.pathname, { replace: true });
      }
    }
  }, [i18n, location.pathname, navigate]);

  return (
    <Routes>
      <Route path="/:lang/*" element={<Layout />}>
        {/* Public Routes */}
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="auth/reset-password/:token" element={<ResetPassword />} />
        <Route path="auth/verify/:token" element={<VerifyAccount />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="risk-disclaimer" element={<RiskDisclaimer />} />
        <Route path="cookies-policy" element={<CookiePolicy />} />
        <Route path="project/:slug" element={<ProjectDetails />} />
        <Route path="investment-opportunities" element={<Projects />} />
        <Route path="policies" element={<Policies />} />
        {/* Other public routes */}

        {/* Private Routes */}
        <Route element={<RequireAuth />}>
          <Route path="welcome" element={<Welcome />} />
          <Route path="profile" element={<Profile />} />
          <Route path="thankyou" element={<Thankyou />} />
          <Route path="payment-status" element={<PaymentStatus />} />
          <Route path="payment-success" element={<PaymentSuccess />} />
          <Route path="nft-payment-success" element={<NftTransferSuccess />} />
          <Route path="issuer/dashboard" element={<IssuerDashboard />} />
          <Route path="exchange" element={<Exchange />} />
          <Route path="exchange/:slug" element={<TradeProject />} />
          <Route path="exchange/payment" element={<TradePayment />} />
          <Route path="verify/profile-change/:token" element={<ProfileChange />} />
          <Route path="logout" element={<Logout />} />
          <Route path="transfer" element={<TransferNft />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Route>
      <Route path="/404" element={<NoMatch />} />
    </Routes>
  );
};

export default AllRoutes;
