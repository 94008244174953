import React from "react";
import { useTranslation } from "react-i18next";

const NoMatch = () => {
  const [t] = useTranslation();

  return (
    <>
      <div className="pageContent error-page d-flex justify-content-center align-items-center flex-column p-5">
        <h1>{t("error.notFound")} <span>:(</span></h1>
        <span>{t("error.pageDoesNotExist")}.</span>
        <p>404</p>
      </div>
    </>
  );
};

export default NoMatch;
