import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentToken } from "./authSlice";
import jwt_decode from "jwt-decode";
import { logout } from "./authSlice";
import { selectDefaultLang } from "../extraReducers";

const RequireAuth = () => {
  const token = useSelector(selectCurrentToken);
  const lang = useSelector(selectDefaultLang)
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (token) {
    const decodedToken = jwt_decode(token);
    if (decodedToken.exp * 1000 < new Date().getTime()) {
      console.log("Token Expired");
      dispatch(logout());
      navigate(`/${lang}/login`);
    }
  }

  return token ? (
    <Outlet />
  ) : (
    <Navigate to={`/${lang}/login`} state={{ from: location }} replace />
  );
};
export default RequireAuth;
