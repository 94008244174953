import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Button,
  Col,
  Card,
  Row,
  Alert,
  ListGroup,
  Form,
  Table,
  Badge
} from "react-bootstrap";
import AnchorLink from "anchor-link";
import AnchorLinkBrowserTransport from "anchor-link-browser-transport";
import blockchains from "../../assets/blockchains.json";
import { useSelector, useDispatch } from "react-redux";
import { selectDefaultLang, selectTradeData } from "../../app/features/extraReducers";
import { useGetUserInformationQuery } from "../../app/features/apisSlice";
import { useSaveTransferDetailMutation } from "../../app/features/secondaryMarketApiSlice";
import Loader from "../../components/Loader";
import PageTitle from "../../components/PageTitle";
import ErrorPage from "../../components/ErrorPage";
import { setTradeData } from "../../app/features/extraReducers";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const TradePayment = () => {
  const dispatch = useDispatch();
  const lang = useSelector(selectDefaultLang)
  const timestampRef = useRef(Date.now()).current;
  const tradeData = useSelector(selectTradeData);
  if (!tradeData) window.location.href = `/${lang}/exchange`;
  const { trasactionObj, commission } = tradeData;
  const [errorMsg, setErrorMsg] = useState(null);
  const [link, setLink] = useState();
  const [identitySession, setIdentitySession] = useState();
  const [sessions, setSessions] = useState();
  const [transactionId, setTransactionId] = useState();
  const [memoText, setMemoText] = useState("");
  const [t] = useTranslation();
  
  const {
    data: userData,
    isLoading: userIsLoading,
    isError,
    error,
  } = useGetUserInformationQuery({
    id: tradeData.user,
    sessionId: timestampRef,
  });

  const transport = new AnchorLinkBrowserTransport();
  const establishLink = async () => {
    const eLink = new AnchorLink({
      transport,
      chains: blockchains.map((b) => ({
        chainId: b.chainId,
        nodeUrl: `${b.rpcEndpoints[0].protocol}://${b.rpcEndpoints[0].host}:${b.rpcEndpoints[0].port}`,
      })),
    });
    const session = await eLink.restoreSession("anchor-link-demo-multipass");
    const sessions = await eLink.listSessions("anchor-link-demo-multipass");

    setIdentitySession(session);
    setSessions(sessions);
    setLink(eLink);
  };

  const addAccount = async () => {
    setErrorMsg(null);
    try {
      // !Use the anchor-link login method with the chain id to establish a session
      const identity = await link.login("anchor-link-demo-multipass");

      //!Retrieve a list of all available sessions to update demo state
      const sessions = await link.listSessions("anchor-link-demo-multipass");

      setIdentitySession(identity.session);
      setSessions(sessions);
    } catch (error) {
      setErrorMsg(error.message);
    }
  };

  // !Remove/delete a session
  const removeSession = async () => {
    let ls = identitySession;
    await link.removeSession("anchor-link-demo-multipass", ls.auth, ls.chainId);

    if (
      identitySession &&
      identitySession.auth.actor.equals(ls.auth.actor) &&
      identitySession.auth.permission.equals(ls.auth.permission)
    ) {
      setIdentitySession();
    }
    setSessions(
      sessions.filter(
        (s) =>
          !(
            s.auth.actor.equals(ls.auth.actor) &&
            s.auth.permission.equals(ls.auth.permission)
          )
      )
    );
    setTransactionId(null);
  };

  const signTransaction = async () => {
    setErrorMsg(null);
    try {
      const action = {
        authorization: [
          {
            actor: String(identitySession.auth.actor),
            permission: String(identitySession.auth.permission),
          },
        ],
        account: tradeData.account,
        // account: "tokenwallet1",
        name: "transfer",
        data: {
          from: String(identitySession.auth.actor),
          to: trasactionObj.to,
          quantity: trasactionObj.quantity,
          // to: "ybik3wv1imgj",
          // quantity: "0.0001 PPP",
          memo: memoText,
        },
      };
      const commissionCharge = {
        authorization: [
          {
            actor: String(identitySession.auth.actor),
            permission: String(identitySession.auth.permission),
          },
        ],
        account: tradeData.account,
        // account: "tokenwallet1",
        name: "transfer",
        data: {
          from: String(identitySession.auth.actor),
          to: commission.to,
          quantity: commission.quantity,
          memo: "0.5% Commission Charge",
        },
      };
      // const action = {
      //   authorization: [
      //     {
      //       actor: String(identitySession.auth.actor),
      //       permission: String(identitySession.auth.permission),
      //     },
      //   ],
      //   account: "tokenwallet1",
      //   name: "transfer",
      //   data: {
      //     from: String(identitySession.auth.actor),
      //     to: "z3rcazefth3g",
      //     quantity: "0.0001 PPP",
      //     memo: memoText,
      //   },
      // };

      // const commissionCharge = {
      //   authorization: [
      //     {
      //       actor: String(identitySession.auth.actor),
      //       permission: String(identitySession.auth.permission),
      //     },
      //   ],
      //   account: "tokenwallet1",
      //   name: "transfer",
      //   data: {
      //     from: String(identitySession.auth.actor),
      //     to: "1tem5wi23wvq",
      //     quantity: "0.0001 PPP",
      //     memo: "0.5% Commission Charge",
      //   },
      // };

      // !Call transact on the session (compatible with eosjs.transact)
      const response = await identitySession.transact(
        { actions: [action, commissionCharge] }
        // { broadcast: true } // true is only working with EOS token
      );

      // !Update application state with the responses of the transaction
      if (response?.processed?.id) setTransactionId(response?.processed?.id);
      sendTransferDetail(response?.processed?.id);
    } catch (error) {
      setErrorMsg(error.message);
    }
  };

  const [saveTransferDetail] = useSaveTransferDetailMutation();
  const sendTransferDetail = async (txnId) => {
    setErrorMsg(null);

    let data = {
      trx_id: txnId,
      orderBookId: tradeData.tradeId,
    };

    try {
      const transferDetail = await saveTransferDetail(data).unwrap();
      console.log(1, transferDetail);
      dispatch(setTradeData(null));
    } catch (err) {
      if (!err) setErrorMsg(t("error.noServerResponse"));
      else if (err?.data?.message) setErrorMsg(err?.data?.message);
      else setErrorMsg(t("error.failedToAddTheOrder"));
    }
  };

  useEffect(() => {
    if (
      identitySession &&
      String(identitySession.auth.actor) !== trasactionObj.from
    ) {
      removeSession();
      Swal.fire({
        title: `${t("alert")}`,
        html: `${t("pleaseSignInWith")} <b>${trasactionObj.from}</b> ${t("accountToCompleteTransaction")}`,
        icon: "error",
      });
    }
    // eslint-disable-next-line
  }, [identitySession, trasactionObj.from]);

  useEffect(() => {
    establishLink();
    // eslint-disable-next-line
  }, []);

  if (userIsLoading) return <Loader />;
  if (isError) return <ErrorPage error={JSON.stringify(error)} />;

  return (
    <>
      <PageTitle title={t("exchange.thankYouHereYouCanFindThePaymentInstructions")} />
      <div className="pageContent">
        <Container>
          <h2 className="titleText">{("exchange.paymentInstructions")}</h2>
          <Row className="mt-5">
            <Col lg="6" md="6" sm="12" className="mb-3">
              <Card className="shadow">
                <Card.Body>
                  {errorMsg && (
                    <p className="text-danger" aria-live="assertive">
                      {errorMsg}
                    </p>
                  )}

                  <Table striped size="sm">
                    <tbody>
                      <tr>
                        <th>
                          <strong>{t("name")}: </strong>
                        </th>
                        <td>
                          {userData?.message?.first_name}{" "}
                          {userData?.message?.last_name}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <strong>{t("order.type")}: </strong>
                        </th>
                        <td>
                          <h6 className="m-0">
                            <Badge
                              pill
                              bg={
                                tradeData.type === "buy" ? "success" : "danger"
                              }
                            >
                              {tradeData.type}
                            </Badge>
                          </h6>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <strong>{t("exchange.contract")}: </strong>
                        </th>
                        <td>{tradeData.account}</td>
                      </tr>
                    </tbody>
                  </Table>

                  <h6>{t("exchange.transferDetails")}</h6>
                  <Table striped size="sm">
                    <tbody>
                      <tr>
                        <th>
                          <strong>{t("order.quantity")}: </strong>
                        </th>
                        <td>{trasactionObj.quantity}</td>
                      </tr>
                      <tr>
                        <th>
                          <strong>{t("exchange.to")}: </strong>
                        </th>
                        <td>{trasactionObj.to}</td>
                      </tr>
                      <tr>
                        <th>
                          <strong>{t("exchange.from")}: </strong>
                        </th>
                        <td>{trasactionObj.from}</td>
                      </tr>
                    </tbody>
                  </Table>

                  <div>
                    {identitySession ? (
                      <>
                        <Alert variant="secondary">
                          <Alert.Heading className="text-primary">
                            {t("nft.great")}!
                          </Alert.Heading>
                          <p>
                            {t("nft.youAreCurrentlySignedInAs")}{" "}
                            <Alert.Link
                              className="text-primary"
                              href={`https://bloks.io/account/${String(
                                identitySession.auth.actor
                              )}`}
                            >
                              {String(identitySession.auth.actor)}
                            </Alert.Link>
                            .
                          </p>
                          <hr />
                          <Form.Control
                            type="text"
                            value={memoText}
                            onChange={(e) => setMemoText(e.target.value)}
                            placeholder={t("nft.enterMemo")}
                          />
                          <Button
                            onClick={signTransaction}
                            disabled={transactionId ? true : false}
                          >
                            {t("nft.signTransaction")}
                          </Button>{" "}
                          <Button onClick={removeSession} className="bg-danger">
                            {t("nft.removeOrChangeAccount")}
                          </Button>
                        </Alert>
                      </>
                    ) : (
                      <Button onClick={addAccount}>
                        {t("nft.selectYourAnchorWallet")}
                      </Button>
                    )}
                  </div>
                  <div>
                    {transactionId && (
                      <ListGroup>
                        <ListGroup.Item variant="success">
                          <div className="fw-bold">{("nft.transactionSubmitted")}</div>
                          <span>{transactionId}</span>
                        </ListGroup.Item>
                      </ListGroup>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="6" md="6" sm="12" className="mb-3 p-3">
              <h5 className="fw-bold">{t("exchange.commission")} (0.5%)</h5>

              <Table striped size="sm">
                <tbody>
                  <tr>
                    <th>
                      <strong>{t("order.quantity")}: </strong>
                    </th>
                    <td>{commission.quantity}</td>
                  </tr>
                  <tr>
                    <th>
                      <strong>{("exchange.to")}: </strong>
                    </th>
                    <td>{commission.to}</td>
                  </tr>
                  <tr>
                    <th>
                      <strong>{t("exchange.from")}: </strong>
                    </th>
                    <td>{commission.from}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TradePayment;
