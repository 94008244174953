import React from "react";
import { Table, ButtonGroup, Button } from "react-bootstrap";
import { useGetPersonalBookDataQuery } from "../app/features/secondaryMarketApiSlice";
import Swal from "sweetalert2";
import ErrorPage from "./ErrorPage";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";

const IssuerTransaction = () => {
  const {
    // eslint-disable-next-line
    data: transactions,
    isLoading,
    isError,
    error,
  } = useGetPersonalBookDataQuery();
  const [t] = useTranslation();
  const tHeader = [t("order.price"), t("order.type"), t("payment.seller"), t("payment.buyer"), t("kyc.status"), t("payment.action")];

  const tBody = [
    {
      id: 1,
      transId: "Sell1000PNX10000EuroPlamen",
      type: "Sell",
      seller: "Seller ID",
      buyer: "Buyer ID",
      status: "confirmed",
    },
    {
      id: 2,
      transId: "Sell1000PNX10000EuroPlamen",
      type: "Buy",
      seller: "Seller ID",
      buyer: "Buyer ID",
      status: "refused",
    },
    {
      id: 3,
      transId: "Sell1000PNX10000EuroPlamen",
      type: "Sell",
      seller: "Seller ID",
      buyer: "Buyer ID",
      status: "",
    },
  ];

  const handleConfirmAlert = async (status, callback) => {
    await Swal.fire({
      title: `${t("order.areYouSure")}`,
      html: `${t("youWant")} <strong>${status}</strong> ${t("payment.thisTransaction")}?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: t("cancel"),
      confirmButtonColor: "#198754",
      confirmButtonText: t("yes"),
    }).then((result) => {
      if (result.isConfirmed) callback(result.isConfirmed);
    });
  };

  const handleActionStatus = (status) => {
    handleConfirmAlert(status, function (confirmed) {
      if (confirmed) handleMessageAlert(status);
    });
  };

  const handleMessageAlert = (status) =>
    Swal.fire(
      `${t("payment.transaction")} ${
        status === t("payment.confirm") ? t("payment.confirmed") : t("payment.refused")
      } ${t("payment.successfully")}!`,
      "",
      "success"
    );

  if (isLoading) return <Loader />;
  if (isError) return <ErrorPage error={JSON.stringify(error)} />;

  return (
    <>
      <h5 className="mb-3">{t("issuer.transactions")}</h5>
      <Table striped responsive>
        <thead>
          <tr>
            {tHeader.map((item, i) => {
              return <th key={i}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tBody.map((item, i) => {
            return (
              <tr key={i}>
                <td>{item.transId}</td>
                <td>{item.type}</td>
                <td>{item.seller}</td>
                <td>{item.buyer}</td>
                <td>{item.status.toUpperCase()}</td>
                <td>
                  <ButtonGroup size="sm">
                    <Button
                      className="btn-sm"
                      disabled={item.status ? true : false}
                      onClick={() => handleActionStatus("confirm")}
                    >
                      {t("confirm")}
                    </Button>
                    <Button
                      className="bg-danger btn-sm"
                      disabled={item.status ? true : false}
                      onClick={() => handleActionStatus("refuse")}
                    >
                      {t("refuse")}
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {/* {transactions.length === 0 && <ErrorPage error="No Record Found" />}
      {JSON.stringify(transactions)} */}
    </>
  );
};

export default IssuerTransaction;
