import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Button, Spinner } from "react-bootstrap";
import Select from "react-select";
import {
  useGetCountriesQuery,
  useExcludeCountriesMutation,
  useGetUserProfileQuery,
} from "../app/features/apisSlice";
import ErrorPage from "./ErrorPage";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

const KycExclusionControl = (issuer) => {
  const sessionId = useRef(Date.now()).current
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedNationalities, setSelectedNationalities] = useState([]);
  const [retailInvestor, setRetailInvestor] = useState(false);
  const [professionalInvestor, setProfessionalInvestor] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [successMsg, setSuccessMsg] = useState();
  const [loading, setLoading] = useState(false);
  const { data, isLoading, isError, error } = useGetCountriesQuery();
  const { data: issuerData, isLoading: issuerLoading } = useGetUserProfileQuery({ sessionId })
  const handleCountryChange = (options) => setSelectedCountries(options);
  const handleNationaliyChange = (options) => setSelectedNationalities(options);
  const [t] = useTranslation();

  const [excludeCountries] = useExcludeCountriesMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg();
    setSuccessMsg();
    setLoading(true);

    let countries = [];
    let nationalities = [];

    if (selectedCountries.length > 0 || selectedNationalities.length > 0 || professionalInvestor || retailInvestor) {
      if (selectedCountries.length > 0)
        selectedCountries.map((country) => countries.push(country.id));
      if (selectedNationalities.length > 0)
        selectedNationalities.map((country) => nationalities.push(country.id));

      let data = {
        exclude: countries,
        nationality: nationalities,
        professional: professionalInvestor ? "true" : "false",
        retail: retailInvestor ? "true" : "false",
      };
      try {
        const response = await excludeCountries(data);
        if (response?.error) setErrMsg(response?.error?.error);
        else setSuccessMsg(response?.message);
        Swal.fire(t("investmentSetting.exlcusionControlSuccess"), "", "success");
      } catch (err) {
        if (!err) setErrMsg(t("error.noServerResponse"));
        else if (err?.data?.msg) setErrMsg(err?.data?.msg);
        else if (err?.data?.error) setErrMsg(err?.data?.error);
        else setErrMsg(t("error.noServerResponse"));
      }
    } else setErrMsg(t("error.nothingToSave"));

    setLoading(false);
  };

  useEffect(() => {
    if (!issuerLoading && !isLoading) {
      // console.log( issuerData?.issuer)
      if (issuerData?.issuer?.projectRestriction) {
        const defaultCountriesIds = issuerData?.issuer?.projectRestriction?.country
        const defaultNationalitiesIds = issuerData?.issuer?.projectRestriction?.nationality

        if (defaultCountriesIds) {
          const defaultCountries = defaultCountriesIds ? data.filter(item => defaultCountriesIds.includes(item.id)) : null
          setSelectedCountries(defaultCountries)
        } else {
          setSelectedCountries([])
        }

        if (defaultNationalitiesIds) {
          const defaultNationalities = data.filter(item => defaultNationalitiesIds.includes(item.id));
          setSelectedNationalities(defaultNationalities)
        } else {
          setSelectedNationalities([])
        }

        console.log(issuerData?.issuer?.projectRestriction?.retail)
        const retail = issuerData?.issuer?.projectRestriction?.retail
        setRetailInvestor(retail);
        const professional = issuerData?.issuer?.projectRestriction?.professional
        setProfessionalInvestor(professional)
      }
    }
  }, [issuerLoading, issuerData, isLoading, data])

  if (isLoading || issuerLoading) return <Loader />;
  if (isError) return <ErrorPage error={JSON.stringify(error)} />;

  return (
    <>
      <h5 className="mb-3">{t("issuer.selectInvestmentSettingForYourProject")}</h5>
      {successMsg && (
        <p className="text-success text-center" aria-live="assertive">
          {successMsg}
        </p>
      )}
      {errMsg && (
        <p className="m-3 text-danger text-center" aria-live="assertive">
          {errMsg}
        </p>
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Col} lg="8" className="mb-3">
          <Form.Label>
            1. {t("issuer.countriesExcludedByInvestorsResidencies")}
          </Form.Label>
          <Select
            value={selectedCountries}
            options={data}
            getOptionLabel={(option) => option.en_name}
            getOptionValue={(option) => option.id}
            closeMenuOnSelect={false}
            onChange={handleCountryChange}
            isMulti
            className="sel2-primary"
          />
        </Form.Group>
        <Form.Group as={Col} lg="8" className="mb-3">
          <Form.Label>
            2. {t("issuer.countriesExcludedByInvestorsNationalities")}
          </Form.Label>
          <Select
            value={selectedNationalities}
            options={data}
            getOptionLabel={(option) => option.en_name}
            getOptionValue={(option) => option.id}
            closeMenuOnSelect={false}
            onChange={handleNationaliyChange}
            isMulti
            className="sel2-primary"
          />
        </Form.Group>
        <Form.Group as={Col} lg="6">
          <Form.Label>
            3. {t("issuer.whichTypeOfUserCanMakeInvestmentToTheProject")}
          </Form.Label>
          <Form.Check
            type="checkbox"
            label={t("issuer.retail")}
            onChange={(e) => setRetailInvestor(e.target.checked)}
            checked={retailInvestor}
          />
          <Form.Check
            type="checkbox"
            label={t("issuer.professional")}
            onChange={(e) => setProfessionalInvestor(e.target.checked)}
            checked={professionalInvestor}
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="submitBtnPrimary">
          {t("save")}{" "}
          {loading && (
            <Spinner
              variant="light"
              size="sm"
              role="status"
              aria-hidden="true"
              animation="border"
            />
          )}
        </Button>
      </Form>
    </>
  );
};

export default KycExclusionControl;
