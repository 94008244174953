import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import ChangePassword from "../Forms/ChangePassword";
import ProfileEdit from "../Forms/ProfileEdit";
import {
  useGetUserProfileQuery,
  useUserNftMutation,
} from "../app/features/apisSlice";
import Loader from "../components/Loader";
import ErrorPage from "../components/ErrorPage";
import style from "./style.module.css";
import KycCard from "../components/KycCard";
import UserNft from "../Forms/UserNft";
import { useDispatch, useSelector } from "react-redux";
import { selectDefaultLang, setNftList } from "../app/features/extraReducers";
import { useTranslation } from "react-i18next";
import RegisterEmail from "../components/RegisterEmail";
import { logout } from "../app/features/auth/authSlice";
import ProfileSetting from "../Forms/ProfileSetting";
// import ProfileStableCoin from "../components/ProfileStableCoin";

const Profile = () => {
  const lang = useSelector(selectDefaultLang)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [nftData, setNftData] = useState([])
  const [t] = useTranslation();
  const navItems = [t("profile.KYC"), t("profile.profile"), t("profile.changePassword"), t("profile.settings"),nftData && nftData.status !== 400 ? "NFT" : ''];
  const [selectedItem, setSelectedItem] = useState("KYC");

  const sessionId = useRef(Date.now()).current;
  const { data, isLoading, isError, error, isSuccess, refetch } = useGetUserProfileQuery({ sessionId });

  const [userNft] = useUserNftMutation();
  const handleUserNFT = async () => {
    const response = await userNft();
    try {
      if (response.error) {
        setNftData(response.error);
        dispatch(setNftList(error));
      } else {
        setNftData(response.data);
        dispatch(setNftList(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleUserNFT();
    // eslint-disable-next-line
  }, []);

  if (isLoading) return <Loader />;
  if(isError && error?.data?.error === "Unauthorized.") {
    dispatch(logout());
    navigate(`/${lang}/login`);
  }
  return (
    <>
      <PageTitle title={t("profile.profile")} profile={data} />
      {isError && (
        <>
          {(() => {
            if(error?.status === "FETCH_ERROR") {
              return <ErrorPage error="Unable to fetch the stable coin details." />
            } else if (error?.data?.error === "email-required") {
              return <RegisterEmail userData={error?.data?.data} />
            } else {
              return <ErrorPage error="Sorry, we're experiencing some errors. Please try again later." />
            }
          })()}
        </>
      )}
      {
        isSuccess && (
          <div className="pageContent">
            <Tab.Container defaultActiveKey={`0`}>
              <Container>
                <Nav variant="pills" className={`${style.navPills}`}>
                  {navItems.map((item, i) => {
                    return (
                      <Nav.Item key={i} onClick={() => setSelectedItem(item)}>
                        <Nav.Link eventKey={i} className={`${style.navLink}`}>
                          {item}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}

                  <Nav.Item className="ms-auto">
                    <Link
                      to={`/${lang}/logout`}
                      className={`${style.logoutBtn} m-0 btn btn-primary d-none d-md-block`}
                    >
                      {t("profile.logout")}
                    </Link>
                  </Nav.Item>
                </Nav>
                <Row className="mt-md-5">
                  <Col lg="12">
                    {(() => {
                      if (nftData.length > 0) {
                        switch (selectedItem) {
                          case t("profile.profile"):
                            return !isLoading ? (
                              <ProfileEdit userData={data} refetch={refetch}/>
                            ) : (
                              <Loader />
                            );
                          case t("profile.changePassword"):
                            return <ChangePassword />;
                          case "NFT":
                            return <UserNft />;
                          case t("profile.settings"):
                              return <ProfileSetting userData={data} refetch={refetch}/>;
                          // case "Stable Coin":
                          //   return <ProfileStableCoin />;
                          default:
                            return <KycCard userData={data} />;
                        }
                      } else {
                        switch (selectedItem) {
                          case t("profile.profile"):
                            return !isLoading ? (
                              <ProfileEdit userData={data} refetch={refetch}/>
                            ) : (
                              <Loader />
                            );
                          case t("profile.changePassword"):
                            return <ChangePassword />;
                          case t("profile.settings"):
                              return <ProfileSetting userData={data} refetch={refetch}/>;
                          // case "Stable Coin":
                          //   return <ProfileStableCoin />;
                          default:
                            return <KycCard userData={data} />;
                        }
                      }
                    })()}
                  </Col>
                </Row>
              </Container>
            </Tab.Container>
          </div>
        )
      }
    </>
  );
};

export default Profile;
