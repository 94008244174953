import React from "react";
import { Container } from "react-bootstrap";
import { useGetCookiePolicyQuery } from "../app/features/apisSlice";
import PageTitle from "../components/PageTitle";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDefaultLang } from "../app/features/extraReducers";

const CookiePolicy = () => {
  const { status, error } = useGetCookiePolicyQuery();
  const [t] = useTranslation();

  const lang = useSelector(selectDefaultLang);

  if (status === "pending") return <Loader />;
  return (
    <>
      <PageTitle title={t("cookiePolicy")} />
      <div className="pageContent">
        {lang === "en" && (
          <Container
            dangerouslySetInnerHTML={{ __html: error.data }}
          ></Container>
        )}

        {lang === "it" && (
          <Container>
            <div className="modal-body">
              <p>
                <strong>
                  <span>Cookies e tecnologie di tracciamento</span>
                </strong>
              </p>

              <p>
                <span>
                  Tecnologie come: cookies, pixel, tag e script vengono
                  utilizzati da EDSX GmbH e i nostri partner, inclusi partner di
                  marketing, affiliate o fornitori di servizi di analisi e
                  processi aziendali.
                </span>
              </p>

              <p>
                <span>
                  I cookies possono essere memorizzati per periodi di tempo
                  variabili nel browser o nel dispositivo. Esistono diversi tipi
                  di cookies. Una classificazione importante dei cookies
                  (soprattutto in relazione al consenso) sono i cookies
                  Essenziali e Non Essenziali.
                </span>
              </p>

              <p>
                <span>
                  &nbsp;Cookies essenziali: utilizzati unicamente per consentire
                  o facilitare la trasmissione di comunicazioni su una rete; o
                  strettamente necessari per fornire un servizio online (ad
                  esempio il nostro sito web o un servizio sul nostro sito web)
                  che hai richiesto. Senza questi cookies, l’interazione del tuo
                  dispositivo con il nostro Sito non potrebbe aver luogo.&nbsp;
                </span>
              </p>
              <span>Questi cookies non richiedono approvazione.</span>
              <ul>
                <li>
                  <span>
                    &nbsp;Cookies per le prestazioni (Non essenziali). Il cui
                    posizionamento sul tuo dispositivo è possibile solo previo
                    consenso da parte tua. Puoi esprimere il tuo consenso
                    premendo il pulsante “Accetta” nella barra cookie emergente
                    in alto/basso della pagina web del Sito. I nostri cookies
                    per le prestazioni raccolgono informazioni anonime su come
                    utilizzi questo sito web e le sue funzionalità.
                  </span>
                </li>
                <li>
                  <span>
                    &nbsp;Cookies funzionali (Non essenziali): consentono a un
                    sito web di memorizzare informazioni già inserite (come nome
                    utente, lingua o posizione corrente) e offrire all’utente
                    funzionalità più personalizzate e migliorate.
                  </span>
                </li>
                <li>
                  <span>
                    &nbsp;Cookies di marketing (Non essenziali): Vengono
                    utilizzati per fornire annunci e altre comunicazioni più
                    rilevanti per te e i tuoi interessi. Vengono anche
                    utilizzati per limitare il numero di volte che vieni
                    visualizzato un annuncio e per aiutare a misurare
                    l’efficacia delle campagne pubblicitarie.
                  </span>
                </li>
                <li>
                  <span>
                    &nbsp; Altre tecnologie di tracciamento (Non essenziali):
                    Alcuni siti utilizzano cose come pixel trasparenti, GIF
                    chiari, tag di pagina e spyware per comprendere come le
                    persone li stanno utilizzando e per indirizzare annunci
                    pubblicitari a loro in base ai loro interessi. In genere
                    hanno la forma di un’immagine trasparente e di piccole
                    dimensioni incorporata in una pagina web o e-mail.
                    Funzionano insieme ai cookies e catturano dati come il tuo
                    indirizzo IP, quando hai visualizzato la pagina o l’e-mail,
                    quale dispositivo stavi utilizzando e dove ti trovavi.
                  </span>
                </li>
              </ul>

              <p>
                <span>
                  Queste tecnologie vengono utilizzate per analizzare le
                  tendenze, gestire il nostro Sito, tracciare i movimenti degli
                  utenti all’interno del nostro Sito e raccogliere informazioni
                  demografiche sulla nostra utenza nel suo complesso. Potremmo
                  ricevere relazioni basate sull’utilizzo di queste tecnologie
                  da parte di queste società sia a livello individuale che
                  aggregato. Utilizziamo i cookies per stimare le dimensioni e i
                  modelli di utilizzo del nostro pubblico; per memorizzare
                  informazioni sulle tue preferenze e consentirci di
                  personalizzare il nostro Sito in base ai tuoi interessi
                  individuali; per velocizzare le tue ricerche e riconoscerti
                  quando ritorni sul nostro Sito. Gli utenti possono controllare
                  l’utilizzo di cookies a livello del browser individuale. Se
                  rifiuti i cookies, potrai comunque utilizzare il nostro Sito,
                  ma la tua capacità di utilizzare alcune funzioni o aree del
                  nostro Sito potrebbe essere limitata.
                </span>
              </p>

              <p>
                <span>
                  Collaboriamo con reti pubblicitarie di terze parti per
                  visualizzare pubblicità sul nostro Sito o per gestire la
                  nostra pubblicità su altri siti. Il nostro partner di rete
                  pubblicitaria utilizza cookies e pixel per raccogliere dati
                  non personali sulle tue attività su questo e altri siti Web
                  per fornirti pubblicità mirate in base ai tuoi interessi.
                </span>
              </p>

              <p>
                <span>
                  Inoltre, utilizziamo un servizio di pubblicazione di annunci
                  per visualizzare annunci di terze parti sul nostro Sito.
                  Quando visualizzi o fai clic su un annuncio, verrà impostato
                  un cookie per consentirci di fornire in modo migliore annunci
                  che possono essere di tuo interesse su questo e altri siti
                  Web. Puoi optare out dell’uso di questo cookie visitando i
                  gestori delle preferenze di tracciamento sopra riportati.
                </span>
              </p>

              <p>
                <span>
                  Local Storage Objects (HTML5/Flash): Terze parti con cui
                  collaboriamo per fornire determinate funzionalità sul nostro
                  Sito o visualizzare pubblicità basata sulla tua attività di
                  navigazione su internet utilizzano LSO come HTML5 o Flash per
                  raccogliere e memorizzare informazioni.
                </span>
              </p>

              <p>
                <strong>
                  <span>
                    Disabilitare/abilitare cookies, pixel e altre tecnologie di
                    tracciamento tramite il browser
                  </span>
                </strong>
              </p>

              <p>
                <span>
                  Dovresti sapere che eventuali preferenze andranno perse se
                  cancelli tutti i cookies e molti siti web non funzioneranno
                  correttamente o perderai alcune funzionalità. Non
                  raccomandiamo di disattivare i cookies quando si utilizza il
                  nostro sito web per queste ragioni.
                </span>
              </p>

              <p>
                <span>
                  Hai la possibilità di accettare o rifiutare i cookies da
                  qualsiasi sito web modificando le impostazioni del tuo
                  browser. Se desideri limitare o bloccare i cookies impostati
                  dal nostro sito web, puoi farlo dalle impostazioni del
                  browser. Per informazioni su come gestire e disabilitare i
                  cookies, puoi utilizzare la funzione “Guida” all’interno del
                  tuo browser o visitare www.aboutcookies.org o
                  www.allaboutcookies.org.
                </span>
              </p>

              <p>
                <span>
                  Poiché rispettiamo il tuo diritto alla privacy, puoi scegliere
                  di non consentire alcuni tipi di cookies che raccogliamo sul
                  nostro sito web. Fai clic sulle diverse intestazioni di
                  categoria per saperne di più e modificare le impostazioni
                  predefinite (pulsante On-off). Tuttavia, il blocco di alcuni
                  tipi di cookies può influire sulla tua esperienza del sito e
                  sui servizi che siamo in grado di offrire.
                </span>
              </p>

              <p>
                <span>
                  Per disabilitare direttamente i tipi di cookies ed evitare
                  pixel e altre tecnologie di tracciamento, è possibile renderli
                  inefficaci disattivando i cookies nel browser.
                </span>
              </p>

              <p>
                <span>
                  Chrome:
                  https://support.google.com/chrome/answer/95647?hl=it&amp;co=GENIE.Platform=Desktop
                </span>
              </p>

              <p>
                <span>
                  Internet Explorer:
                  https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies
                </span>
              </p>

              <p>
                <span>
                  Opera: https://help.opera.com/it/latest/web-preferences
                </span>
              </p>

              <p>
                <span>Safari: https://support.apple.com/it-it/HT201265</span>
              </p>

              <p>
                <span>
                  Firefox:
                  https://support.mozilla.org/it/kb/abilitare-e-disabilitare-i-cookie-le-impostazioni-relative-ai-siti
                </span>
              </p>

              <p>
                <span>
                  Se utilizzi un browser web non elencato qui sopra, consulta
                  per favore la documentazione o la guida online del tuo browser
                  per ulteriori informazioni.
                </span>
              </p>
            </div>
          </Container>
        )}
      </div>
    </>
  );
};

export default CookiePolicy;
