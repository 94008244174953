import React, { useState } from "react";
import { useTranslation } from "react-i18next"
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import {
  useUpdateProfileMutation,
  useResendEmailMutation,
} from "../app/features/apisSlice";
import Swal from "sweetalert2";

const RegisterEmail = ({ userData }) => {
  const [t] = useTranslation()
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(
    <>
      {t("profile.emailSentMsg1")}<br />
      {t("profile.emailSentMsg2")}
    </>
  );
  const handleEmailChange = (e) => setEmail(e.target.value);
  const [updateProfile] = useUpdateProfileMutation();
  const [resendEmail] = useResendEmailMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      setIsLoading(true);
      try {
        let data = {
          email,
          name: userData.first_name,
          lastName: userData.last_name,
        };
        const response = await updateProfile(data).unwrap();
        Swal.fire(`${response.message}`, "", "success");
        localStorage.setItem("isMailSent", "true");
      } catch (error) {
        if (!error) Swal.fire("No Server Response", "", "error");
        else if (error?.data?.message)
          Swal.fire(`${error?.data?.message}`, "", "error");
        else Swal.fire("Failed to update. Please try again later", "", "error");
      }
      setIsLoading(false);
    } else {
      Swal.fire(`Please enter your email`, "", "error");
    }
  };

  const resendMail = async () => {
    setIsLoading(true);
    try {
      const response = await resendEmail().unwrap();
      if (response) {
        setConfirmationMessage(response?.message);
        Swal.fire(`${response.message}`, "", "success");
      }
    } catch (error) {
      if (!error) Swal.fire("No Server Response", "", "error");
      else if (error?.data?.message)
        Swal.fire(`${error?.data?.message}`, "", "error");
      else Swal.fire("Failed to update. Please try again later", "", "error");
    }
    setIsLoading(false);
  };

  return (
    <div className="pageContent">
      <Container>
        <Row>
          <Col xs={12} md={8}>
            {localStorage.getItem("isMailSent") !== "true" ? (
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <h6>{t("profile.registerEmail")}</h6>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  {t("save")}
                  {isLoading && (
                    <Spinner
                      variant="light"
                      size="sm"
                      role="status"
                      animation="border"
                    />
                  )}
                </Button>
              </Form>
            ) : (
              <>
                <h5 className="text-danger">{confirmationMessage}</h5>
                <p>
                  {t("profile.emailNotReceived")}
                  <span
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      resendMail();
                    }}
                  >
                     {t("resend")}
                    {isLoading && (
                      <Spinner
                        variant="primary"
                        size="sm"
                        role="status"
                        animation="border"
                      />
                    )}
                  </span>
                </p>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RegisterEmail;
