import React from "react";
import { Container } from "react-bootstrap";
import PageTitle from "../components/PageTitle";
import { useGetRiskDisclaimerQuery } from "../app/features/apisSlice";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDefaultLang } from "../app/features/extraReducers";

const RiskDisclaimer = () => {
  const { status, error } = useGetRiskDisclaimerQuery();
  const [t] = useTranslation();

  const lang = useSelector(selectDefaultLang);

  if (status === "pending") return <Loader />;
  return (
    <>
      <PageTitle title={t("footer.riskDisclaimer")} />
      <div className="pageContent">
        {lang === "en" && (
          <Container
            dangerouslySetInnerHTML={{ __html: error.data }}
          ></Container>
        )}

        {lang === "it" && (
          <Container>
            <div className="modal-body">
              <h3>
                <strong>
                  <span>Avviso sui rischi</span>
                </strong>
              </h3>
              <p>
                <strong>
                  <span>
                    Accetto che gli investimenti oggetto delle promozioni
                    possano espormi a un rischio significativo di perdita di
                    tutto il denaro o altre propriet&agrave; investite. Sono
                    consapevole di poter richiedere consiglio a un soggetto
                    abilitato che si specializza nel fornire consulenza su
                    titoli non prontamente liquidabili e investimenti collettivi
                    non convenzionali.
                  </span>
                </strong>
              </p>

              <p>
                <span>
                  Attenzione, investire in societ&agrave; non quotate comporta
                  rischi specifici:
                </span>
              </p>
              <ul>
                <li>
                  <span>
                    Rischio di perdita parziale o totale del capitale con
                    eventuale insolvenza dell&rsquo;emittente
                  </span>
                </li>
                <li>
                  <span>
                    Redditivit&agrave; dell&rsquo;investimento dipendente dal
                    successo del progetto finanziato
                  </span>
                </li>
                <li>
                  <span>
                    Rischio di liquidit&agrave;: la rivendita dei titoli non
                    &egrave; garantita e pu&ograve; essere incerta o impossibile
                  </span>
                </li>
              </ul>

              <p>
                <span>
                  EDSX non garantisce il tuo investimento. Una strategia
                  prudente di investimento consiste nel:
                </span>
              </p>
              <ul>
                <li>
                  <span>Diversificare gli investimenti;</span>
                </li>
                <li>
                  <span>
                    Investire una quota non significativa dei tuoi risparmi;
                  </span>
                </li>
                <li>
                  <span>
                    Investire denaro di cui non avrai bisogno nel breve periodo.
                  </span>
                </li>
              </ul>

              <p>
                <strong>
                  <span>
                    Investire in security token emessi dalla Societ&agrave;
                    &egrave; associato a un certo grado di rischio e non
                    &egrave; appropriato per individui o entit&agrave; che non
                    hanno l&rsquo;esperienza per valutare correttamente tale
                    investimento. I potenziali investitori dovrebbero leggere e
                    considerare attentamente le informazioni sui principali
                    rischi specifici legati alle attivit&agrave; commerciali
                    della Societ&agrave;, insieme alle informazioni sui rischi
                    intrinseci dei security token offerti, prima di decidere di
                    acquistare security token emessi da una Societ&agrave;.
                  </span>
                </strong>
              </p>

              <p>
                <strong>
                  <span>
                    Si avverte i potenziali investitori che eventuali futuri
                    risultati operativi di una Societ&agrave; potrebbero
                    differire significativamente dai risultati passati a causa
                    di numerosi fattori, inclusi i fattori di rischio di seguito
                    indicati. Ciascuno dei rischi indicati qui o nella
                    documentazione della Societ&agrave; pu&ograve;,
                    individualmente o congiuntamente, avere effetti negativi
                    sull&rsquo;attivit&agrave;, la posizione finanziaria e lo
                    sviluppo futuro di una Societ&agrave;, pu&ograve;
                    influenzare negativamente il prezzo dei security token della
                    Societ&agrave; e gli investitori potrebbero perdere parte o
                    tutti i soldi investiti.
                  </span>
                </strong>
              </p>

              <ol>
                <li>
                  <strong>
                    <span>Perdita di capitale.&nbsp;</span>
                  </strong>
                  <span>
                    La maggior parte delle startup e molte altre attivit&agrave;
                    falliscono e, se investi in un&rsquo;attivit&agrave;
                    visualizzata sulla piattaforma, &egrave; molto pi&ugrave;
                    probabile che perderai tutto il tuo capitale investito
                    piuttosto che ottenere un rendimento o un profitto. Non
                    dovresti investire pi&ugrave; denaro nei tipi di
                    attivit&agrave; visualizzate sulla piattaforma di quanto
                    puoi permetterti di perdere senza modificare il tuo tenore
                    di vita standard.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Illiquidit&agrave;.&nbsp;</span>
                  </strong>
                  <span>
                    Quasi tutti gli investimenti che effettui in attivit&agrave;
                    visualizzate sulla piattaforma saranno altamente illiquidi.
                    &Egrave; molto improbabile che esista un mercato secondario
                    liquido per i titoli dell&rsquo;entit&agrave; oggetto di
                    investimento. Ci&ograve; significa che dovresti presumere di
                    non essere in grado di vendere le tue azioni fino a quando
                    l&rsquo;entit&agrave; oggetto di investimento non
                    entrer&agrave; in borsa o non verr&agrave; acquistata da
                    un&rsquo;altra societ&agrave;; e anche se
                    l&rsquo;entit&agrave; oggetto di investimento viene
                    acquistata da un&rsquo;altra societ&agrave; o entra in
                    borsa, il tuo investimento pu&ograve; continuare ad essere
                    illiquido. Anche per un&rsquo;attivit&agrave; di successo,
                    l&rsquo;offerta pubblica iniziale o l&rsquo;acquisizione
                    &egrave; improbabile che si verifichi per diversi anni dal
                    momento in cui effettui il tuo investimento. Per le
                    attivit&agrave; per le quali sono disponibili
                    opportunit&agrave; di mercato secondario, potrebbe essere
                    difficile trovare un acquirente o un venditore e gli
                    investitori non dovrebbero presumere che un&rsquo;uscita
                    anticipata sar&agrave; possibile solo perch&eacute; esiste
                    un mercato secondario.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Rarit&agrave; dei dividendi.&nbsp;</span>
                  </strong>
                  <span>
                    Le attivit&agrave; del tipo visualizzato sulla piattaforma
                    pagano raramente dividendi. Ci&ograve; significa che se
                    investi in un&rsquo;attivit&agrave; attraverso la
                    piattaforma, anche se &egrave; di successo &egrave;
                    improbabile che tu veda un rendimento del capitale o un
                    profitto fino a quando non sarai in grado di vendere le tue
                    azioni nell&rsquo;entit&agrave; oggetto di investimento.
                    Anche per un&rsquo;attivit&agrave; di successo, ci&ograve;
                    &egrave; improbabile che avvenga per diversi anni dal
                    momento in cui effettui il tuo investimento.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Diluizione.&nbsp;</span>
                  </strong>
                  <span>
                    Qualsiasi investimento effettuato in
                    un&rsquo;attivit&agrave; visualizzata sulla piattaforma
                    sar&agrave; probabilmente soggetto a diluizione. Ci&ograve;
                    significa che se l&rsquo;attivit&agrave; raccogliesse
                    capitale aggiuntivo in un momento successivo,
                    emetter&agrave; nuove azioni per i nuovi investitori e la
                    percentuale dell&rsquo;entit&agrave; oggetto di investimento
                    di tua propriet&agrave; diminuir&agrave;. Queste nuove
                    azioni potrebbero anche avere determinati diritti
                    preferenziali sui dividendi, sul ricavato della vendita e su
                    altre questioni e l&rsquo;esercizio di tali diritti
                    pu&ograve; svantaggiarti. Il tuo investimento potrebbe anche
                    essere soggetto a diluizione a seguito della concessione di
                    opzioni (o diritti simili per acquisire azioni) ai
                    dipendenti, ai fornitori o ad altre parti collegate con
                    l&rsquo;entit&agrave; oggetto di investimento.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Diversificazione.&nbsp;</span>
                  </strong>
                  <span>
                    Se scegli di investire in attivit&agrave; del tipo
                    visualizzato sulla piattaforma, tali investimenti dovrebbero
                    essere effettuati solo come parte di un portafoglio ben
                    diversificato. Ci&ograve; significa che dovresti investire
                    solo una quota relativamente piccola del tuo capitale
                    investibile in tali attivit&agrave; e la maggior parte del
                    tuo capitale investibile dovrebbe essere investita in
                    attivit&agrave; pi&ugrave; sicure e liquide. Significa anche
                    che dovresti ripartire il tuo investimento tra pi&ugrave;
                    attivit&agrave; invece di investire una somma maggiore in
                    poche attivit&agrave;.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Andamento degli investimenti.&nbsp;</span>
                  </strong>
                  <span>
                    Il passato non &egrave; necessariamente indicativo del
                    futuro andamento di un investimento. Il valore degli
                    investimenti pu&ograve; aumentare o diminuire e gli
                    investitori potrebbero non recuperare l&rsquo;importo
                    investito. Le variazioni dei tassi di cambio possono far
                    aumentare o diminuire il valore degli investimenti.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>IT.&nbsp;</span>
                  </strong>
                  <span>
                    Il processo di generazione, trasferimento e possesso di
                    token potrebbe essere soggetto ad errori. Il concetto di
                    smart contract e la tecnologia blockchain sono in fasi
                    iniziali di sviluppo e non c&rsquo;&egrave; garanzia che il
                    processo di generazione, trasferimento e possesso di token
                    non sia soggetto ad errori. Inoltre, il software potrebbe
                    contenere punti deboli e vulnerabilit&agrave; che potrebbero
                    portare a perdita o furto di token di partecipazione al
                    capitale di equity o ad altri problemi imprevedibili.
                  </span>
                </li>
                <li>
                  <strong>
                    <span>Rischi specifici della societ&agrave;.&nbsp;</span>
                  </strong>
                  <span>
                    Rischi insiti nell&rsquo;attivit&agrave; specifica di un
                    emittente, elenco non esaustivo: personale, fluttuazione
                    dell&rsquo;attivit&agrave;, dipendenze dal mercato, rischio
                    fornitori e potere contrattuale, potere contrattuale
                    clienti, rischio di nuovi concorrenti nel business e
                    prodotti/servizi alternativi, rischio di aumento della
                    concorrenza nel mercato correlato, rischio di nuovi
                    prodotti/servizi pi&ugrave; efficienti, rischio di
                    interruzione dell&rsquo;attivit&agrave;, volatilit&agrave;
                    dei prezzi delle materie prime, rischio normativo, rischio
                    macroeconomico, rischio paese, rischio geopolitico, ecc. Si
                    prega inoltre di consultare la documentazione
                    dell&rsquo;emittente.
                  </span>
                </li>
              </ol>
            </div>
          </Container>
        )}
      </div>
    </>
  );
};

export default RiskDisclaimer;
