import React, { useState } from "react";
import { Container, Row, Col, Tab, Nav, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import IssuerTransaction from "../../components/IssuerTransaction";
import KycExclusionControl from "../../components/KycExclusionControl";
import PageTitle from "../../components/PageTitle";
import style from "../style.module.css";
import { useSelector } from "react-redux";
import { selectDefaultLang, selectNftList } from "../../app/features/extraReducers";
import IssuerNft from "../../Forms/IssuerNft";
import { useTranslation } from "react-i18next";

const IssuerDashboard = () => {
  const lang = useSelector(selectDefaultLang)
  const [selectedItem, setSelectedItem] = useState(null);
  const [t] = useTranslation();

  const nftList = useSelector(selectNftList);
  const navItems = [t("issuer.kycExclusionControl"), t("issuer.transactions")];
  if (nftList && nftList?.length > 0) navItems.push("NFT");

  return (
    <>
      <PageTitle title={t("issuerDashboard")} />
      <div className="pageContent">
        <Tab.Container defaultActiveKey={`0`}>
          <Container>
            <Nav variant="pills" className={`${style.navPills}`}>
              {navItems.map((item, i) => {
                return (
                  <Nav.Item key={i} onClick={() => setSelectedItem(item)}>
                    <Nav.Link eventKey={i} className={`${style.navLink}`}>
                      {item}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}

              <Nav.Item className="ms-auto">
                <Link
                  to={`/${lang}/logout`}
                  className={`${style.logoutBtn} m-0 btn btn-primary d-none d-md-block`}
                >
                  {t("profile.logout")}
                </Link>
              </Nav.Item>
            </Nav>

            <Row className="mt-md-5">
              <Col lg="12">
                {(() => {
                  if (nftList) {
                    switch (selectedItem) {
                      case t("issuer.transactions"):
                        return (
                          <Card className="shadow">
                            <Card.Body>
                              <IssuerTransaction />
                            </Card.Body>
                          </Card>
                        );
                      case "NFT":
                        return <IssuerNft />;
                      default:
                        return (
                          <Card className="shadow">
                            <Card.Body>
                              <KycExclusionControl />
                            </Card.Body>
                          </Card>
                        );
                    }
                  } else {
                    switch (selectedItem) {
                      case t("issuer.transactions"):
                        return (
                          <Card className="shadow">
                            <Card.Body>
                              <IssuerTransaction />
                            </Card.Body>
                          </Card>
                        );
                      default:
                        return (
                          <Card className="shadow">
                            <Card.Body>
                              <KycExclusionControl />
                            </Card.Body>
                          </Card>
                        );
                    }
                  }
                })()}
              </Col>
            </Row>
          </Container>
        </Tab.Container>
      </div>
    </>
  );
};

export default IssuerDashboard;
