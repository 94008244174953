import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useConfirmPaymentMutation } from "../../app/features/apisSlice";
import style from "../../Forms/style.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDefaultLang } from "../../app/features/extraReducers";

const StripeCheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const lang = useSelector(selectDefaultLang)
  const [errorMsg, setErrorMsg] = useState(null);
  const [processing, setProcessing] = useState("");
  const [confirmPayment] = useConfirmPaymentMutation();
  const [t] = useTranslation();

  const sendPaymentStatusData = async (result) => {
    let data = {
      paymentIntent: result?.paymentIntent,
      projectId: props?.data?.id,
      transactionId: props?.data?.transactionId,
      price: props?.data?.investedAmount
    }
    try {
      await confirmPayment(data).unwrap();
      navigate(`/${lang}/payment-success`);
    } catch (err) {
      console.log(`Payment failed: ${err}`)
      setErrorMsg(`${t("error.paymentFailed")}`);
      setProcessing(false);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url: process.env.REACT_APP_SITE_URL+"/payment-status",
        return_url: document.location.origin + "/payment-status",
      },
      redirect: "if_required"
    });
    if (result.error) {
      console.log(`Payment failed: ${result.error.message}`)
      setErrorMsg(`${t("error.paymentFailed")}`);
      setProcessing(false);
    } else {
      sendPaymentStatusData(result)
    }
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement />
      <Button variant="primary" type="submit" className={`mt-3 ${style.btnLogin}`}>
        {t("investNow")}{" "}
        <Spinner
          className={`${!processing ? "d-none" : ""}`}
          variant="light"
          size="sm"
          role="status"
          aria-hidden="true"
          animation="border"
        />
      </Button>
      {errorMsg && (
        <div className="text-danger mt-4" role="alert">
          <b>Payment Failed : </b>{`${errorMsg}`}
        </div>
      )}
    </form>
  );
};

export default StripeCheckoutForm;
