import React from "react";
import { Container } from "react-bootstrap";
import PageTitle from "../components/PageTitle";
import { useGetPrivacyPolicyQuery } from "../app/features/apisSlice";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDefaultLang } from "../app/features/extraReducers";

const PrivacyPolicy = () => {
  const { status, error } = useGetPrivacyPolicyQuery();
  const [t] = useTranslation();

  const lang = useSelector(selectDefaultLang);

  if (status === "pending") return <Loader />;
  return (
    <>
      <PageTitle title={t("footer.privacyPolicy")} />
      <div className="pageContent">
        {lang === "en" && (
          <Container
            dangerouslySetInnerHTML={{ __html: error.data }}
          ></Container>
        )}

        {lang === "it" && (
          <Container>
            <div className="modal-body">
              <strong>
                <span>EDSX GmbH</span>
              </strong>
              <span>
                &nbsp;("EDSX", "noi", "noi stessi") si impegna a proteggere e
                rispettare la tua privacy.
              </span>

              <br />
              <br />
              <p>
                <strong>
                  <span>Ultimo aggiornamento data di effetto:</span>
                </strong>
                <span>&nbsp;16 novembre 2019</span>
              </p>

              <p>
                <span>
                  La presente politica sulla privacy si applica al sito web
                  www.edsx.ch di proprietà e gestito da EDSX Limited (in qualità
                  di titolare del trattamento dei dati). La presente politica
                  sulla privacy descrive come EDSX raccoglie e utilizza i dati
                  personali che fornisci su www.edsx.ch. Descrive inoltre le
                  scelte a tua disposizione riguardo al nostro utilizzo dei tuoi
                  dati personali e come accedervi e aggiornarli (vedi la sezione
                  "I tuoi diritti" per ulteriori dettagli).
                </span>
              </p>

              <p>
                <strong>
                  <span>1. Informazioni che potremmo raccogliere da te</span>
                </strong>
              </p>
              <p>
                <span>
                  Potremmo raccogliere e trattare le seguenti informazioni su di
                  te fornite al momento della richiesta di beni, servizi o
                  informazioni:
                </span>
              </p>

              <p>
                <span>
                  Informazioni che ci fornisci compilando moduli sul nostro sito
                  www.edsx.ch e applicazioni mobili ("Il nostro sito"). Ciò
                  include informazioni di contatto come nome, indirizzo email,
                  indirizzo postale, numero di telefono, copia dei tuoi
                  documenti, informazioni finanziarie come numeri di conto
                  bancario o di brokeraggio, indirizzo del portafoglio,
                  identificativi univoci come username, numero di account,
                  password, data di nascita e preferenze come elenchi preferiti,
                  cronologia delle transazioni, preferenze di marketing. Se
                  scegli di elencare la tua azienda con noi, potremmo chiedere
                  informazioni sulla tua attività come nome dell'azienda,
                  indirizzo del portafoglio, dimensione aziendale, tipo di
                  attività, documenti aziendali e dati personali come un profilo
                  professionale.
                </span>
              </p>

              <p>
                <span>
                  Informazioni che ci fornisci quando ci scrivi (anche via
                  email).
                </span>
              </p>

              <p>
                <span>
                  Informazioni che ci fornisci quando parliamo al telefono.
                  Potremmo realizzare e conservare un resoconto delle
                  informazioni che condividi con noi.
                </span>
              </p>

              <p>
                <span>Informazioni che ci fornisci compilando sondaggi.</span>
              </p>

              <p>
                <span>
                  Dettagli delle transazioni effettuate attraverso il nostro
                  sito e l'adempimento dei tuoi ordini.
                </span>
              </p>

              <p>
                <span>
                  Informazioni ottenute da noi da terze parti in conformità con
                  questa Informativa sulla privacy. Per esempio, se scegli di
                  elencare la tua impresa con noi, potremmo ottenere
                  informazioni aggiuntive da agenzie di valutazione del merito
                  creditizio come fonte supplementare.
                </span>
              </p>

              <p>
                <span>
                  Come è vero per la maggior parte dei siti web, raccogliamo
                  automaticamente alcune informazioni e le archiviamo nei log
                  file. Queste informazioni includono gli indirizzi IP (Internet
                  Protocol), il tipo di browser, il provider di servizi Internet
                  (ISP), le pagine di riferimento/uscita, il sistema operativo,
                  il timestamp e i dati di navigazione. Usiamo queste
                  informazioni per analizzare le tendenze, gestire il nostro
                  sito, tracciare i movimenti degli utenti all'interno del
                  nostro sito e raccogliere dati demografici sulla nostra base
                  utenti nel suo complesso.
                </span>
              </p>

              <p>
                {" "}
                <strong>
                  <span>2. Modalità d'uso delle informazioni</span>
                </strong>
              </p>

              <p>
                <span>
                  Usiamo le informazioni che abbiamo su di te nel seguente modo:
                </span>
              </p>

              <p>
                <span>
                  Per garantire che i contenuti del nostro sito siano presentati
                  nel modo più efficace per te e per il tuo computer. Ciò
                  avviene per i nostri legittimi interessi commerciali.
                </span>
              </p>

              <p>
                <span>
                  Per fornirti informazioni, prodotti o servizi che possano
                  esserti di interesse, dove hai acconsentito a essere
                  contattato a tali scopi.
                </span>
              </p>

              <p>
                <span>
                  Per adempiere agli obblighi derivanti da (i) obblighi legali
                  e/o (ii) eventuali contratti intercorsi tra te e noi per
                  fornire informazioni, prodotti o servizi che hai richiesto e
                  per informarti di eventuali modifiche ai nostri beni e
                  servizi.
                </span>
              </p>

              <p>
                <span>
                  Se sei un membro esistente, possiamo contattarti via posta
                  elettronica, fax o SMS con informazioni su beni e servizi
                  simili a quelli oggetto di una precedente vendita a te. Non ti
                  contatteremo altrimenti via posta elettronica per fornirti
                  informazioni su beni e servizi che possano interessarti, a
                  meno che tu non abbia acconsentito a questo. Se non vuoi che
                  utilizziamo i tuoi dati in una o più delle modalità menzionate
                  sopra, faccelo sapere contattandoci all'indirizzo info@edsx.ch
                  o facendo riferimento alle istruzioni aggiuntive nella sezione
                  "I tuoi diritti" della presente politica.
                </span>
              </p>

              <p>
                <strong>
                  <span>3. Divulgazione delle tue informazioni</span>
                </strong>
              </p>

              <p>
                <span>
                  Condivideremo i tuoi dati personali con terze parti solo nei
                  modi descritti in questa politica sulla privacy. Non vendiamo
                  i tuoi dati personali a terzi. Possiamo divulgare i tuoi dati
                  personali a qualsiasi membro del nostro gruppo, il che
                  significa le nostre controllate, la nostra società madre
                  ultima e le sue controllate. Inoltre, possiamo divulgare i
                  tuoi dati personali a terzi:
                </span>
              </p>

              <p>
                <span>
                  Nel caso in cui vendiamo o acquistiamo un'attività o beni, nel
                  qual caso potremmo divulgare i tuoi dati personali al
                  potenziale venditore o acquirente di tale attività o beni. Ti
                  verrà notificato via email e/o con un avviso prominente sul
                  nostro sito web di qualsiasi modifica della proprietà o degli
                  usi dei tuoi dati personali, nonché di eventuali scelte che
                  potresti avere riguardo ai tuoi dati personali.
                </span>
              </p>

              <p>
                <span>
                  Se EDSX o sostanzialmente tutti i suoi asset venissero
                  acquisiti da un terzo, nel qual caso i dati personali detenuti
                  sui membri verrebbero trasferiti come uno degli asset
                  trasferiti.
                </span>
              </p>

              <p>
                <span>
                  Se siamo, o riteniamo in buona fede di essere, sotto l'obbligo
                  di divulgare o condividere i tuoi dati personali al fine di
                  rispettare un obbligo legale; o è consentito per legge al fine
                  di far rispettare o applicare i nostri Termini e Condizioni di
                  utilizzo del sito web e altri accordi; o per proteggere i
                  diritti, i beni o la sicurezza di noi, dei nostri membri o di
                  altri. Ciò include lo scambio di informazioni con altre
                  società e organizzazioni a fini di prevenzione delle frodi e
                  riduzione del rischio di credito.
                </span>
              </p>

              <p>
                <span>
                  Se hai effettuato un investimento o hai presentato domanda di
                  investimento in una società tramite www.edsx.ch o tramite la
                  pagina di destinazione di un emittente registrato in EDSX, i
                  tuoi dati personali possono essere divulgati alla società (o
                  ai rappresentanti professionali della società) in cui hai
                  investito o hai presentato domanda di investimento. Se
                  richiedi un business plan, segui una presentazione o contatti
                  un'azienda o un imprenditore tramite una pagina di
                  presentazione, i tuoi dati di contatto possono anche essere
                  condivisi con quell'azienda o quell'imprenditore.
                </span>
              </p>

              <p>
                <span>
                  Potremmo fornire i tuoi dati personali alle società che
                  forniscono servizi per aiutarci nelle nostre attività
                  aziendali come la spedizione del tuo ordine, l'elaborazione
                  dei pagamenti, l'offerta di servizi per i membri o per
                  migliorare le nostre operazioni aziendali. Queste società sono
                  autorizzate ad utilizzare i tuoi dati personali solo nella
                  misura necessaria per fornire questi servizi per nostro conto.
                  Non divulghiamo informazioni su individui identificabili ai
                  nostri inserzionisti, ma possiamo fornire loro informazioni
                  aggregate sui nostri utenti (ad esempio, possiamo informarli
                  che 500 uomini di età inferiore a 30 anni hanno cliccato sul
                  loro annuncio in un determinato giorno). Possiamo anche
                  utilizzare tali informazioni aggregate per aiutare gli
                  inserzionisti a raggiungere il tipo di pubblico che desiderano
                  raggiungere (ad esempio, donne in SW1). Possiamo utilizzare i
                  dati personali che abbiamo raccolto da te per consentirci di
                  soddisfare i desideri dei nostri inserzionisti visualizzando i
                  loro annunci a quel pubblico di destinazione.
                </span>
              </p>

              <p>
                <strong>
                  <span>4. I tuoi diritti</span>
                </strong>
              </p>

              <p>
                <span>
                  Hai il diritto di accedere alle informazioni che ti
                  riguardano. Per proteggere la tua privacy e sicurezza,
                  adotteremo misure ragionevoli per verificare la tua identità
                  prima di fornire copie di eventuali materiali rilevanti.
                  Potresti avere il diritto di chiedere:
                </span>
              </p>

              <p>
                <span>una copia delle tue informazioni;</span>
              </p>
              <p>
                <span>di correggere o cancellare le tue informazioni;</span>
              </p>
              <p>
                <span>
                  di limitare o interrompere l'elaborazione delle informazioni;
                </span>
              </p>
              <p>
                <span>
                  di trasferire alcune di queste informazioni ad altre
                  organizzazioni; e
                </span>
              </p>
              <p>
                <span>
                  dove abbiamo chiesto il tuo consenso per elaborare i tuoi
                  dati, di revocare questo consenso.
                </span>
              </p>

              <p>
                <span>
                  Questi diritti possono essere limitati in alcune situazioni -
                  ad esempio, dove possiamo dimostrare di avere un obbligo
                  legale di elaborare i tuoi dati. In alcuni casi, ciò può
                  significare che siamo in grado di conservare i dati anche se
                  revochi il consenso. Dove richiediamo informazioni per
                  rispettare obblighi legali o contrattuali, allora la fornitura
                  di tali dati è obbligatoria: se tali informazioni non vengono
                  fornite, non saremo in grado di soddisfare gli obblighi posti
                  su di noi o di gestire le tue transazioni sul nostro sito. In
                  tutti gli altri casi, la fornitura delle informazioni
                  richieste è facoltativa. Speriamo di poter soddisfare
                  eventuali domande che potresti avere sul modo in cui
                  elaboriamo le tue informazioni e risolvere eventuali reclami.
                  Ti incoraggiamo a rivolgerti a noi in primo luogo, ma hai il
                  diritto di lamentarti direttamente all'autorità garante
                  competente in materia di protezione dei dati.
                </span>
              </p>

              <p>
                <strong>
                  <span>5. Come esercitare i tuoi diritti</span>
                </strong>
              </p>

              <p>
                <span>
                  Puoi correggere, modificare o cancellare le tue informazioni
                  nella pagina Impostazioni account utente. Puoi smettere di
                  ricevere le nostre newsletter o email di marketing seguendo le
                  istruzioni di annullamento della sottoscrizione incluse in
                  queste email o accedendo alle preferenze email nella pagina
                  delle impostazioni del tuo account. In alternativa, o per
                  accedere a qualsiasi altro dei diritti sopra elencati, puoi
                  inviare un'email al nostro Supporto Utenti a info@edsx.ch o
                  contattarci telefonicamente o via posta all'indirizzo
                  riportato di seguito.
                </span>
              </p>

              <p>
                <strong>
                  <span>
                    6. Per quanto tempo conserviamo le tue informazioni
                  </span>
                </strong>
              </p>

              <p>
                <span>
                  Conserveremo le tue informazioni finché il tuo account sarà
                  attivo o per il tempo necessario a fornirti i servizi.
                  Conservaremo e utilizzeremo le tue informazioni nella misura
                  necessaria per conformarci ai nostri obblighi legali,
                  risolvere controversie ed applicare i nostri accordi, che in
                  alcuni casi che implicano la raccolta e l'elaborazione di dati
                  finanziari possono richiedere un periodo di conservazione di 7
                  anni (o più a lungo se richiesto dalla legge).
                </span>
              </p>

              <p>
                <strong>
                  <span>7. Cookies e tecnologie di tracciamento</span>
                </strong>
              </p>

              <p>
                <span>
                  EDSX GmbH e i nostri partner, tra cui partner di marketing,
                  affiliate o fornitori di servizi di analisi e gestione dei
                  processi aziendali, utilizzano tecnologie come: cookies,
                  pixel, tag e script.
                </span>
              </p>

              <p>
                <span>
                  I cookies possono essere memorizzati per periodi di tempo
                  variabili sul browser o dispositivo. Esistono diversi tipi di
                  cookies. Un'importante classificazione dei cookies
                  (soprattutto per quanto riguarda il consenso) sono i cookies
                  essenziali e non essenziali:
                </span>
              </p>

              <p>
                <span>
                  Cookies essenziali: utilizzati esclusivamente per eseguire o
                  facilitare la trasmissione di comunicazioni su una rete; o
                  strettamente necessari per fornire un servizio online (ad es.
                  il nostro sito web o un servizio sul nostro sito web) che hai
                  richiesto. Senza questi cookies, l'interazione del tuo
                  dispositivo con il nostro Sito Web non potrebbe aver luogo.
                  Questi cookies non richiedono l'approvazione.
                </span>
              </p>

              <p>
                <span>
                  Cookies di prestazione (non essenziali). Il cui posizionamento
                  sul tuo dispositivo è possibile solo previo consenso da parte
                  tua. Puoi esprimere il tuo consenso premendo il pulsante
                  "Accetta" nella barra dei cookie emergente in alto/basso della
                  pagina web del Sito. I nostri cookies di prestazione
                  raccolgono informazioni anonime su come utilizzi questo sito
                  web e le sue funzionalità. Ad esempio, i nostri cookies di
                  prestazione raccolgono informazioni su quali pagine di questo
                  sito web visiti più spesso, se apri o leggi le comunicazioni
                  che ti inviamo, quali annunci visualizzi o con cui interagisci
                  su questo sito web o altri siti web su cui compaiono i nostri
                  annunci, e se ricevi eventuali messaggi di errore.
                </span>
              </p>

              <p>
                <span>
                  Cookies funzionali (non essenziali): consentono a un sito web
                  di memorizzare informazioni già inserite (come nome utente,
                  lingua o posizione corrente) e offrire all'utente funzionalità
                  migliori e più personalizzate. Ad esempio, un sito web può
                  offrirti informazioni fiscali locali e pertinenti al prezzo se
                  utilizza un cookie per ricordare la regione in cui ti trovi
                  attualmente. I cookies funzionali vengono anche utilizzati per
                  abilitare le funzionalità che richiedi come la riproduzione di
                  video. Questi cookies raccolgono informazioni anonime e non
                  possono tracciare i tuoi spostamenti su altri siti web.
                </span>
              </p>

              <p>
                <span>
                  Cookies di marketing (non essenziali): Vengono utilizzati per
                  fornire annunci e altre comunicazioni più rilevanti per te e i
                  tuoi interessi. Vengono anche utilizzati per limitare il
                  numero di volte che vieni visualizzato un annuncio e per
                  aiutare a misurare l'efficacia delle campagne pubblicitarie.
                  Ricordano se hai visitato o meno un sito web e queste
                  informazioni possono essere condivise con altre organizzazioni
                  come gli inserzionisti. I cookies per migliorare il targeting
                  di gruppo e la pubblicità saranno spesso collegati alle
                  funzionalità del sito fornite da altre organizzazioni.
                </span>
              </p>

              <p>
                <span>
                  Altre tecnologie di tracciamento (non essenziali): Alcuni siti
                  utilizzano cose come web beacon, GIF chiari, tag di pagina e
                  web bug per capire come le persone li stanno utilizzando e
                  indirizzare la pubblicità a loro. Si presentano tipicamente
                  come una piccola immagine trasparente incorporata in una
                  pagina web o e-mail. Funzionano con i cookies e raccolgono
                  dati come il tuo indirizzo IP, quando hai visualizzato la
                  pagina o la e-mail, il dispositivo che stavi utilizzando e
                  dove ti trovavi. Puoi scoprire come evitarli nella sezione 4
                  della presente informativa sui cookie.
                </span>
              </p>

              <p>
                <span>
                  Queste tecnologie vengono utilizzate per l'analisi delle
                  tendenze, la gestione del nostro Sito, il tracciamento dei
                  movimenti degli utenti all'interno del nostro Sito e per
                  raccogliere informazioni demografiche sulla nostra intera base
                  di utenti. Possiamo ricevere rapporti basati sull'utilizzo di
                  queste tecnologie da parte di queste società su base
                  individuale e aggregata. Usiamo i cookie per stimare le
                  dimensioni del nostro pubblico e i modelli di utilizzo; per
                  memorizzare informazioni sulle tue preferenze e consentirci
                  così di personalizzare il nostro Sito in base ai tuoi
                  interessi individuali; per velocizzare le tue ricerche e
                  riconoscerti quando tornerai al nostro Sito. Gli utenti
                  possono controllare l'utilizzo dei cookie a livello
                  individuale di browser. Se rifiuti i cookie, potrai comunque
                  utilizzare il nostro Sito, ma la tua capacità di utilizzare
                  alcune funzionalità o aree del nostro Sito potrebbe essere
                  limitata. Collaboriamo con reti pubblicitarie di terze parti
                  per visualizzare annunci pubblicitari sul nostro Sito o per
                  gestire la nostra pubblicità su altri siti. Il nostro partner
                  di rete pubblicitaria utilizza cookie e web beacon per
                  raccogliere dati non personali sulle tue attività su questo e
                  altri siti Web per fornirti annunci pubblicitari
                  personalizzati in base ai tuoi interessi.
                </span>
              </p>

              <p>
                <span>
                  Inoltre, utilizziamo un servizio di pubblicazione di annunci
                  per visualizzare annunci di terze parti sul nostro Sito.
                  Quando visualizzi o fai clic su un annuncio, verrà impostato
                  un cookie per aiutare a fornire meglio annunci che possono
                  esserti di interesse su questo e altri siti Web. Puoi optare
                  out dall'uso di questo cookie visitando i sopraccitati gestori
                  delle preferenze di tracciamento. Oggetti di archiviazione
                  locale (Flash/HTML5): I terzi con cui collaboriamo per fornire
                  determinate funzionalità sul nostro Sito o per visualizzare
                  annunci pubblicitari in base alla tua attività di navigazione
                  sul Web utilizzano LSO come HTML5 o Flash per raccogliere e
                  archiviare informazioni. Vari browser potrebbero offrire
                  propri strumenti di gestione per la rimozione degli LSO HTML5.
                  Per gestire gli LSO Flash, fai clic qui.
                </span>
              </p>

              <p>
                <strong>
                  <span>
                    7.1 Disabilitare/abilitare i cookie, web beacons e altre
                    tecnologie di tracciamento tramite il browser
                  </span>
                </strong>
              </p>

              <p>
                <span>
                  Dovresti essere consapevole che eventuali preferenze andranno
                  perse se cancelli tutti i cookie e molti siti web non
                  funzioneranno correttamente o perderai alcune funzionalità.
                  Non ti consigliamo di disattivare i cookie quando utilizzi il
                  nostro sito web per questi motivi.
                </span>
              </p>

              <p>
                <span>
                  Hai la possibilità di accettare o rifiutare i cookie da
                  qualsiasi sito web modificando le impostazioni del tuo
                  browser. Se desideri limitare o bloccare i cookie impostati
                  dal nostro sito web, puoi farlo attraverso le impostazioni del
                  tuo browser. Per informazioni su come gestire e disabilitare i
                  cookie, puoi utilizzare la funzione di 'Aiuto' all'interno del
                  tuo browser o visitare www.aboutcookies.org o
                  www.allaboutcookies.org.
                </span>
              </p>

              <p>
                <span>
                  Poiché rispettiamo il tuo diritto alla privacy, puoi scegliere
                  di non consentire alcuni tipi di cookie che raccogliamo sul
                  nostro sito web. Fai clic sui diversi titoli di categoria per
                  saperne di più e modificare le impostazioni predefinite
                  (interruttore On-off). Tuttavia, bloccare alcuni tipi di
                  cookie può influire sulla tua esperienza del sito e sui
                  servizi che siamo in grado di offrire.
                </span>
              </p>

              <p>
                <u>
                  <span>
                    Per disabilitare direttamente i tipi di cookie e evitare web
                    beacon e altre tecnologie di tracciamento, puoi renderli
                    inefficaci disattivando i cookie nel tuo browser.
                  </span>
                </u>
              </p>

              <p>
                <span>
                  Chrome:
                  https://support.google.com/chrome/answer/95647?hl=it&amp;co=GENIE.Platform=Desktop
                </span>
              </p>

              <p>
                <span>
                  Internet Explorer:
                  https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies
                </span>
              </p>

              <p>
                <span>
                  Opera: https://help.opera.com/it/latest/web-preferences/
                </span>
              </p>

              <p>
                <span>Safari: https://support.apple.com/it-it/HT201265</span>
              </p>

              <p>
                <span>
                  Firefox:
                  https://support.mozilla.org/it/kb/abilitare-e-disabilitare-i-cookie-preferenze-siti
                </span>
              </p>

              <p>
                <span>
                  Se utilizzi un browser web non elencato sopra, consulta la
                  documentazione o la guida online del tuo browser per ulteriori
                  informazioni.
                </span>
              </p>

              <p>
                <strong>
                  <span>
                    7. Modalità di memorizzazione, elaborazione e protezione
                    delle tue informazioni
                  </span>
                </strong>
              </p>

              <p>
                <span>
                  I dati che raccogliamo da te possono essere trasferiti e
                  archiviati in una destinazione all'interno o all'esterno della
                  Svizzera o dell'Area Economica Europea ("EEA"). Potrebbero
                  anche essere elaborati da personale che opera all'interno o
                  all'esterno della Svizzera o dell'EEA che lavora per noi o per
                  uno dei nostri fornitori. Tale personale potrebbe occuparsi,
                  tra le altre cose, dell'evasione dei tuoi ordini,
                  dell'elaborazione dei tuoi dettagli di pagamento e della
                  fornitura di servizi di supporto. L'accesso alle tue
                  informazioni è limitato a coloro che ne hanno bisogno per
                  gestirle. Quando le tue informazioni sono trasferite, ci
                  assicureremo che siano adeguatamente protette garantendo (i)
                  la presenza di clausole contrattuali standard approvate dalla
                  Commissione UE, una certificazione Privacy Shield appropriata
                  o obblighi giuridici vincolanti per le aziende del fornitore o
                  (ii) facendo affidamento su deroghe (ad es. necessità
                  contrattuale).
                </span>
              </p>

              <ul>
                <li>
                  <span>9.&nbsp;</span>
                  <strong>
                    <span>Sicurezza</span>
                  </strong>
                </li>
              </ul>

              <p>
                <span>
                  Tutte le informazioni che ci fornisci via e-mail o sul nostro
                  sito Web sono memorizzate sui nostri server sicuri. Dove ti
                  abbiamo fornito (o hai scelto) una password che ti consente di
                  accedere a determinate parti del nostro sito, sei responsabile
                  della riservatezza di questa password. Ti chiediamo di non
                  condividerla con nessuno. La sicurezza dei tuoi dati personali
                  è importante per noi. Adottiamo misure tecniche e
                  organizzative ragionevoli per prevenire la perdita, l'uso
                  improprio o la cancellazione dei tuoi dati personali.
                  Memorizziamo tutte le informazioni personali che ci fornisci
                  su server protetti da password accessibili solo a diversi
                  dipendenti autorizzati. Quando utilizzi un modulo sul nostro
                  sito per inviarci dati, questa trasmissione avviene solo
                  attraverso una connessione TLS crittografata. Le tue
                  informazioni sono memorizzate ed crittografate utilizzando il
                  certificato SSL / TLS sul sito. Tuttavia, nessun metodo di
                  trasmissione o memorizzazione elettronica su Internet è del
                  100% sicuro. Pertanto, non possiamo garantirne l'assoluta
                  sicurezza. Non possiamo assumerci la responsabilità dei siti
                  di terze parti a cui il sito è collegato o delle loro
                  politiche. Se fai clic su un collegamento a un sito di terze
                  parti, leggi attentamente la politica sulla privacy dell'altro
                  sito e scegli se è appropriato utilizzarlo. Se hai domande
                  sulla sicurezza del nostro sito, puoi contattarci
                  all'indirizzo info@edsx.ch.
                </span>
              </p>

              <ul>
                <li>
                  <span>10.&nbsp;</span>
                  <strong>
                    <span>Audit di sicurezza</span>
                  </strong>
                </li>
              </ul>

              <p>
                <span>
                  Utilizziamo società di terze parti riconosciute e accreditate
                  per eseguire regolari test di penetrazione della nostra
                  piattaforma e anche delle reti interne dei nostri
                  uffici.&nbsp;
                </span>
                <span>
                  Eventuali output sono rivisti e prioritizzati con elevata
                  importanza.
                </span>
              </p>

              <ul>
                <li>
                  <span>11.&nbsp;</span>
                  <strong>
                    <span>Sicurezza di terze parti</span>
                  </strong>
                </li>
              </ul>

              <p>
                <span>
                  Come molte aziende, utilizziamo determinati terzi per
                  supportare i servizi che forniamo ai nostri utenti. Rivediamo
                  costantemente la sicurezza e l'idoneità di eventuali terzi
                  nell'ambito dei nostri processi in corso.
                </span>
              </p>

              <ul>
                <li>
                  <span>12.&nbsp;</span>
                  <strong>
                    <span>Formazione del personale</span>
                  </strong>
                </li>
              </ul>

              <p>
                <span>
                  Ai dipendenti EDSX è richiesto di seguire corsi di formazione
                  sulla cybersecurity e di riconoscere e conformarsi ai processi
                  di sicurezza interni relativi a hardware e software. Questi
                  vengono riesaminati e aggiornati regolarmente.
                </span>
              </p>

              <p>
                <strong>
                  <span>13. Ulteriori informazioni sulla privacy</span>
                </strong>
              </p>
              <p>
                <strong>
                  <span>13.1 Blog/Discussioni</span>
                </strong>
              </p>

              <p>
                <span>
                  Il nostro sito offre un blog pubblicamente accessibile e
                  discussioni di comunità. Dovresti essere consapevole che
                  qualsiasi informazione fornita in queste aree può essere
                  letta, raccolta e utilizzata da altri che vi accedono. Per
                  richiedere la rimozione dei tuoi dati personali dal nostro
                  blog o dalle discussioni della comunità, contattaci
                  all'indirizzo info@edsx.ch. In alcuni casi, potremmo non
                  essere in grado di rimuovere i tuoi dati personali, nel qual
                  caso ti faremo sapere se non siamo in grado di farlo e il
                  motivo.
                </span>
              </p>

              <p>
                <strong>
                  <span>13.2 Segnalazioni</span>
                </strong>
              </p>

              <p>
                <span>
                  Se scegli di utilizzare il nostro servizio di segnalazione per
                  informare un contatto sul nostro sito, ti chiederemo il nome e
                  l'indirizzo email del contatto. Devi avere il consenso del
                  contatto a fornire queste informazioni. Invieremo
                  automaticamente al tuo contatto una singola email di invito a
                  visitare il sito. EDSX memorizza queste informazioni
                  esclusivamente per l'invio di questa singola email e il
                  tracciamento del successo del nostro programma di
                  segnalazione. Il tuo contatto può contattarci all'indirizzo
                  info@edsx.ch per richiedere la rimozione di queste
                  informazioni dal nostro database.
                </span>
              </p>

              <p>
                <strong>
                  <span>13.3 Collegamenti a siti di terze parti</span>
                </strong>
              </p>

              <p>
                <span>
                  Il nostro sito può contenere occasionalmente collegamenti ai
                  siti web dei nostri fornitori, reti partner, inserzionisti,
                  affiliati e altre terze parti. Se segui un collegamento a
                  questi siti, nota che questi siti dovrebbero avere le proprie
                  politiche sulla privacy e non accettiamo alcuna responsabilità
                  o responsabilità per tali politiche o per il contenuto o il
                  funzionamento di questi siti. Verifica queste politiche e i
                  termini dei siti prima di inviare qualsiasi dato personale a
                  questi siti.
                </span>
              </p>

              <p>
                <strong>
                  <span>13.4 Directory dei membri</span>
                </strong>
              </p>

              <p>
                <span>
                  Potremmo elencarti nella nostra directory dei membri
                  pubblicamente accessibile. Se desideri richiedere la rimozione
                  delle tue informazioni o la cancellazione di un profilo non
                  autorizzato dalla nostra directory, puoi contattarci
                  all'indirizzo info@edsx.ch.
                </span>
              </p>

              <p>
                <strong>
                  <span>13.5 Profili</span>
                </strong>
              </p>

              <p>
                <span>
                  Il profilo che crei sul nostro sito sarà pubblicamente
                  accessibile a meno che non sia indicato diversamente. Puoi
                  modificare le impostazioni di privacy del tuo profilo
                  attraverso il tuo portale account.
                </span>
              </p>

              <p>
                <strong>
                  <span>13.6 Widget di Social Media</span>
                </strong>
              </p>

              <p>
                <span>
                  Il nostro sito include funzionalità di Social Media, come il
                  pulsante "Mi piace" di Facebook. Queste funzioni potrebbero
                  raccogliere il tuo indirizzo IP, la pagina che stai visitando
                  sul nostro sito e potrebbero impostare un cookie per
                  consentire il corretto funzionamento della funzione. Le
                  funzionalità e i widget dei social media sono ospitati da
                  terze parti o ospitati direttamente sul nostro sito. Le tue
                  interazioni con queste funzioni sono regolate dalla politica
                  sulla privacy della società che le fornisce.
                </span>
              </p>

              <p>
                <strong>
                  <span>13.7 Accesso unico</span>
                </strong>
              </p>

              <p>
                <span>
                  Puoi accedere al nostro sito utilizzando il servizio di
                  accesso come LinkedIn, Facebook e Google. Questi servizi
                  autenticheranno la tua identità e ti daranno la possibilità di
                  condividere alcuni dati personali come il tuo nome e indirizzo
                  email con noi per precompilare il nostro modulo di
                  registrazione. Servizi come LinkedIn, Facebook e Google
                  potrebbero darti la possibilità di pubblicare informazioni
                  sulle tue attività sul nostro sito sul tuo profilo per
                  condividerle con altri nella tua rete.
                </span>
              </p>

              <p>
                <strong>
                  <span>13.8 Modifiche alla nostra politica sulla privacy</span>
                </strong>
              </p>

              <p>
                <span>
                  Potremmo aggiornare questa politica sulla privacy per
                  riflettere le modifiche alle nostre pratiche in materia di
                  informazioni. Se apportiamo modifiche sostanziali, te ne
                  informeremo via email (inviata all'indirizzo specificato nel
                  tuo account) o mediante un avviso sul nostro sito prima che la
                  modifica entri in vigore. Ti invitiamo a rivedere
                  periodicamente questa pagina per le ultime informazioni sulle
                  nostre pratiche in materia di privacy.
                </span>
              </p>
            </div>
          </Container>
        )}
      </div>
    </>
  );
};

export default PrivacyPolicy;
