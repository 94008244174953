export const BeNiceNumber = (number) => {
  if (number >= 1000000000000) {
    number = number / 1000000000000;
    return `${number.toFixed(2)}tln`;
  } else if (number >= 1000000000) {
    number = number / 1000000000;
    return `${number.toFixed(2)}bln`;
  } else if (number >= 1000000) {
    number = number / 1000000;
    return `${number.toFixed(2)}mln`;
  } else if (number >= 1000) {
    number = number / 1000;
    return `${number.toFixed(2)}k`;
  } else {
    return `${number.toFixed(2)}`;
  }
};

export const BeNiceNumberWithCurrency = (number) => {
  if (number >= 1000000000000) {
    number = number / 1000000000000;
    return `€${number.toFixed(2)}tln`;
  } else if (number >= 1000000000) {
    number = number / 1000000000;
    return `€${number.toFixed(2)}bln`;
  } else if (number >= 1000000) {
    number = number / 1000000;
    return `€${number.toFixed(2)}mln`;
  } else if (number >= 1000) {
    number = number / 1000;
    return `€${number.toFixed(2)}k`;
  } else {
    return `€${number.toFixed(2)}`;
  }
};
