import React, { useState } from "react";
import { Card, Col, Button, Form, Container, Row, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Reaptcha from "reaptcha";
import { useForgotPasswordMutation } from "../app/features/auth/authApiSlice";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDefaultLang } from "../app/features/extraReducers";

const ForgotPassword = () => {
  const lang = useSelector(selectDefaultLang);
  const [captcha, setCaptcha] = useState(null);
  const [forgotPassword] = useForgotPasswordMutation();
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false);
  const [t] = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = { email: event.target.elements.email.value, language: lang };
    try {
      const response = await forgotPassword(data).unwrap();
      setSuccessMsg(response?.msg);
      setErrMsg(null);
      event.target.reset();
      captcha.reset();
    } catch (err) {
      if (err?.data?.error) setErrMsg(err?.data?.error);
      else if (err?.data?.msg) setErrMsg(err?.data?.msg);
      else setErrMsg(t("error.noServerResponse"));
      setSuccessMsg(null);
    }
  };

  return (
    <Container>
      <Row className="pt-5 pb-5 justify-content-center">
        <Col lg="5" md="8" sm="12">
          <Card className={`${style.loginFormContainer}`}>
            <Card.Header
              className={`m-3 pb-2 text-primary ${style.title} ${style.resetPasswordTitle}`}
            >
              {t("forgotPassword")}?
            </Card.Header>
            <Card.Body>
              {successMsg && (
                <p className="text-success text-center" aria-live="assertive">
                  {successMsg}
                </p>
              )}
              {errMsg && (
                <p className="text-danger text-center" aria-live="assertive">
                  {errMsg}
                </p>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label className={`${style.formLabel}`}>
                    {t("error.enterYourEmailAddressBelowToResetYourPassword")}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={t("enterEmail")}
                    className={`${style.formControl}`}
                    name="email"
                    required
                  />
                </Form.Group>

                <Reaptcha
                  ref={(e) => setCaptcha(e)}
                  sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                  onVerify={() => {
                    setVerifiedCaptcha(true);
                  }}
                  onExpire={() => {
                    setVerifiedCaptcha(false);
                  }}
                />

                <Button
                  variant="primary"
                  type="submit"
                  className={`mt-3 ${style.btnLogin}`}
                  disabled={!verifiedCaptcha}
                >
                  {t("submit")}
                </Button>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Nav className="justify-content-between">
                <Link to={`/${lang}/login`} className="text-danger nav-link">
                  {t("loginNow")}?
                </Link>
                <Link
                  to={`/${lang}/signup`}
                  className="ms-sm-auto text-primary nav-link"
                >
                  {t("createAccount")}
                </Link>
              </Nav>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
