import React, { useRef } from "react";
import { Card, Row, Col, Table, Button, Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import {
  useGetOpenOrdersQuery,
  useCancelTradeOrderMutation,
} from "../app/features/secondaryMarketApiSlice";
import ErrorPage from "./ErrorPage";
import Loader from "./Loader";
import { BeNiceNumberWithCurrency, BeNiceNumber } from "../helpers/CustomHelper";
import { useTranslation } from "react-i18next";

const OpenOrders = () => {
  const timestampRef = useRef(Date.now()).current;
  const { data, isLoading, isSuccess, isError, error, refetch } = useGetOpenOrdersQuery({
    sessionId: timestampRef,
  });
  const [t] = useTranslation()
  const tHead = [t("order.iD"), t("order.type"), t("order.date"), t("order.quantity"), t("order.price"), t("order.value"), ""];

  const handleTradeOrder = (id) => {
    handleConfirmAlert(function (confirmed) {
      if (confirmed) handleCancelOrder(id);
    });
  };

  const handleConfirmAlert = async (callback) => {
    await Swal.fire({
      title: t("order.areYouSure"),
      html: `${t("youWant")} <b>${t("cancel")}</b> ${t("thisOrder")}?`,
      icon: "question",
      showCancelButton: true,
      cancelButtonText: t("no"),
      confirmButtonColor: "#198754",
      cancelButtonColor: "#d33",
      confirmButtonText: t("yes"),
    }).then((result) => {
      if (result.isConfirmed) callback(result.isConfirmed);
    });
  };

  const [cancelTradeOrder] = useCancelTradeOrderMutation();
  const handleCancelOrder = async (id) => {
    try {
      const response = await cancelTradeOrder({ orderBookId: id });
      console.log(response)
      refetch();
      Swal.fire(t("order.orderCanceledSuccessfully"), "", "success");
    } catch (error) {
      Swal.fire(t("order.unableToCancel"), t("order.gettingSomeError"), "error");
    }
  };

  if (isLoading) return <Loader />;
  if (isError) return <ErrorPage error={error} />;
  if (isSuccess)
    return (
      <Row>
        <Col md="12" className="mt-3 mb-3">
          <h5>{t("order.yourOpenOrders")}</h5>
          <Card className="shadow">
            <Card.Body>
              <Table hover striped responsive>
                <thead>
                  <tr>
                    {tHead.map((head, index) => (
                      <th key={index}>{head}</th>
                    ))}
                  </tr>
                </thead>
                {(() => {
                  if (data.rows.length > 0) {
                    return (
                      <tbody>
                        {data.rows.map((row, index) => (
                          <tr key={index}>
                            <td>#{row.id}</td>
                            <td>
                              <h6>
                                <Badge
                                  pill
                                  bg={
                                    row.order_type === "buy"
                                      ? "success"
                                      : "danger"
                                  }
                                >
                                  {row.order_type}
                                </Badge>
                              </h6>
                            </td>
                            <td>{new Date(row.executedAt).toDateString()}</td>
                            <td>{BeNiceNumber(row.quantity)}</td>
                            <td>{BeNiceNumberWithCurrency(row.price)}</td>
                            <td>{BeNiceNumberWithCurrency(row.quantity)}</td>
                            <td className="text-end">
                              <Button
                                className="btn btn-primary btn-sm m-0"
                                onClick={() => handleTradeOrder(row.id)}
                              >
                                {t("cancel")}
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    );
                  } else {
                    return (
                      <tbody>
                        <tr>
                          <td colSpan={7} className="text-center">
                            {t("order.noRecordFound")}
                          </td>
                        </tr>
                      </tbody>
                    );
                  }
                })()}
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
};

export default OpenOrders;
