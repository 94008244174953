import React, { useState, useEffect } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useUserNftMutation } from "../app/features/apisSlice";
import { setNftTransfer, setTradeData } from "../app/features/extraReducers";
import style from "../components/style.module.css";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";

const UserNft = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userNft] = useUserNftMutation();
  const [nftData, setNftData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation();

  const handleUserNFT = async () => {
    setIsLoading(true);
    try {
      const response = await userNft();
      if (response.error) setNftData(response.error);
      else setNftData(response.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const HandleTransferPage = (data) => {
    dispatch(setNftTransfer(data));
    dispatch(
      setTradeData({
        from: data.eosAccountName,
        to: "",
        asset_id: data.asset_id,
        memo: "",
        actorName: data.actorName,
      })
    );
    navigate({
      pathname: "/en/transfer",
      // state: { data }
    });
  };

  useEffect(() => {
    handleUserNFT();
    // eslint-disable-next-line
  }, [userNft, navigate]);

  if (isLoading) return <Loader />;

  return (
    <>
      {(() => {
        if (nftData.status === 400) {
          return <h1>{t("nft.noNftFound")}</h1>;
        } else {
          return (
            <Row xs={1} md={3} lg={6}>
              {nftData.map((data, index) => (
                <Col className="mb-3" key={index}>
                  <Card className={`mb-3 ${style.projectCard}`}>
                    <div className={`${style.nftImgContainer}`}>
                      <Card.Img
                        alt={data.asset_id}
                        variant="top"
                        src={data.mutable_data[0]}
                        className={`${style.projectImage}`}
                      />
                    </div>
                    <hr className="mt-0" />
                    <Card.Body
                      className={`p-0 pt-0 text-center ${style.projectCardBody}`}
                    >
                      <Card.Text>
                        <strong> {t("nft.assetID")} : {data.asset_id} </strong>
                      </Card.Text>
                      <Button
                        size="sm"
                        className="m-0 mb-1"
                        variant="primary"
                        target="_blank"
                        href={data?.mutable_data[1]}
                      >
                        {t("nft.giaReports")}
                      </Button>{" "}
                      <Button
                        size="sm"
                        className="m-0 mb-1"
                        variant="primary"
                        href={data?.mutable_data[2]}
                      >
                        {t("nft.downloadZIP")}
                      </Button>{" "}
                      <Button
                        size="sm"
                        className="m-0 mb-1"
                        variant="primary"
                        onClick={() => HandleTransferPage(data)}
                      >
                        {t("nft.transfer")}
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          );
        }
      })()}
    </>
  );
};

export default UserNft;
