import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Form,
  Nav,
  Spinner,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../app/features/auth/authSlice";
import {
  useLoginMutation,
  useGoogleLoginMutation,
  useFbLoginMutation,
} from "../app/features/auth/authApiSlice";
import { isLogged } from "../app/features/auth/authSlice";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { selectDefaultLang } from "../app/features/extraReducers";
import Loader from "../components/Loader";

const Login = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const token = queryParams.get("token");
  const provider = queryParams.get("provider");

  const errRef = useRef();
  const navigate = useNavigate();
  const lang = useSelector(selectDefaultLang);
  const isLoggedIn = useSelector(isLogged);
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const [googleLogin] = useGoogleLoginMutation();
  const [fbLogin] = useFbLoginMutation();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [t] = useTranslation();

  useEffect(() => {
    setErrMsg("");
    if (isLoggedIn) {
      window.location.href = `/${lang}/profile`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let userData = await login({
        email: email.trim(),
        password,
        language : lang
      }).unwrap();
      const data = { user: email, accessToken: userData.token };
      dispatch(setCredentials({ data }));
      setEmail("");
      setPassword("");
      if (window.self !== window.top) {
        const URL = localStorage.getItem("EDSXFrame");
        window.location.href = `https://app.edsx.ch/project${URL}`;
      } else {
        navigate(`/${lang}/profile`);
      }
    } catch (err) {
      if (!err) setErrMsg(t("error.noServerResponse"));
      else if (err?.data?.msg) setErrMsg(err?.data?.msg);
      else if (err?.data?.error) setErrMsg(err?.data?.error);
      else setErrMsg(t("error.loginFailed"));
      errRef.current.focus();
    }
    setLoading(false);
  };

  const handleSocialLogin = (platform) => {
    window.open(`${process.env.REACT_APP_BACKEND_URL}/user/${lang}/auth/${platform}`, "_self")
  };

  useEffect(() => {
    if (provider) {
      const verifyTokens = async () => {
        try {
          const userData =
            provider === "google"
              ? await googleLogin({ accesstoken: token }).unwrap()
              : await fbLogin({ accesstoken: token }).unwrap();

          if (userData.token) {
            const data = { user: "", accessToken: userData.token };
            dispatch(setCredentials({ data }));
            if (window.self !== window.top) {
              const URL = localStorage.getItem("EDSXFrame");
              window.location.href = `https://app.edsx.ch/project/${URL}`;
            } else {
              navigate(`/${lang}/profile`);
            }
          }
        } catch (err) {
          if (!err) setErrMsg("No Server Response");
          else if (err.data.error) setErrMsg(err.data.error);
          else setErrMsg("Login Failed");
        }
      };
      verifyTokens();
    }
    // eslint-disable-next-line
  }, [token, provider]);

  if (token && !errMsg) {
    return <Loader />;
  }

  if (!isLoggedIn) {
    return (
      <Container>
        <Row className="pt-5 pb-5 justify-content-center">
          <Col lg="5" md="8" sm="12">
            <Card className={`${style.loginFormContainer}`}>
              <Card.Header className={`m-3 pb-2 text-primary ${style.title}`}>
                {t("signIn")}
              </Card.Header>
              <Card.Body>
                {errMsg && (
                  <p
                    ref={errRef}
                    className="text-center text-danger"
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                )}
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label className={`${style.formLabel}`}>
                      {t("emailAddress")}
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={t("enterEmail")}
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className={`${style.formLabel}`}>
                      {t("password")}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("password")}
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      autoComplete="off"
                      required
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className={`${style.btnLogin}`}
                  >
                    {t("SIGNIN")}{" "}
                    <Spinner
                      className={`${!loading || !isLoading ? "d-none" : ""}`}
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      animation="border"
                    />
                  </Button>
                </Form>

                <Nav className={`mt-3 mb-3 ${style.socialLogin}`}>
                  <Nav.Item>
                    <Nav.Link disabled>{t("orUse")}:</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-1"
                      className={`${style.facebook}`}
                      aria-label="Facebook Login"
                      onClick={() => handleSocialLogin("facebook")}
                    >
                      <i
                        className={`fab fa-facebook-f fa-2x ${style.facebookI}`}
                      ></i>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="link-2"
                      className={`${style.google}`}
                      aria-label="Google Login"
                      onClick={() => handleSocialLogin("google")}
                    >
                      <i
                        className={`fab fa-google-plus-g fa-2x ${style.googleI}`}
                      ></i>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Body>
              <Card.Footer>
                <Nav className="justify-content-center">
                  <Link
                    to={`/${lang}/forgot-password`}
                    className="text-danger nav-link"
                  >
                    {t("forgotYourPassword")}?
                  </Link>
                  <Link
                    to={`/${lang}/signup`}
                    className="ms-sm-auto text-primary nav-link"
                  >
                    {t("createAccount")}
                  </Link>
                </Nav>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default Login;
