import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  Container,
  Offcanvas,
  // DropdownButton,
  // Dropdown,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isLogged } from "../app/features/auth/authSlice";
import { useLocation, useNavigate } from "react-router";
import image from "../assets/img/logo.png";
import { useTranslation } from "react-i18next";
import { setDefaultLang } from "../app/features/extraReducers";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [stickyClass, setStickyClass] = useState("");
  const [otherPage, setOtherPage] = useState(true);
  const isLoggedIn = useSelector(isLogged);
  const [show, setShow] = useState(false);
  const [showLang, setShowLang] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [t] = useTranslation();

  const changeLanguage = () => {
    const lang = selectedLanguage === 'en' ? 'it' : 'en'
    dispatch(setDefaultLang(lang));
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang);
    const path = location.pathname.slice(1).split("/");
    path[0] = lang;
    window.location.href = `/${[...path].join("/")}`;
    // navigate(
    //   { ...location, pathname: `/${[...path].join("/")}` },
    //   { replace: true }
    // );
  };

  useEffect(() => {
    const path = location.pathname.slice(1).split("/");
    setSelectedLanguage(path[0]);
    dispatch(setDefaultLang(path[0]));
  }, [i18n, location.pathname, navigate, dispatch]);

  const stickNavbar = useCallback(() => {
    if (window.pageYOffset > 450) {
      if (otherPage) setStickyClass("top");
    }
    if (window.pageYOffset < 350) setStickyClass("");
  }, [otherPage]);

  useEffect(() => {
    if (
      window.location.pathname === `/${selectedLanguage}/login` ||
      window.location.pathname === `/${selectedLanguage}/signup` ||
      window.location.pathname === "/"
    ) {
      setOtherPage(false);
    } else {
      setOtherPage(true);
    }

    window.addEventListener("scroll", stickNavbar);
    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, [location, stickNavbar, selectedLanguage]);

  return (
    <>
      <Navbar
        sticky={stickyClass}
        expand="lg"
        className={`p-4 header ${otherPage ? "bg-primary" : "bg-transparent"}`}
        variant="dark"
      >
        <Container fluid>
          <a
            href={`${process.env.REACT_APP_REAL_SITE}/${
              selectedLanguage === "it" ? "it/" : ""
            }`}
            className="navbar-brand"
          >
            <img
              alt={process.env.REACT_APP_TITLE}
              src={image}
              className="d-inline-block align-top"
              width="265"
              height="72"
            />
          </a>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar-expand-sm"
            onClick={() => setShow(true)}
          />
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-sm"
            aria-labelledby="offcanvasNavbarLabel-expand-sm"
            placement="end"
            show={show}
            onHide={() => setShow(false)}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-sm">
                <a
                  href={`${process.env.REACT_APP_REAL_SITE}/${
                    selectedLanguage === "it" ? "it/" : ""
                  }`}
                  className="navbar-brand"
                  onClick={() => setShow(false)}
                >
                  <img
                    alt={process.env.REACT_APP_TITLE}
                    src={image}
                    className="d-inline-block align-top"
                    width="190"
                    height="51"
                  />
                </a>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3 nav-item">
                <a
                  href={`${process.env.REACT_APP_REAL_SITE}/${
                    selectedLanguage === "it" ? "it/" : ""
                  }`}
                  onClick={() => setShow(false)}
                  className="nav-link"
                >
                  {t("header.home")}
                </a>
                <Link
                  onClick={() => setShow(false)}
                  to={`/${selectedLanguage}/investment-opportunities`}
                  className=" nav-link"
                >
                  {t("header.investment")} <br />
                  {t("header.opportunities")}
                </Link>
                <Link
                  onClick={() => setShow(false)}
                  to={`/${selectedLanguage}/exchange`}
                  className="nav-link"
                >
                  {t("header.exchange")}
                </Link>
                {/* <Link
                  onClick={() => setShow(false)}
                  to="/project/euro-token"
                  className="nav-link"
                >
                  Buy <br />
                  Stable Coin
                 </Link> */}
                <Nav.Link
                  href={`${process.env.REACT_APP_REAL_SITE}/${
                    selectedLanguage === "it"
                      ? "it/sei-una-attivita-commerciale/"
                      : "are-you-a-business"
                  }`}
                >
                  {t("header.areYou")} <br />
                  {t("header.business")}
                </Nav.Link>
                <Nav.Link
                  href={`${process.env.REACT_APP_REAL_SITE}/${
                    selectedLanguage === "it"
                      ? "it/blog-notizie/"
                      : "blognews"
                  }`}
                >
                  {t("header.blog")} <br />
                  {t("header.news")}
                </Nav.Link>
                <Nav.Link
                  href={`${process.env.REACT_APP_REAL_SITE}/${
                    selectedLanguage === "it"
                      ? "it/tutorial-per-investitori/"
                      : "are-you-an-investor"
                  }`}
                >
                  {t("header.investor")} <br />
                  {t("header.tutorial")}
                </Nav.Link>
                {/* <DropdownButton className="dropdownMenu" title="Some Title">
                  <Dropdown.Item href={`${process.env.REACT_APP_REAL_SITE}/${selectedLanguage === "it" ? 'it/sei-una-attivita-commerciale/' : 'are-you-a-business'}`}>
                    {t("header.areYou")} {t("header.business")}
                  </Dropdown.Item>
                  <Dropdown.Item href={`${process.env.REACT_APP_REAL_SITE}/${selectedLanguage === "it" ? 'it/tutorial-per-investitori/' : 'are-you-an-investor'}`}>
                    {t("header.investor")} {t("header.tutorial")}
                  </Dropdown.Item>
                </DropdownButton> */}
                <Nav.Link
                  href={`${process.env.REACT_APP_REAL_SITE}/${
                    selectedLanguage === "it" ? "it/contattaci/" : "contacts"
                  }`}
                >
                  {t("header.contactUs")}
                </Nav.Link>
                {isLoggedIn ? (
                  <>
                    <Link
                      onClick={() => setShow(false)}
                      to={`/${selectedLanguage}/profile`}
                      className="nav-link profile-menu"
                    >
                      {t("header.dashboard")}
                    </Link>
                    <Link
                      onClick={() => setShow(false)}
                      to={`/${selectedLanguage}/logout`}
                      className=" nav-link profile-menu d-md-none"
                    >
                      {t("profile.logout")}
                    </Link>
                  </>
                ) : (
                  <Link
                    onClick={() => setShow(false)}
                    to={`/${selectedLanguage}/login`}
                    className=" nav-link profile-menu"
                  >
                    {t("getStarted")}
                  </Link>
                )}
                {/* <DropdownButton
                  className="languageSwitch"
                  title={selectedLanguage}
                >
                  <Dropdown.Item onClick={() => changeLanguage("en")}>
                    <img src="/en.svg" alt="English"/> EN
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => changeLanguage("it")}>
                    <img src="/it.svg" alt="Italian"/> IT
                  </Dropdown.Item>
                </DropdownButton> */}

                <div class="dropdown">
                  <button
                    type="button"
                    className="dropdown-toggle"
                    style={{
                      padding: "10px 20px",
                      borderRadius: "30px",
                      backgroundColor: "#fff",
                      textTransform: "uppercase",
                    }}
                    onClick={() => setShowLang(!showLang)}
                  >
                    <img
                      src={`/${selectedLanguage}.svg`}
                      alt={selectedLanguage}
                      style={{
                        paddingRight: "10px",
                      }}
                    />
                    {selectedLanguage}
                  </button>
                  <ul
                    class={`dropdown-menu ${showLang ? "show" : ""}`}
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#fff",
                      borderRadius: "30px",
                      minWidth:"109px",
                      textTransform: "uppercase",
                    }}
                  >
                      <li style={{ cursor: "pointer" }} onClick={() => changeLanguage()}>
                        <img
                          src={`/${selectedLanguage === "en" ? 'it' : 'en'}.svg`}
                          alt={selectedLanguage === "en" ? 'it' : 'en'}
                          style={{ paddingRight: "5px" }}
                        />{" "}
                        {selectedLanguage === "en" ? 'it' : 'en'}
                      </li>
                  </ul>
                </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;