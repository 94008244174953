import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Spinner, Form, InputGroup } from "react-bootstrap";
import { useGetkycurlMutation, useGetKYCUpdateUrlMutation } from "../app/features/apisSlice";
import Swal from "sweetalert2";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import image from "../assets/img/onboarding-welcome.png";
import { formatCurrencyValue } from "../helpers/FormatCurrencyHelper";

const KycCard = ({ userData }) => {
  const setCopy = "";
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [userType, setUserType] = useState(userData?.kycStatus?.entity_type);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [t] = useTranslation();

  const [getKYCUpdateUrl] = useGetKYCUpdateUrlMutation();
  const handleUpdateKYCUrl = async () => {
    setLoading2(true);
    setErrMsg(null);
    setSuccessMsg(null);
    try {
      const response = await getKYCUpdateUrl();
      if (response?.error) setErrMsg(response?.error?.data?.message);
      else if (response?.data?.redirectUrl) {
        const url = response?.data?.redirectUrl
        setRedirectUrl(url);
        window.open(url, "_blank");
      } else setErrMsg(t("error.noServerResponse"));
    } catch (error) {
      if (!error) setErrMsg(t("error.noServerResponse"));
      else if (error?.data?.message) setErrMsg(error?.data?.message);
      else if (error?.data?.error) setErrMsg(error?.data?.error);
      else setErrMsg(t("error.noServerResponse"));
    }
    setLoading2(false);
  };

  const [getkycurl] = useGetkycurlMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrMsg(null);
    setSuccessMsg(null);
    if (!userType) {
      setErrMsg(t("kyc.pleaseSelectUserType"));
      return false;
    }
    try {
      const response = await getkycurl(userType);
      if (response?.error?.data?.errorData?.message) {
        setErrMsg(response?.error?.data?.errorData?.message);
        setLoading(false);
        return false;
      }
      if (response?.data?.message) {
        setErrMsg(response?.data?.message);
        setLoading(false);
        return false;
      }
      if (response?.error?.data?.message) {
        setErrMsg(response?.error?.data?.message);
        setLoading(false);
        return false;
      }
      if (response?.data?.redirectUrl) {
        const url = response?.data?.redirectUrl;
        setRedirectUrl(url);
        if (url) window.open(url, "_blank");
      }
    } catch (err) {
      if (!err) setErrMsg(t("error.noServerResponse"));
      else if (err?.data?.msg) setErrMsg(err?.data?.msg);
      else if (err?.data?.error) setErrMsg(err?.data?.error);
      else setErrMsg(t("error.noServerResponse"));
    }
    setLoading(false);
  };

  useEffect(() => {
    const popup = window.open('about:blank', '_blank');
    if (!popup) {
      Swal.fire({
        icon: "warning",
        title: `${t("profile.popupMsg")}`,
      })
    } else {
      popup.close();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card className="mt-4 p-4 shadow">
      <Card.Body>
        <Row className="align-items-center kyc-profile">
          <Col md="8" sm="12">
            <h4 className="mt-2 mb-3">{t("profile.kycVeification")}</h4>
            {successMsg && (
              <p className="text-success" aria-live="assertive">
                {successMsg}
              </p>
            )}
            {errMsg && (
              <p className="text-danger" aria-live="assertive">
                {errMsg}
              </p>
            )}

            {successMsg && (
              <p className="text-success" aria-live="assertive">
                {successMsg}
              </p>
            )}

            {userType && !userData?.kycStatus?.level}
            {userData?.totalInvested && (
              <>
                <h6 className="mb-0">
                  <b>{t("kyc.status")}: </b>
                  <span
                    className={`${userData?.kycStatus?.kyc_status === "REJECTED"
                      ? "text-danger"
                      : "text-info"
                      }`}
                  >
                    {t(`status.${userData?.kycStatus?.kyc_status}`)}
                  </span>
                </h6>
                <p>
                  <b>{t("kyc.type")}: </b>
                  {t(`entity.${userData?.kycStatus?.entity_type}`)}
                  <br />
                  <b>{t("kyc.level")}: </b>
                  {t(`level.${userData?.kycStatus?.level}`)}
                </p>
              </>
            )}
            {userType && userData?.kycStatus?.kyc_status !== "REJECTED" && (
              <p>
                {userData?.totalInvested && (
                  <>
                    <b>{t("kyc.totalInvestedAmount")}: </b>
                    {userData?.totalInvested === "CHF null"
                      ? "0"
                      : formatCurrencyValue(userData?.totalInvested)}
                    <br />
                  </>
                )}
                {userData?.currentMaxRange && (
                  <>
                    <b>{t("kyc.currentMaximumInvestmentRange")}: </b>
                    {formatCurrencyValue(userData?.currentMaxRange)}
                  </>
                )}
              </p>
            )}

            <Form onSubmit={handleSubmit} className="text-sm-left">
              {!userData?.kycStatus?.level && (
                <>
                  <Form.Group className="mb-3" style={{ textAlign: "left" }}>
                    <strong>{t("profile.selectUserType")}</strong>
                    <Form.Check type="radio">
                      <Form.Check.Input
                        type="radio"
                        name="usertype"
                        value="PERSON"
                        onChange={(e) => setUserType(e.target.value)}
                      />
                      <Form.Check.Label>
                        {t("profile.isNaturalPerson")}
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check type="radio">
                      <Form.Check.Input
                        type="radio"
                        name="usertype"
                        value="COMPANY"
                        onChange={(e) => setUserType(e.target.value)}
                      />
                      <Form.Check.Label>
                        {t("profile.isCompanyOrLegalPerson")}
                      </Form.Check.Label>
                    </Form.Check>

                    {userType === "PERSON" && (
                      <Form.Check type="radio" className="mt-4">
                        <Form.Check.Input
                          type="checkbox"
                          name="agree"
                          required
                        />
                        <Form.Check.Label>
                          {t("profile.benificialOwnerNotThirdParty")}
                        </Form.Check.Label>
                      </Form.Check>
                    )}
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    {t("profile.proceedToKYC")}{" "}
                    {loading && (
                      <Spinner
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    )}
                  </Button>
                </>
              )}
              {(() => {
                if (userType && userData?.kycStatus?.kyc_status === "UPDATE_REQUESTED") {
                  return (
                    <>
                      <Button variant="primary" type="submit">
                        {t("profile.viewkyc")}{" "}
                        {loading && (
                          <Spinner
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        )}
                      </Button>
                    </>
                  );
                }
                else if (userType && userData?.kycStatus?.kyc_status === "ACCEPTED" && userData?.kycStatus?.level !== "FIFTH") {
                  return (
                    <>
                      <Button variant="primary" type="submit">
                        {t("profile.upgradeKYCLevel")}{" "}
                        {loading && (
                          <Spinner
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        )}
                      </Button>
                    </>
                  );
                }
                else if (userType && userData?.kycStatus?.kyc_status === "OPENED") {
                  return (
                    <>
                      <Button variant="primary" onClick={handleUpdateKYCUrl}>
                        {t("profile.updateKYC")}{" "}
                        {loading2 && (
                          <Spinner
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        )}
                      </Button>
                    </>
                  );
                }
              })()}
              {redirectUrl && (
                <>
                  <h6 className="pl-2">{t("profile.redirectToKycMsg")}</h6>
                  <InputGroup>
                    <Form.Control disabled={true} value={redirectUrl} />
                    <CopyToClipboard text={redirectUrl} onCopy={() => setCopy()}>
                      <Button variant="outline-secondary">
                        <i className="fa fa-copy"></i>
                      </Button>
                    </CopyToClipboard>
                  </InputGroup>
                </>
              )}
            </Form>
          </Col>
          <Col md="4" sm="12">
            <img src={image} alt="Onboarding" />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default KycCard;
