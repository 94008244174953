import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectDefaultLang, setInvestmentData } from "../app/features/extraReducers";
import image from "../assets/img/payment_approved.png";
import { useTranslation } from "react-i18next";

const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const lang = useSelector(selectDefaultLang)
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [t] = useTranslation();

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      dispatch(setInvestmentData(null));
    }
    // eslint-disable-next-line
  }, [isInitialRender]);

  return (
    <>
      <div className="pageContent">
        <Container>
          <Row className="align-items-center text-center">
            <Col>
              <h2>
                {t("payment.yourInvestment")} <br />
                {t("payment.finishedButPendingConfirmation")}
              </h2>
              <img src={image} alt="payment_approved" className="w-50" />
              <h4>{t("payment.itIsSafeToLeaveNow")}.</h4>
              <p>
                {t("payment.weWillBeSendingYouAConfirmationEmailAfterPaymentAndKYCApproval")}
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="m-3 mt-0">
              <ul className="pay-success-info">
                <li>
                  {t("payment.bankTransferConfirmationTime")} 
                </li>
                <li>
                  {t("payment.cryptoTransferConfirmationTime")}{" "}
                  {t("payment.transactionConfirmedNoWorries")}
                </li>
                <li>
                  {t("payment.ifPaidWithCreditCardExpectEmailInMinutes")}
                </li>
              </ul>
              <div className="w-100 text-center">
                <Link
                  to={`/${lang}/investment-opportunities`}
                  className="btn btn-primary mx-auto"
                >
                  {t("welcome.seeCurrentProject")} <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PaymentSuccess;
