import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import image from "../assets/img/loader.svg";

const Loader = () => {
  return (
    <>
      <div className="pageContent mx-auto">
        <Container>
          <Row className="align-items-center text-center">
            <Col>
              <img src={image} alt="Loader" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Loader;
