import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, Alert, Spinner, Card } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  useGetUserProfileQuery,
  useInsertPublicKeyMutation,
  useCreateInvestmentMutation,
} from "../app/features/apisSlice";
import { new_eos_keys } from "eos-ecc";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDefaultLang,
  selectNftPriceList,
  setInvestmentData,
} from "../app/features/extraReducers";
import style from "../Forms/style.module.css";
import ErrorPage from "./ErrorPage";
import { useTranslation } from "react-i18next"

const PdInvestNow = (props) => {
  const lang = useSelector(selectDefaultLang)
  const Project = props.data;
  const errorRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionId = useRef(Date.now()).current;
  const [amount, setAmount] = useState("");
  const [validAmount, setValidAmount] = useState(true);
  const [amountErrMsg, setAmountErrMsg] = useState(null);
  const [t] = useTranslation();

  const validCurriencies = [
    { code: "EUR", alias: "Euro (EUR)" },
    { code: "CHF", alias: "Swiss Franc (CHF)" },
    { code: "USD", alias: "US Dollar (USD)" },
    { code: "BTC", alias: "bitCoinWalletAddress" },
    { code: "ETH", alias: "ethereumWalletAddress" },
    { code: "USDT", alias: "tetherWalletAddress" },
  ];
  // const curriencies = validCurriencies.map((item, i) => {
  //   if (
  //     validCurriencies[i].code === "EUR" ||
  //     validCurriencies[i].code === "CHF" ||
  //     validCurriencies[i].code === "USD" ||
  //     Project[validCurriencies[i].alias]
  //   ) {
  //     return validCurriencies[i].code
  //   }
  // }).filter(x => x)
  const curriencies = validCurriencies
    .filter(item => {
      return (
        item.code === "EUR" ||
        item.code === "CHF" ||
        item.code === "USD" ||
        Project[item.alias]
      );
    })
    .map(item => item.code);

  const [currency, setCurrency] = useState("");
  const [validCurrency, setValidCurrency] = useState(true);
  const [currencyErrMsg, setCurrencyErrMsg] = useState(null);
  const [referral, setReferral] = useState("");
  const [policies, setPolicies] = useState("");
  const [validPolicies, setValidPolicies] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasKey, setHasKey] = useState(false);
  const [isKeyDownloaded, setIsKeyDownloaded] = useState(false);
  const [nftPrice, setNftPrice] = useState(0);
  const executeScroll = () => errorRef.current.scrollIntoView();

  const { data, isLoading, isError, error } = useGetUserProfileQuery({ sessionId });

  const nftPriceList = useSelector(selectNftPriceList);
  useEffect(() => {
    if (Project?.type === "NFT" && nftPriceList) {
      let defaultCurrency = Project?.currency.toLowerCase();
      setNftPrice(nftPriceList[defaultCurrency]);
      setAmount(nftPriceList[defaultCurrency]);
      setCurrency(Project?.currency);
    }
  }, [nftPriceList, Project]);

  useEffect(() => {
    if (
      !isLoading &&
      !isError &&
      data?.publicKey?.public_key &&
      data?.publicKey?.public_key !== "null"
    ) {
      setEnableInvestBtn(true);
      setHasKey(true);
    }
  }, [
    isLoading,
    isError,
    data?.publicKey?.public_key,
    Project
  ]);

  useEffect(() => {
    const fetchEOSKey = async () => {
      const generatedKey = await new_eos_keys();
      setPublicKey(generatedKey.public_key);
      setPrivateKey(generatedKey.private_key);
    };

    fetchEOSKey();
  }, []);

  const getPriceInKeyValuePair = (value) => {
    const keyCurrency = Object.keys(nftPriceList);
    if (keyCurrency.includes(value)) {
      return nftPriceList[value];
    }
  };

  const handleCurrencyChange = async (value) => {
    setErrMsg(null);
    setCurrency(value);

    if (Project?.type === "NFT") {
      const fiatPrice = getPriceInKeyValuePair(value.toLowerCase());
      setNftPrice(+fiatPrice);
      setAmount(+fiatPrice);
    }
  };

  const setCopy = "";
  const privateStar = "***************************************************";
  const [privateKey, setPrivateKey] = useState(privateStar);
  const [showKey, setShowKey] = useState(privateStar);
  const [publicKey, setPublicKey] = useState(null);
  const [enableInvestBtn, setEnableInvestBtn] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const downloadKey = () => {
    setTimeout(() => {
      setIsKeyDownloaded(true);
    }, 1500);
    let textToSave = t("yourPrivatekeyForEOSAccount") + privateKey;
    let hiddenElement = document.createElement("a");
    hiddenElement.href = "data:attachment/text," + encodeURI(textToSave);
    hiddenElement.target = "_blank";
    hiddenElement.download = "EDSX-myKey.txt";
    hiddenElement.click();
  };

  const [insertPublicKey] = useInsertPublicKeyMutation();
  const handleCheckbox = async (e) => {
    setIsChecked(true)
    try {
      const response = await insertPublicKey({ publicKey: publicKey }).unwrap();
      if (response.message) {
        setEnableInvestBtn(true);
        Swal.fire(`${t("youMayContinueYourInvestment")}!`, "", "success");
      } else {
        setEnableInvestBtn(false);
        setIsChecked(false)
        Swal.fire(`${t("error.unableToSavePublicKey")}`, t("error.tryAgainLater"), "error");
      }
    } catch (err) {
      console.log(err);
      setEnableInvestBtn(false);
      setIsChecked(false)
      Swal.fire(`${t("error.somethingWentWrong")}`, t("error.tryAgainLater"), "error");
    }
  };

  const validateInvestment = () => {
    if (!amount) {
      setValidAmount(false);
      setAmountErrMsg(t("invest.enterSomeAmountToInvest"));
    } else if (amount <= 0) {
      setValidAmount(false);
      setAmountErrMsg(t("invest.amountShouldBeGreaterThanZero"));
    } else {
      setValidAmount(true);
      setAmountErrMsg(null);
    }

    if (!currency) {
      setCurrencyErrMsg(t("invest.pleaseSelectCurrency"));
      setValidCurrency(false);
    } else {
      setValidCurrency(true);
      setCurrencyErrMsg(null);
    }

    if (!policies) {
      setValidPolicies(false);
      setCurrencyErrMsg(t("invest.acceptTheTermsAndPolicies"));
    } else {
      setValidPolicies(true);
      setCurrencyErrMsg(null);
    }

    return !amount || !currency || !policies || amount <= 0 ? true : false;
  };

  const [createInvestment] = useCreateInvestmentMutation();

  const handleSubmitInvestment = async (event) => {
    // const form = event.currentTarget;
    event.preventDefault();
    const isError = validateInvestment();
    setLoading(true);
    if (!isError) {
      let investmentData = {
        amount: parseFloat(amount),
        currency,
        referral,
        projectId: Project.id,
      };

      if (Project?.type === "NFT") {
        investmentData.nftId = props?.nftId;
      }

      try {
        const { transactionId, error } = await createInvestment(
          investmentData
        ).unwrap();
        if (error) {
          console.log(1, error);
        } else {
          dispatch(
            setInvestmentData({
              ...Project,
              investedAmount: amount,
              investedCurrency: currency,
              transactionId,
            })
          );
        }
        navigate(`/${lang}/thankyou`);
      } catch (err) {
        if (!err) setErrMsg(t("error.noServerResponse"));
        else if (err?.data?.error) setErrMsg(err.data.error);
        else if (err?.data?.message) setErrMsg(err.data.message);
        else setErrMsg(t("error.investmentFailed"));
        setLoading(false);
        executeScroll();
      }
    }
    setLoading(false);
  };

  if (isError) return <ErrorPage error={error?.data?.error} />;

  return (
    <>
      <Form>
        <h2 className="titleText mb-5">{t("yourInvestment")}</h2>
        {errMsg && (
          <p
            ref={errorRef}
            className="text-danger text-center"
            aria-live="assertive"
          >
            {errMsg}
          </p>
        )}
        <Row>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label className={`${style.formLabel}`}>
                {t("amountYouWantToInvest")}
              </Form.Label>
              {nftPrice ? (
                <Form.Control
                  type="text"
                  placeholder={t("pleaseEnterYourInvestmentAmount")}
                  value={nftPrice}
                  required
                  isInvalid={false}
                  disabled={true}
                />
              ) : (
                <Form.Control
                  type="number"
                  placeholder={t("pleaseEnterYourInvestmentAmount")}
                  onChange={(e) => setAmount(e.target.value)}
                  value={amount}
                  isInvalid={!validAmount}
                  required
                />
              )}
              <Form.Control.Feedback type="invalid">
                {amountErrMsg}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6} sm={12}>
            <Form.Group className="mb-3">
              <Form.Label className={`${style.formLabel}`}>
                {t("selectACurrency")}
              </Form.Label>
              <Form.Select
                onChange={(e) => handleCurrencyChange(e.target.value)}
                isInvalid={!validCurrency}
                value={currency}
                required
              >
                <option disabled value="">
                  {t("selectCurrency")}
                </option>
                {curriencies.map((item, i) => (
                  <option key={i} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {currencyErrMsg}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3">
          <Form.Label
            className={`${style.formLabel} ${style.profileFormLabel}`}
          >
            {t("referral")}
          </Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => setReferral(e.target.value)}
            placeholder={t("typeHereYourReferralCode")}
          />
        </Form.Group>

        {!hasKey && (
          <Card className="shadow">
            <Card.Body>
              <h5 className="titleText subTitle">{t("invest.yourNewEOSAccount")}:</h5>
              <p>
                {t("saveTheFollowingPrivateKey")}{" "}
                <b>
                  {t("thereIsNoWayForUsToRecoverIt")}
                </b>
              </p>

              <input type="hidden" name="privKey" value={privateKey} />
              <input type="hidden" name="publicKey" value={privateKey} />
              <Alert
                variant="secondary"
                className="p-4"
                onMouseEnter={() => setShowKey(privateKey)}
                onMouseLeave={() => setShowKey(privateStar)}
              >
                <CopyToClipboard text={privateKey} onCopy={() => setCopy()}>
                  <i className="fa fa-copy fs-3 text"></i>
                </CopyToClipboard>
                <span className="p-5">{showKey}</span>
              </Alert>

              <div className="mt-4 d-flex justify-content-center align-items-center flex-column">
                <Button variant="primary" onClick={downloadKey}>
                  {t("downloadKeyAsTextFile")}
                </Button>
                {!enableInvestBtn && (
                  <>
                    <Form.Check type="checkbox" className="mt-3">
                      <Form.Check.Input
                        disabled={!isKeyDownloaded}
                        type="checkbox"
                        onChange={handleCheckbox}
                        checked={isChecked}
                        required
                      />
                      <Form.Check.Label>
                        {t("iConfirmThatIHaveStoredMyPrivateKey")}
                      </Form.Check.Label>
                    </Form.Check>
                  </>
                )}
                {enableInvestBtn && (
                  <Alert
                    variant="success"
                    className="privkeydone d-flex justify-content-center align-items-center"
                  >
                    <svg
                      className="checkmark"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      <circle
                        className="checkmark__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        className="checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                    {t("youMayContinueYourInvestment")}
                  </Alert>
                )}
              </div>
            </Card.Body>
          </Card>
        )}

        <p className="mt-3">
          {t("byClicking")} <b>{t("invests")}</b>, {t("iHereby")}
        </p>
        <Form.Check type="checkbox">
          <Form.Check.Input
            type="checkbox"
            onChange={(e) => setPolicies(e.target.value)}
            checked={policies ? true : false}
            required
            isInvalid={!validPolicies}
          />
          <Form.Check.Label>
            {t("agreeAndConsentToThe")}{" "}
            <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_REAL_SITE}/${lang === "it" ? 'it/termini-e-condizioni' : 'terms-conditions'}`}>
              {t("campaignTermsAndConditions")}
            </a>
            , {t("the")}{" "}
            <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_REAL_SITE}/${lang === "it" ? 'it/termini-e-condizioni' : 'terms-conditions'}`}>
              {t("platformTermsAndConditions")}
            </a>
            , {t("the")}{" "}
            <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_REAL_SITE}/${lang === "it" ? 'it/politica-sulla-privacy/' : 'privacy-policy'}`}>
              {t("platformPrivacyPolicy")}
            </a>
            , {t("the")}{" "}
            <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_REAL_SITE}/${lang === "it" ? 'it/cookies-policy-italiano' : 'cookies-policy'}`}>
              {t("platformCookiesPolicy")}
            </a>{" "}
            {t("and")} {t("the")}{" "}
            <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_REAL_SITE}/${lang === "it" ? 'it/avviso-sui-rischi/' : 'risk-disclaimer'}`}>
              {t("platformRiskDisclaimer")}
            </a>
            .
          </Form.Check.Label>
          <Form.Control.Feedback type="invalid">
            {t("pleaseAcceptTermsAndPolicies")}
          </Form.Control.Feedback>
        </Form.Check>
        <p className="mt-5">
          <small>
            <b>{t("disclaimer")}</b>
            <ul>
              <li>
                {t("conversionAmountStatedCryptocurrency")}
              </li>
              <li>
                {t("paymentInSelectedCryptocurrenciesIsAcceptable")}
              </li>
            </ul>
          </small>
        </p>
        <p>
          <small>
            {t("ifYouDoNotPayWithStripe")}
          </small>
        </p>

        <Button
          type="submit"
          variant="primary"
          disabled={!enableInvestBtn}
          size="lg"
          onClick={handleSubmitInvestment}
        >
          {t("invests")}{" "}
          {loading ? (
            <Spinner
              variant="light"
              size="sm"
              role="status"
              aria-hidden="true"
              animation="border"
            />
          ) : (
            <i className="fa fa-arrow-right"></i>
          )}
        </Button>
      </Form>
    </>
  );
};

export default PdInvestNow;
