import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDefaultLang } from "../app/features/extraReducers";

const Welcome = () => {
  const lang = useSelector(selectDefaultLang)
  const [t] = useTranslation();
  
  return (
    <>
      <PageTitle title={t("welcome.welcome")} />
      <div className="pageContent">
        <Container>
          <Row className={`align-items-center text-center ${style.welcomeBtnWrapper}`}>
            <Col md="6" sm="12">
              <Link to={`${lang}/investment-opportunities`} className="btn btn-primary btn-lg">{t("welcome.seeCurrentProject")}</Link>
            </Col>
            <Col md="6" sm="12">
              <Link to={`/${lang}/profile`} className="btn btn-primary btn-lg">{t("welcome.continueKYCVerification")}</Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Welcome; 