import React, { useState, useEffect } from "react";
import { Card, Col, Button, Form, Container, Row, Nav, Modal, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLogged } from "../app/features/auth/authSlice";
import { useRegisterMutation } from "../app/features/auth/authApiSlice";
import { selectDefaultLang } from "../app/features/extraReducers";
import Reaptcha from 'reaptcha';
import TermsAndConditions from "../Pages/TermsAndConditions";
import RiskDisclaimer from "../Pages/RiskDisclaimer";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import CookiePolicy from "../Pages/CookiePolicy";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";

const Signup = () => {
  const navigate = useNavigate();
  const lang = useSelector(selectDefaultLang)
  const isLoggedIn = useSelector(isLogged);
  // Handle Modal
  const [modalType, setModalType] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (type) => {
    setModalType(type);
    setShow(true);
  }
  // Handle Signup
  const [loading, setLoading] = useState(false);
  const [name, setFirstname] = useState("");
  const [sureName, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [validMatch, setValidMatch] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(0);
  const [riskDisclaimer, setRiskDisclaimer] = useState(0);
  const [privacyPolicy, setPrivacyPolicy] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [captchaRes, setCaptchaRes] = useState(null)
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false)
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
  const [captcha, setCaptcha] = useState(null)
  const [t] = useTranslation();

  useEffect(() => {
    // eslint-disable-next-line
    setValidPassword(PWD_REGEX.test(password));
    setValidMatch(password === matchPassword);
    if (isLoggedIn) navigate(`/${lang}/profile`);
    // eslint-disable-next-line
  }, [password, matchPassword, isLoggedIn, navigate, lang]);

  const handleTermsAndConditionCheckbox = (e) => e.target.checked ? setTermsAndCondition(1) : setTermsAndCondition(0);
  const handleRiskDisclaimerCheckbox = (e) => e.target.checked ? setRiskDisclaimer(1) : setRiskDisclaimer(0);
  const handlePrivacyPolicyCheckbox = (e) => e.target.checked ? setPrivacyPolicy(1) : setPrivacyPolicy(0);

  const [register, { isLoading }] = useRegisterMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
      const data = {
        name: name.trim(),
        sureName: sureName.trim(),
        email: email.trim(),
        password,
        termsAndCondition,
        riskDisclaimer,
        privacyPolicy,
        reponseString:captchaRes,
        language: lang
      };
      try {
        const response = await register(data).unwrap();
        setSuccessMsg(response?.msg);
        setErrMsg(null)
        setSuccess(true);
        setFirstname("");
        setSurname("");
        setEmail("");
        setPassword("");
        setMatchPassword("");
        setTermsAndCondition("");
        setRiskDisclaimer("");
        setPrivacyPolicy("");
        captcha.reset();
      } catch (err) {
        setSuccessMsg(null);
        if (!err) setErrMsg(t("error.noServerResponse"));
        else if (err?.data?.error) setErrMsg(err?.data?.error);
        else if(err?.data?.msg) setErrMsg(err?.data?.msg)
        else setErrMsg(t("error.registrationFailed"));
      }
    setLoading(false);
  };

  const handleSocialSignup = (platform) => {
    window.open(`${process.env.REACT_APP_BACKEND_URL}/user/${lang}/auth/${platform}`, "_self")
  }

  if (!isLoggedIn) {
    return (
      <Container>
        <Row className="pt-5 pb-5 justify-content-center">
          <Col lg="7" md="10" sm="12">
            <Card className={`${style.signupFormContainer}`}>
              <Card.Header className={`mt-3 pb-2 text-primary ${style.title}`} >
                {t("signUp")}
              </Card.Header>
              <Card.Body>
                <Nav className={`mb-3 justify-content-center ${style.socialLogin}`}>
                  <Nav.Item>
                    <Nav.Link className={`${style.facebook}`} aria-label="Facebook Signup" onClick={() => handleSocialSignup('facebook')}>
                      <i className={`fab fa-facebook-f fa-2x ${style.facebookI}`}></i>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className={`${style.google}`} aria-label="Google Login" onClick={() => handleSocialSignup('google')}>
                      <i className={`fab fa-google-plus-g fa-2x ${style.googleI}`}></i>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <p className="text-center text-uppercase fs-5">--- {t("or")} ---</p>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${style.formLabel}`}>
                          {t("name")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("name")}
                          onChange={(e) => setFirstname(e.target.value)}
                          value={name}
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className={`${style.formLabel}`}>
                          {t("surname")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("surname")}
                          onChange={(e) => setSurname(e.target.value)}
                          value={sureName}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className="mb-3">
                    <Form.Label className={`${style.formLabel}`}>
                      {t("emailAddress")}
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={t("enterEmail")}
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className={`${style.formLabel}`}>
                      {t("password")}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("password")}
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      isInvalid={password && !validPassword}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      <i className="fa fa-info-circle"></i> {t("characters")}
                      <br />
                      {t("useLetterNumberCharacter")}
                      <br />
                      {t("specialCharacters")}:{" "}
                      <span aria-label="exclamation mark">!</span>{" "}
                      <span aria-label="at symbol">@</span>{" "}
                      <span aria-label="hashtag">#</span>{" "}
                      <span aria-label="dollar sign">$</span>{" "}
                      <span aria-label="percent">%</span>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className={`${style.formLabel}`}>
                      {t("re-typePassword")}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("password")}
                      onChange={(e) => setMatchPassword(e.target.value)}
                      value={matchPassword}
                      isInvalid={matchPassword && !validMatch}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("enterSamePassword")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        onChange={handleTermsAndConditionCheckbox}
                        required
                      />
                      <Form.Check.Label>
                        {t("AcceptThe")}{" "}
                        <Button
                          onClick={() => handleShow("termsandconditions")}
                          className={`${style.likeAnchor}`}
                        >
                          {t("termsAndConditions")}
                        </Button>
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        onChange={handleRiskDisclaimerCheckbox}
                        required
                      />
                      <Form.Check.Label>
                        {t("AcceptThe")}{" "}
                        <Button
                          onClick={() => handleShow("riskdisclaimer")}
                          className={`${style.likeAnchor}`}
                        >
                          {t("footer.riskDisclaimer")}
                        </Button>
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        onChange={handlePrivacyPolicyCheckbox}
                        required
                      />
                      <Form.Check.Label>
                        {t("AcceptThe")}{" "}
                        <Button
                          onClick={() => handleShow("privacypolicy")}
                          className={`${style.likeAnchor}`}
                        >
                          {t("footer.privacyPolicy")}
                        </Button>{" "}
                        {t("and")}{" "}
                        <Button
                          onClick={() => handleShow("cookiepolicy")}
                          className={`${style.likeAnchor}`}
                        >
                          {t("cookiePolicy")}
                        </Button>
                      </Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                  <Reaptcha
                    ref={(e) => setCaptcha(e)}
                    sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                    onVerify={recaptchaResponse => {
                      setVerifiedCaptcha(true);
                      setCaptchaRes(recaptchaResponse);
                    }}
                    onExpire={() => {
                      setVerifiedCaptcha(false);
                      setCaptchaRes(null);
                    }}
                  />

                  <Button
                    variant="primary"
                    type="submit"
                    className={`mt-3 ${style.btnLogin}`}
                    disabled={!validMatch || !validPassword || !verifiedCaptcha ? true : false}
                  >
                    {t("register")}{" "}
                    <Spinner
                      className={`${!loading || !isLoading ? "d-none" : ""}`}
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      animation="border"
                    />
                  </Button>

                  {success && (
                    <p className="m-3 text-success text-center" aria-live="assertive">
                      {successMsg}
                    </p>
                  )}
                  {errMsg && (
                    <p className="m-3 text-danger text-center" aria-live="assertive">
                      {errMsg}
                    </p>
                  )}
                </Form>
              </Card.Body>
              <Card.Footer className="text-muted">
                <Nav className="justify-content-center">
                  <Link to={`/${lang}/forgot-password`} className="text-danger nav-link">
                    {t("forgotYourPassword")}?
                  </Link>
                  <Link to={`/${lang}/login`} className="ms-sm-auto text-primary nav-link">
                    {t("loginNow")}
                  </Link>
                </Nav>
              </Card.Footer>
            </Card>
          </Col>
        </Row>

        <Modal show={show} size="lg" onHide={handleClose} centered>
          <Modal.Body className="p-0">
            <button
              type="button"
              className={`btn-close ${style.modalClose}`}
              aria-label="Close"
              onClick={handleClose}
            ></button>
            {(() => {
              if (modalType === "termsandconditions") return <TermsAndConditions />;
              if (modalType === "riskdisclaimer") return <RiskDisclaimer />;
              if (modalType === "privacypolicy") return <PrivacyPolicy />;
              if (modalType === "cookiepolicy") return <CookiePolicy />;
            })()}
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
};

export default Signup;
