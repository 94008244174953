import React, { useRef } from "react";
import { Spinner } from "react-bootstrap";
import { useGetExchangeChartDataQuery } from "../../app/features/secondaryMarketApiSlice";
import TradingView from "./TradingView";
// eslint-disable-next-line
import { priceData } from "../../assets/priceData";
import Loader from "../Loader";

const TradingViewChartWrap = ({ height, projectId, spinnerType }) => {
  const timestampRef = useRef(Date.now()).current;
  const { data, isLoading, isSuccess, isError, error } =
    useGetExchangeChartDataQuery({ projectId, sessionId: timestampRef });

  if (isLoading && spinnerType === "sm")
    return (
      <Spinner
        variant="dark"
        size="sm"
        role="status"
        aria-hidden="true"
        animation="border"
      />
    );

  if (isLoading && spinnerType === "bg") return <Loader />;

  if (isError) return <p className="text-danger">{JSON.stringify(error)}</p>;

  if (
    isSuccess &&
    data?.getHistoricalData?.rows &&
    data?.getHistoricalData?.rows.length > 0
  ) {
    const chartData = JSON.stringify(data?.getHistoricalData?.rows);
    let finalData = JSON.parse(chartData);
    return <TradingView height={height} data={finalData} />;
  } else {
    return <TradingView height={height} data={priceData} />;
  }
};

export default TradingViewChartWrap;
