import React, { useEffect, useState, useRef } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useConfirmUpdateProfileMutation } from "../app/features/apisSlice";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import style from "./style.module.css";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDefaultLang } from "../app/features/extraReducers";

const ProfileChange = () => {
  const { token } = useParams();
  const lang = useSelector(selectDefaultLang)
  const [errMsg, setErrMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const isInitialRender = useRef(false);
  const [confirmUpdateProfile] = useConfirmUpdateProfileMutation();
  const [t] = useTranslation();
  const handleConfirmUpdateProfile = async () => {
    const data = { token: token };
    try {
      await confirmUpdateProfile(data).unwrap();
      setSuccessMsg(t(`profileUpdated`));
      localStorage.removeItem("isMailSent"); 
      setErrMsg(null);
    } catch (error) {
      if (error?.data?.err) setErrMsg(error?.data?.err);
      else if (error?.data?.message) setErrMsg(error?.data?.message);
      else setErrMsg(t("error.noServerResponse"));
      setSuccessMsg(null);
    }
  };

  useEffect(() => {
    if (!isInitialRender.current) {
      handleConfirmUpdateProfile();
    }
    isInitialRender.current = true;
  });

  return (
    <>
      {errMsg || successMsg ? (
        <Container>
          <Row className="pt-5 pb-5 justify-content-center">
            <Col lg="5" md="8" sm="12">
              <Card className={`${style.loginFormContainer}`}>
                <Card.Body className="text-center">
                  <h5 className={`${errMsg ? "text-danger" : ""}`}>
                    {errMsg ? errMsg : successMsg}
                  </h5>
                  <Link
                    to={`/${lang}/logout`}
                    className={`btn btn-primary mt-3 ${style.btnLogin}`}
                  >
                    {t("header.dashboard")}
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ProfileChange;
