import React from "react";
import { Container, Stack, Button } from "react-bootstrap";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDefaultLang } from "../app/features/extraReducers";

const PageTitle = ({ title, profile }) => {
  const lang = useSelector(selectDefaultLang)
  const [t] = useTranslation()
  return (
    <div className={`${style.pageHeader}`}>
      <Container>
        {(profile && profile?.profileData?.role_id === 8) ? (
          <Stack direction="horizontal" gap={2}>
            <div className={`${style.pageTitle}`}>{title}</div>
            <Button
              href={`/${lang}/issuer/dashboard`}
              varient="primary"
              className="ms-auto"
            >
              {t("issuerDashboard")}
            </Button>
          </Stack>
        ) : (
          <div className={`${style.pageTitle}`}>{title}</div>
        )}
      </Container>
    </div>
  );
};
export default PageTitle;
