import React, { useState, useEffect } from "react";
import { Container, Dropdown, DropdownButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import Wire from "../components/paymentType/Wire";
import StripeBox from "../components/paymentType/StripeBox";
import Paypal from "../components/paymentType/Paypal";
import CryptoBox from "../components/paymentType/CryptoBox";
import { useSelector } from "react-redux";
import { selectDefaultLang, selectInvestmentData } from "../app/features/extraReducers";
import { useTranslation } from "react-i18next";

const Thankyou = () => {
  const navigate = useNavigate();
  const lang = useSelector(selectDefaultLang)
  const investmentData = useSelector(selectInvestmentData);
  const [paymentType, setPaymentType] = useState("");
  const [t] = useTranslation();
  const crypto = ["BTC", "ETH", "USDT"];
  
  useEffect(() => {
    if (!investmentData) {
      navigate(`/${lang}/investment-opportunities`);
    }
  }, [investmentData, navigate, lang]);

  let cryptoAddress;
  if (investmentData?.investedCurrency === "BTC")
    cryptoAddress = investmentData?.bitCoinWalletAddress;
  if (investmentData?.investedCurrency === "ETH")
    cryptoAddress = investmentData?.ethereumWalletAddress;
  if (investmentData?.investedCurrency === "USDT")
    cryptoAddress = investmentData?.tetherWalletAddress;

  if (investmentData) {
    return (
      <>
        <PageTitle title={t("thankyou.thankYouHereYouCanFindPaymentInstructions")} />
        <div className="pageContent">
          {crypto.includes(investmentData?.investedCurrency) ? (
            <CryptoBox data={investmentData} address={cryptoAddress} />
          ) : (
            <Container>
              <h2 className="titleText">{t("thankyou.selectPaymentOption")}:</h2>
              <DropdownButton
                className="paymentDropdown"
                title={t("thankyou.selectOption")}
              >
                {investmentData?.paypal === 1 && (
                  <Dropdown.Item onClick={() => setPaymentType("paypal")}>
                    {t("thankyou.paypal")}
                  </Dropdown.Item>
                )}
                {investmentData?.wireTransfer === 1 && (
                  <Dropdown.Item onClick={() => setPaymentType("wire")}>
                    {t("thankyou.wireTransfer")}
                  </Dropdown.Item>
                )}
                {investmentData?.stripe === 1 && (
                  <Dropdown.Item onClick={() => setPaymentType("stripe")}>
                    {t("thankyou.creditCard")}
                  </Dropdown.Item>
                )}
              </DropdownButton>
              {paymentType === "wire" && <Wire data={investmentData} />}
              {paymentType === "paypal" && <Paypal data={investmentData} />}
              {paymentType === "stripe" && <StripeBox data={investmentData} />}
              <hr className="mt-5" />
              <p>
                <small>
                  *{t("disclaimer")}: {t("thankyou.creditCardManagedOutsideEDSXByStripe")}
                  {t("thankyou.informationNotStoredOnOurServers")}
                </small>
              </p>
            </Container>
          )}
        </div>
      </>
    );
  }
};

export default Thankyou;
