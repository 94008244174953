import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Badge,
  ProgressBar,
  Stack,
  Button,
} from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLogged } from "../app/features/auth/authSlice";
import Swal from "sweetalert2";
import style from "./style.module.css";
import { useTranslation } from "react-i18next"
import { selectDefaultLang } from "../app/features/extraReducers";

const PdHeader = ({
  setShowInvestTab,
  isKyced,
  project: {
    projectName,
    type,
    invested,
    goal,
    currency,
    endDate,
    pricePerShare,
  },
}) => {
  const lang = useSelector(selectDefaultLang);
  const [dayLeft, setDayLeft] = useState("");
  const [isInitialRender, setIsInitialRender] = useState(true);
  const isLoggedIn = useSelector(isLogged);
  const progress = Math.ceil((invested * 100) / goal);
  const [t] = useTranslation()

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      let enDate = new Date(endDate);
      let curDate = new Date();
      let difference = enDate.getTime() - curDate.getTime();
      let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
      TotalDays = TotalDays <= 0 ? 0 : TotalDays;
      setDayLeft(TotalDays);
    }
    // eslint-disable-next-line
  }, [dayLeft, isInitialRender]);

  const handleInvestButtonClick = () => {
    const profileUrl = `/${lang}/profile`;
    const signupUrl = `/${lang}/signup`;
    const loginUrl = `/${lang}/login`;
    if (isLoggedIn && isKyced) setShowInvestTab(true);
    else if (isLoggedIn && !isKyced) {
      Swal.fire({
        icon: 'warning',
        html: `<b>${t("beforeYouCanStartInvesting")}</b>`,
        showConfirmButton: true,
        confirmButtonText:
          `<a href=${profileUrl} style="color:#fff">Dashboard</a>`,
        confirmButtonColor: "#222890",
        focusCancel: true,
        showCancelButton: true,
        cancelButtonColor: "#A21B29",
      });
    } else {
      Swal.fire({
        icon: 'warning',
        html: `<b>${t("please")} <a href=${signupUrl}>${t("register")}</a>/<a href=${loginUrl}>${t("login")}</a> ${t("toMakeInvestment")}</b>`,
        showConfirmButton: false,
        focusCancel: true,
        showCancelButton: true,
        cancelButtonColor: "#A21B29",
      });
    };
  };

  return (
    <>
      <div className={`${style.pageHeader}`}>
        <Container>
          <Row>
            <Col lg="5">
              <div className={`${style.pageTitle}`}>{projectName}</div>
              <h5 className={`mt-4 ${style.projectSubHeading}`}>
                {/* <span>
                  <img
                    alt="United Kingdom"
                    src="https://edsx.ch/content/assets/global/img/flags/gb.png"
                  />
                  United Kingdom
                </span>{" "} */}
                <Badge bg="secondary" pill className="text-light">
                  {type}
                </Badge>
              </h5>
            </Col>
            <Col lg="7">
              <div className={`p-3 bg-white shadow mt-lg-1 mt-md-3 mt-sm-3`}>
                <Row className="align-items-center">
                  <Col lg="7" md="7" sm="12" className={`${style.investCol1}`}>
                    <Stack direction="horizontal" gap={2}>
                      <div>
                        <p className="m-0 mb-1">
                          {t("amountRaised")}<br />
                          <b>
                            {invested} {currency}
                          </b>
                        </p>
                      </div>
                      <div className="ms-auto">
                        <p className="m-0 mb-1">
                          {t("target")}
                          <br />
                          <b>
                            {goal} {currency}
                          </b>
                        </p>
                      </div>
                    </Stack>
                    <ProgressBar animated now={progress} className="m-0 mb-1" />
                    <Stack direction="horizontal">
                      <div>
                        <p className="m-0 mb-1">
                          {t("tokenPrice")}{" "}
                          <b>
                            <span className="text-danger">{pricePerShare}</span>{" "}
                            {currency}
                          </b>
                        </p>
                      </div>
                    </Stack>
                  </Col>
                  <Col
                    lg="2"
                    md="2"
                    sm="6"
                    className={`text-center ${style.investCol2}`}
                  >
                    <span className={`${style.daysCount}`}>{dayLeft}</span>
                    <br />
                    {t("daysLeft")}
                  </Col>
                  <Col
                    lg="3"
                    md="3"
                    sm="6"
                    className={`text-center ${style.investCol3}`}
                  >
                    <Button
                      variant="primary"
                      className={`${style.hBtnInvest}`}
                      onClick={() => handleInvestButtonClick()}
                    >
                      {t("investNow")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PdHeader;
