import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setInvestmentData } from "../app/features/extraReducers";
import image from "../assets/img/payment_approved.png";
import { useTranslation } from "react-i18next";

const NftTransferSuccess = () => {
    const dispatch = useDispatch();
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [t] = useTranslation();

    useEffect(() => {
        if (isInitialRender) {
            setIsInitialRender(false);
            dispatch(setInvestmentData(null));
        }
        // eslint-disable-next-line
    }, [isInitialRender]);

    return (
        <>
            <div className="pageContent">
                <Container>
                    <Row className="align-items-center text-center">
                        <Col>
                            <h2>
                                {t("payment.transferOfNft")} <br />
                                {t("payment.finishedCheckOnBlockExplorer")}
                            </h2>
                            <img src={image} alt="payment_approved" className="w-50" />
                            <h4>{t("payment.itIsSafeToLeaveNow")}.</h4>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default NftTransferSuccess