import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isLogged, logout } from "../app/features/auth/authSlice";
import Loader from "../components/Loader";
import { selectDefaultLang } from "../app/features/extraReducers";

const Logout = () => {
  const lang = useSelector(selectDefaultLang)
  const isLoggedIn = useSelector(isLogged)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(logout());
      navigate(`${lang}/login`);
    }
  }, [isLoggedIn, dispatch, navigate, lang]);
  
  return <Loader />
};

export default Logout;
