import React, { useState, useEffect, useRef } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { useActivateAccountMutation } from "../app/features/auth/authApiSlice";
import style from "./style.module.css";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import { selectDefaultLang } from "../app/features/extraReducers";
import { useSelector } from "react-redux";

const VerifyAccount = () => {
  const lang = useSelector(selectDefaultLang)
  const { token } = useParams();
  const [activateAccount, { isLoading }] = useActivateAccountMutation();
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const isInitialRender = useRef(false);
  const [t] = useTranslation();

  const handleSubmit = async () => {
    const data = { token: token };
    try {
      const response = await activateAccount(data).unwrap();
      setSuccessMsg(response?.message);
      setErrMsg(null);
    } catch (error) {
      if (error?.data?.err) setErrMsg(error?.data?.err);
      else setErrMsg(t("error.noServerResponse"));
      setSuccessMsg(null);
    }
  };

  useEffect(() => {
    if (!isInitialRender.current) {
      handleSubmit();
    }
    isInitialRender.current = true;
  });

  if (isLoading) return <Loader />;
  return (
    <Container>
      <Row className="pt-5 pb-5 justify-content-center">
        <Col lg="5" md="8" sm="12">
          <Card className={`${style.loginFormContainer}`}>
            <Card.Header
              className={`m-3 pb-2 text-primary ${style.title} ${style.resetPasswordTitle}`}
            >
              {t("WelcomeEDSX")}
            </Card.Header>
            <Card.Body className="text-center">
              <h5 className={`${errMsg ? "text-danger" : ""}`}>
                {errMsg ? errMsg : successMsg}
              </h5>
              <Link
                to={`/${lang}/login`}
                className={`btn btn-primary mt-3 ${style.btnLogin}`}
              >
                {t("loginNow")}
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default VerifyAccount;
