import React, { useRef, useState } from "react";
import { Container, Row, Col, Form, Table, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGetExchangeProjectQuery } from "../../app/features/secondaryMarketApiSlice";
import Loader from "../../components/Loader";
import ErrorPage from "../../components/ErrorPage";
import TradingViewChartWrap from "../../components/charts/TradingViewChartWrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDefaultLang } from "../../app/features/extraReducers";

const Exchange = () => {
  const timestampRef = useRef(Date.now()).current;
  const lang = useSelector(selectDefaultLang)
  const [searchProject, setSearchProject] = useState("");
  const [marketFilter, setMarketFilter] = useState("");
  const [t] = useTranslation();
  const marketName = [t("invest.ico"), t("invest.sto"), t("invest.nft")];
  const tHeader = [
    t("exchange.projectName"),
    t("exchange.ticker"),
    t("exchange.lastPrice"),
    t("exchange.change24h"),
    t("exchange.marketCap"),
    t("exchange.circulatingSupply"),
    t("exchange.last7Days"),
    "",
  ];

  const { data, isLoading, isSuccess, isError, error } =
    useGetExchangeProjectQuery({
      title: searchProject,
      marketType: marketFilter,
      marketPhase: "",
      sessionId: timestampRef,
    });

  const handelMarketFilter = (event) => {
    if (marketFilter === event?.target?.value) setMarketFilter("");
    else setMarketFilter(event?.target?.value);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="pageHeader">
        <Container>
          <Row className="align-items-center">
            <Col lg="4" sm="12" className="mbsm-3">
              <Form.Control
                placeholder={t("invest.search")}
                onChange={(e) => setSearchProject(e.target.value)}
              />
            </Col>
            <Col lg="4" sm="12"></Col>
            <Col lg="4" sm="12" className="d-flex justify-content-between">
              {marketName.map((item, index) => {
                return (
                  <Form.Check
                    type="switch"
                    key={`marketName${index}`}
                    checked={marketFilter === item ? true : false}
                    onChange={handelMarketFilter}
                    value={item}
                    label={`${item}s`}
                  />
                );
              })}
            </Col>
          </Row>
        </Container>
      </div>
      <div className="pageContent">
        <Container>
          {isError && <ErrorPage error={error?.error} />}
          {isError && <ErrorPage error={error?.data?.error} />}
          {isSuccess && data?.length === 0 && (
            <ErrorPage error={t("invest.sorryNoProjectsToShow")} />
          )}
          {isSuccess && data?.length > 0 && (
            <>
              <Card className="shadow d-none d-md-block">
                <Card.Body>
                  <Table hover striped responsive className="secondary-market ">
                    <thead className="align-middle">
                      <tr>
                        {tHeader.map((item, i) => (
                          <th key={i}>{item}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="align-middle">
                      {data.map((project, i) => (
                        <tr key={i}>
                          <td>
                            <strong>{project.projectName}</strong>
                          </td>
                          <td>{project.ticker}</td>
                          <td>{project.lastPrice}</td>
                          <td className="text-success">
                            {Number(project.change24h).toFixed(2)}
                          </td>
                          <td>{Number(project.marketCap).toFixed(4)}</td>
                          <td>
                            {project.cirulatingSupply ? Number(project.cirulatingSupply).toFixed(4) : 0}{" "}
                            {project.ticker}
                          </td>
                          <td style={{width : 270}}>
                            <div className="m-1">
                              <TradingViewChartWrap
                                height="150"
                                projectId={project.projectId}
                                spinnerType="sm"
                              />
                            </div>
                          </td>
                          <td className="text-center">
                            <Link
                              className="btn btn-primary"
                              to={`/${lang}/exchange/${project.projectSlug}`}
                            >
                              {t("exchange.trade")}
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>

              <div className="d-md-none">
                {data.map((project, i) => (
                  <Card className="mb-3 shadow" key={i}>
                    <Card.Header className="p-0 text-center">
                      <div className="bg-light">
                        <TradingViewChartWrap
                          height="170"
                          projectId={project.projectId}
                          spinnerType="sm"
                        />
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <p className="mb-0">
                        <strong>
                          {tHeader[0]}: {project.projectName}
                        </strong>
                        <br />
                        <strong>{tHeader[1]}:</strong> {project.ticker}
                        <br />
                        <strong>{tHeader[2]}:</strong> {project.lastPrice}
                        <span className="float-end">
                          <strong>{tHeader[3]}:</strong>
                          <span className="text-success">
                            {" "}
                            {Number(project.change24h).toFixed(2)}
                          </span>
                        </span>
                        <br />
                        <strong>{tHeader[4]}:</strong>{" "}
                        {Number(project.marketCap).toFixed(4)}
                        <br />
                        <strong>{tHeader[5]}:</strong>{" "}
                        {project.cirulatingSupply ? Number(project.cirulatingSupply).toFixed(4) : 0}{" "}
                        {project.ticker}
                        <br />
                        <Link
                          className="btn btn-primary"
                          to={`/${lang}/exchange/${project.projectSlug}`}
                        >
                          {t("exchange.trade")}
                        </Link>
                      </p>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default Exchange;
