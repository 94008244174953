import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ dataCount, postPerPage, offset, setOffset }) => {
  const PageCount = Math.ceil(dataCount / postPerPage);
  const handlePageChange = ({ selected }) => setOffset(selected + 1);
  if (PageCount > 1) {
    return (
      <ReactPaginate
        previousLabel="‹"
        nextLabel="›"
        containerClassName="pagination justify-content-center"
        activeClassName="active"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={PageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        breakLabel="..."
        onPageChange={handlePageChange}
        forcePage={offset - 1}
      />
    );
  }
};

export default Pagination;
