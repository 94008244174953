import React, { useEffect } from "react";
import { useLocation } from "react-router";
import AllRoutes from "./routes";

const App = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
      <AllRoutes /> 
  );
}

export default App;
