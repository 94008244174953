import React, { useState } from "react";
import {
  Container,
  Button,
  InputGroup,
  Row,
  Col,
  Card,
  Form,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useCryptoInvestmentConfirmationMutation } from "../../app/features/apisSlice";
import { CopyToClipboard } from "react-copy-to-clipboard";
import style from "../../Forms/style.module.css";
import Swal from "sweetalert2";
import ErrorPage from "../ErrorPage";
import { useTranslation } from "react-i18next";
import { selectDefaultLang } from "../../app/features/extraReducers";
import { useSelector } from "react-redux";

const CryptoBox = (props) => {
  const [loading, setLoading] = useState(false);
  const lang = useSelector(selectDefaultLang)
  const navigate = useNavigate();
  const investmentData = props?.data;
  const setCopy = "";
  const [t] = useTranslation();

  const [cryptoInvestmentConfirmation] =
    useCryptoInvestmentConfirmationMutation();
  const handleConfirmClick = async () => {
    setLoading(true);
    try {
      const response = await cryptoInvestmentConfirmation({
        transactionId: investmentData?.transactionId,
        method: "crypto_investment",
      });
      if (response?.error?.data?.message)
        Swal.fire(response?.error?.data?.message, "", "info");
      if (response?.data?.message) navigate(`/${lang}/payment-success`);
    } catch (err) {
      console.log("Error:", err);
      if (err?.error?.data?.message)
        Swal.fire(err?.error?.data?.message, "", "error");
      else Swal.fire(t("error.noServerResponse"), "", "error");
    }
    setLoading(false);
  };

  return (
    <Container>
      {props?.address ? (
        <>
          <h2 className="titleText">{t("payment.paymentInstructions")}</h2>
          <p>{t("payment.pleaseDepositYourFundsTo")}:</p>
          <Row className="mt-5">
            <Col lg="6" md="6" sm="12">
              <Card className="shadow p-3">
                <Card.Body>
                  <Form.Label className={`${style.formLabel}`}>
                    {t("payment.accountName")}:
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control disabled={true} value={props?.address} />
                    <CopyToClipboard
                      onCopy={() => setCopy()}
                      text={props?.address}
                    >
                      <Button variant="outline-secondary">
                        <i className="fa fa-copy"></i>
                      </Button>
                    </CopyToClipboard>
                  </InputGroup>
                  <Card.Body className="shadow" style={{ width: "185px" }}>
                    {/* <img
                      src={`https://api.qrserver.com/v1/create-qr-code/?data=${props?.address}&size=150x150`}
                      alt="crypto-qrcode"
                    /> */}
                    <img
                      src={`https://quickchart.io/qr?text=${props?.address}&size=150`}
                      alt="crypto-qrcode"
                    />
                  </Card.Body>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="6" md="6" sm="12" className="mt-3 pt-5 ">
              <p className="fs-5 fw-bold">
                {t("payment.project")}: {investmentData?.projectName}
              </p>
              <p className="fs-5 fw-bold">
                {t("payment.investmentAmount")}: {investmentData?.investedAmount}{" "}
                {investmentData?.investedCurrency}
              </p>
              <p>
                {t("payment.clickButtonReceiveEmailWithInstructionsAndSummary")}
              </p>
              <Button onClick={handleConfirmClick}>
                {t("payment.confirmPayment")}{" "}
                {loading && (
                  <Spinner
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                  />
                )}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <ErrorPage error={t("error.walletAddressNotFound")} />
      )}
    </Container>
  );
};

export default CryptoBox;
