import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { logout } from "../app/features/auth/authSlice";

const ErrorPage = ({ error }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (error === "Unauthorized") {
      dispatch(logout());
    }
  }, [error, dispatch]);
  return (
    <>
      <div className="pageContent mx-auto">
        <Container>
          <Row className="align-items-center text-center">
            <Col>
              <h4 className="text-danger">{error}</h4>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ErrorPage;
