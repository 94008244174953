import { apiSlice } from "../api/apiSlice";

const GET_PROJECTS = "/secondary-market/exchange-page";
const SAVE_ORDER = "/secondary-market/order-book-save";
const GET_ORDERS = "/secondary-market/book-data";
const EXECUTE_TRADE = "/secondary-market/trade";
const GET_PERSONAL_BOOK_DATA = "/secondary-market/personal-book-data";
const GET_USER_EUROTOKEN = "/secondary-market/user-eurotoken-details";
const SAVE_TRANSFER_DETAIL = "/secondary-market/eos-transfer-detail-save";
const SAVE_NFT_TRANSFER_DETAIL = "/secondary-market/nft-transfer-detail";
const GET_EXCHANGE_CHART_DATA = "/secondary-market/historical-data";
const GET_OPENORDERS = "/secondary-market/open-orders";
const CANCEL_OPENORDER = "/secondary-market/cancel-trade";
const FREEZE_TOKEN_SAVE_ORDER = "/secondary-market/freeze-token-and-save-order";

export const secondaryMarketApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExchangeProject: builder.query({
      query: (data) => GET_PROJECTS + `?market=${data?.marketType}&title=${data?.title}&marketphase=${data?.marketPhase}`,
    }),
    saveTradeOrder: builder.mutation({
      query: (data) => ({
        url: SAVE_ORDER,
        method: "POST",
        body: { ...data },
      }),
    }),
    getOrders: builder.query({
      query: () => GET_ORDERS,
    }),
    executeTrade: builder.mutation({
      query: (data) => ({
        url: EXECUTE_TRADE,
        method: "POST",
        body: { ...data },
      }),
    }),
    getPersonalBookData: builder.query({
      query: () => GET_PERSONAL_BOOK_DATA,
    }),
    getUserEuroTokenDetails: builder.query({
      query: () => GET_USER_EUROTOKEN
    }),
    saveTransferDetail: builder.mutation({
      query: (data) => ({
        url: SAVE_TRANSFER_DETAIL,
        method: "POST",
        body: { ...data },
      }),
    }),
    saveNftTransferDetail: builder.mutation({
      query: (data) => ({
        url: SAVE_NFT_TRANSFER_DETAIL,
        method: "POST",
        body: { ...data },
      }),
    }),
    getExchangeChartData: builder.query({
      query: ({projectId}) => `${GET_EXCHANGE_CHART_DATA}/${projectId}`
    }),
    getOpenOrders: builder.query({
      query: () => GET_OPENORDERS
    }),
    cancelTradeOrder: builder.mutation({
      query: (data) => ({
        url: CANCEL_OPENORDER,
        method: "POST",
        body: {...data}
      })
    }),
    freezeTokenSaveOrder: builder.mutation({
      query: (data) => ({
        url: FREEZE_TOKEN_SAVE_ORDER,
        method: "POST",
        body: {...data}
      })
    })
  }),
});

export const {
  useGetExchangeProjectQuery,
  useSaveTradeOrderMutation,
  useGetOrdersQuery,
  useExecuteTradeMutation,
  useGetPersonalBookDataQuery,
  useGetUserEuroTokenDetailsQuery,
  useSaveTransferDetailMutation,
  useSaveNftTransferDetailMutation,
  useGetExchangeChartDataQuery,
  useGetOpenOrdersQuery,
  useCancelTradeOrderMutation,
  useFreezeTokenSaveOrderMutation
} = secondaryMarketApiSlice;
