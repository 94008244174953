import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Form,
  Nav,
  Spinner,
} from "react-bootstrap";
import { useResetPasswordMutation } from "../app/features/auth/authApiSlice";
import { useSelector } from "react-redux";
import { isLogged } from "../app/features/auth/authSlice";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { selectDefaultLang } from "../app/features/extraReducers";

const ResetPassword = () => {
  const lang = useSelector(selectDefaultLang);
  const isLoggedIn = useSelector(isLogged);
  if (isLoggedIn) window.location.href = `/${lang}/profile`;
  const { token } = useParams();
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [matchPassword, setMatchPassword] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
  const [resetPassword] = useResetPasswordMutation();
  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setValidMatch(password === matchPassword);
    // eslint-disable-next-line
  }, [password, matchPassword]);

  const [t] = useTranslation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMsg("");
    setErrMsg("");
    const data = {
      token: token,
      newPassword: password,
    };

    try {
      const response = await resetPassword(data).unwrap();
      setSuccessMsg(response?.message);
      setPassword("");
      setMatchPassword("");
    } catch (err) {
      if (!err) setErrMsg(t("error.noServerResponse"));
      else if (err?.data?.error) setErrMsg(err?.data?.error);
      else if (err?.data?.message) setErrMsg(err?.data?.message);
      else setErrMsg(t("error.registrationFailed"));
    }
    setLoading(false);
  };

  let content;
  content = (
    <Container>
      <Row className="pt-5 pb-5 justify-content-center">
        <Col lg="6" md="8" sm="12">
          <Card className={`${style.loginFormContainer}`}>
            <Card.Header
              className={`m-3 pb-2 text-primary ${style.title} ${style.resetPasswordTitle}`}
            >
              {t("resetYourPassword")}
            </Card.Header>
            <Card.Body>
              {successMsg && (
                <p className="text-success text-center" aria-live="assertive">
                  {successMsg}
                </p>
              )}
              {errMsg && (
                <p className="text-danger text-center" aria-live="assertive">
                  {errMsg}
                </p>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label className={`${style.formLabel}`}>
                    {t("password")}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder={t(`enterPassword`)}
                    className={`${style.formControl}`}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    isInvalid={password && !validPassword}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    <i className="fa fa-info-circle"></i> {t("characters")}
                    <br />
                    {t("useLetterNumberCharacter")}
                    <br />
                    {t("specialCharacters")}:{" "}
                    <span aria-label="exclamation mark">!</span>{" "}
                    <span aria-label="at symbol">@</span>{" "}
                    <span aria-label="hashtag">#</span>{" "}
                    <span aria-label="dollar sign">$</span>{" "}
                    <span aria-label="percent">%</span>
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className={`${style.formLabel}`}>
                    {t("re-typePassword")}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder={t("re-typePassword")}
                    className={`${style.formControl}`}
                    onChange={(e) => setMatchPassword(e.target.value)}
                    value={matchPassword}
                    isInvalid={matchPassword && !validMatch}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("enterSamePassword")}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className={`mt-3 ${style.btnLogin}`}
                  disabled={!validMatch || !validPassword ? true : false}
                >
                  {t("resetPassword")}{" "}
                  <Spinner
                    className={`${!loading ? "d-none" : ""}`}
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                  />
                </Button>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Nav className="justify-content-between">
                <Link to={`/${lang}/login`} className="text-danger nav-link">
                  {t("loginNow")}
                </Link>
                <Link
                  to={`/${lang}/signup`}
                  className="ms-sm-auto text-primary nav-link"
                >
                  {t("createAccount")}
                </Link>
              </Nav>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
  return content;
};

export default ResetPassword;
