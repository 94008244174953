import { apiSlice } from "../../api/apiSlice";

const LOGIN_URL = "/user/signin-with-email";
const REGISTER_URL = "/user/signup-with-email";
const FORGOT_PASSWORD = "/user/forgot-password";
const RESET_PASSWORD = "/user/reset-password";
const CHANGE_PASSWORD = "/user/logged-forgot-password";
const ACTIVATE_ACCOUNT = "/user/account-activation";
const FACEBOOK_SIGNUP = "/user/signup-with-facebook"
const FACEBOOK_LOGIN = "/user/signin-with-facebook";
const GOOGLE_SIGNUP = "/user/signup-with-google";
const GOOGLE_LOGIN = "/user/signin-with-google";
const GOOGLE_SIGNUP_SUCCESS = "/user/auth/google/success";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: LOGIN_URL,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: REGISTER_URL,
        method: "POST",
        body: { ...data },
      }),
    }),
    activateAccount: builder.mutation({
      query: (data) => ({
        url: ACTIVATE_ACCOUNT,
        method: "POST",
        body: { ...data },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: FORGOT_PASSWORD,
        method: "POST",
        body: { ...data },
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: RESET_PASSWORD,
        method: "POST",
        body: { ...data },
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: CHANGE_PASSWORD,
        method: "POST",
        body: { ...data },
      }),
    }),
    fbSignup: builder.mutation({
      query: (data) => ({
        url: FACEBOOK_SIGNUP,
        method: "POST",
        body: { ...data },
      }),
    }),
    fbLogin: builder.mutation({
      query: (credentials) => ({
        url: FACEBOOK_LOGIN,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    googleSignup: builder.mutation({
      query: (data) => ({
        url: GOOGLE_SIGNUP,
        method: "POST",
        body: { ...data },
      }),
    }),
    googleSignupSuccess: builder.query({
      query: () => GOOGLE_SIGNUP_SUCCESS
    }),
    googleLogin: builder.mutation({
      query: (credentials) => ({
        url: GOOGLE_LOGIN,
        method: "POST",
        body: { ...credentials },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useActivateAccountMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useFbSignupMutation,
  useFbLoginMutation,
  useGoogleSignupMutation,
  useGoogleSignupSuccessQuery,
  useGoogleLoginMutation,
} = authApiSlice;
