import React, { useState } from "react";
import {
  Button,
  InputGroup,
  Row,
  Col,
  Card,
  Form,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useWireInvestmentConfirmationMutation } from "../../app/features/apisSlice";
import { CopyToClipboard } from "react-copy-to-clipboard";
import style from "../../Forms/style.module.css";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDefaultLang } from "../../app/features/extraReducers";

const Wire = (props) => {
  const lang = useSelector(selectDefaultLang)
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const investmentData = props?.data;
  const setCopy = "";
  const accountName = investmentData?.accountName;
  const accountNumber = investmentData?.accountNumber;
  const iban = investmentData?.iban;
  const swift = investmentData?.swift;
  const [t] = useTranslation();

  const [wireInvestmentConfirmation] = useWireInvestmentConfirmationMutation();
  const handleConfirmClick = async () => {
    setLoading(true);
    try {
      const response = await wireInvestmentConfirmation({
        transactionId: investmentData?.transactionId,
        method: "wire_transfer",
      });
      if (response?.error?.data?.message)
        Swal.fire(response?.error?.data?.message, "", "info");
      if (response?.data?.message)
        navigate(`/${lang}/payment-success`);
    } catch (err) {
      console.log("Error:", err);
      if (err?.error?.data?.message)
        Swal.fire(err?.error?.data?.message, "", "error");
      else Swal.fire(t("error.noServerResponse"), "", "error");
    }
    setLoading(false);
  };

  return (
    <Row className="mt-5">
      <Col lg="6" md="6" sm="12">
        <h5 className="fw-bold">{t("payment.paymentFiatInstruction")}</h5>
        <p>{t("payment.pleaseDepositYourFundsTo")}:</p>
        <Card className="shadow p-3">
          <Card.Body>
            <Form.Label className={`${style.formLabel}`}>
              {t("payment.accountName")}:
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                disabled={true}
                value={accountName ? accountName : null}
              />
              <CopyToClipboard
                text={accountName ? accountName : null}
                onCopy={() => setCopy()}
              >
                <Button variant="outline-secondary">
                  <i className="fa fa-copy"></i>
                </Button>
              </CopyToClipboard>
            </InputGroup>

            <Form.Label className={`${style.formLabel}`}>
              {t("payment.accountNumber")}:
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                disabled={true}
                value={accountNumber ? accountNumber : null}
              />
              <CopyToClipboard
                text={accountNumber ? accountNumber : null}
                onCopy={() => setCopy()}
              >
                <Button variant="outline-secondary">
                  <i className="fa fa-copy"></i>
                </Button>
              </CopyToClipboard>
            </InputGroup>

            <Form.Label className={`${style.formLabel}`}>{t("payment.IBAN")}:</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control disabled={true} value={iban ? iban : null} />
              <CopyToClipboard
                text={iban ? iban : null}
                onCopy={() => setCopy()}
              >
                <Button variant="outline-secondary">
                  <i className="fa fa-copy"></i>
                </Button>
              </CopyToClipboard>
            </InputGroup>

            <Form.Label className={`${style.formLabel}`}>{t("payment.SWIFT/BIC")}:</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control disabled={true} value={swift ? swift : null} />
              <CopyToClipboard
                text={swift ? swift : null}
                onCopy={() => setCopy()}
              >
                <Button variant="outline-secondary">
                  <i className="fa fa-copy"></i>
                </Button>
              </CopyToClipboard>
            </InputGroup>
          </Card.Body>
        </Card>
      </Col>
      <Col lg="6" md="6" sm="12" className="mt-3 pt-5 ">
        <p className="fs-5 fw-bold">{t("payment.project")}: {investmentData?.projectName}</p>
        <p className="fs-5 fw-bold">
          {t("payment.investmentAmount")}: {investmentData?.investedAmount}{" "}
          {investmentData?.investedCurrency}
        </p>
        <p>
          {t("payment.clickButtonReceiveEmailWithInstructionsAndSummary")}
        </p>
        <Button onClick={handleConfirmClick}>
          {t("payment.confirmPayment")}{" "}
          {loading && (
            <Spinner
              variant="light"
              size="sm"
              role="status"
              aria-hidden="true"
              animation="border"
            />
          )}
        </Button>
      </Col>
    </Row>
  );
};

export default Wire;
