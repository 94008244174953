import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next"

const Paypal = (props) => {
  const investmentData = props?.data;
  const [errMsg, setErrMsg] = useState("");
  const [t] = useTranslation();

  const style = {
    layout: "vertical",
    color: "blue",
    shape: "pill",
    label: "pay",
    height: 50,
  };
  const initialOptions = {
    "client-id": `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
    currency: investmentData?.investedCurrency,
  };

  const createOrder = async (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: investmentData?.projectName,
            amount: {
              currency_code: investmentData?.investedCurrency,
              value: investmentData?.investedAmount,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      // console.log(details)
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrMsg(t("error.errorOccuredWithPayment"));
  };

  return (
    <>
      <Row className="mt-5">
        <Col lg="4" md="8" sm="12">
          {errMsg && (
            <p className="text-danger" aria-live="assertive">
              {errMsg}
            </p>
          )}
          <h5 className="fw-bold">{t("payment.project")}: {investmentData?.projectName}</h5>
          <p className="fs-6 fw-bold">
            {t("payment.investmentAmount")}: {investmentData?.investedAmount}{" "}
            {investmentData?.investedCurrency}
          </p>
          <PayPalScriptProvider options={initialOptions} onError={onError}>
            <PayPalButtons
              style={style}
              createOrder={createOrder}
              onApprove={onApprove}
            />
          </PayPalScriptProvider>
        </Col>
      </Row>
    </>
  );
};

export default Paypal;
