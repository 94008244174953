import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect } from "react";

const Layout = () => {
  useEffect(() => {
    const value = window.location.href;
    if(window.self !== window.top && value.indexOf('/project/') > -1) {
      const URL = value.split('project').slice(1)
      localStorage.setItem('EDSXFrame', URL)
    }
  })
  return (
    <>
      {window.self === window.top && <Header />}
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
