import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { selectNftTransfer } from "../app/features/extraReducers";
import { useNavigate } from "react-router-dom";
import AnchorLink from "anchor-link";
import AnchorLinkBrowserTransport from "anchor-link-browser-transport";
import blockchains from "../assets/blockchains.json";
import { selectTradeData } from "../app/features/extraReducers";
import { useSaveNftTransferDetailMutation } from "../app/features/secondaryMarketApiSlice";
import { setTradeData } from "../app/features/extraReducers";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const TransferNft = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const nftData = useSelector(selectNftTransfer);
    // eslint-disable-next-line
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [memo, setMemo] = useState('')
    const [t] = useTranslation();

    // const TransferClick = () => {
    //     const transferObj = {
    //         from,
    //         to,
    //         assetId: nftData.asset_id,
    //         memo
    //     }
    //     dispatch(setTradeData(transferObj))
    // }

    // const anchorData = useSelector(setTradeData).payload.extra.trade

    // blockchain ---------------------------

    const tradeData = useSelector(selectTradeData);
    console.log(tradeData);
    // eslint-disable-next-line
    const [errorMsg, setErrorMsg] = useState(null);
    const [link, setLink] = useState();
    const [identitySession, setIdentitySession] = useState();
    const [sessions, setSessions] = useState();
    const [transactionId, setTransactionId] = useState();

    const transport = new AnchorLinkBrowserTransport();
    const establishLink = async () => {
        const eLink = new AnchorLink({
            transport,
            chains: blockchains.map((b) => ({
                chainId: b.chainId,
                nodeUrl: `${b.rpcEndpoints[0].protocol}://${b.rpcEndpoints[0].host}:${b.rpcEndpoints[0].port}`,
            })),
        });
        const session = await eLink.restoreSession("anchor-link-demo-multipass");
        const sessions = await eLink.listSessions("anchor-link-demo-multipass");

        setIdentitySession(session);
        setSessions(sessions);
        setLink(eLink);
    };

    const addAccount = async () => {
        setErrorMsg(null);
        try {
            // !Use the anchor-link login method with the chain id to establish a session
            const identity = await link.login("anchor-link-demo-multipass");

            //!Retrieve a list of all available sessions to update demo state
            const sessions = await link.listSessions("anchor-link-demo-multipass");

            setIdentitySession(identity.session);
            setSessions(sessions);

            console.log(identity);
        } catch (error) {
            setErrorMsg(error.message);
        }
    };

    const removeSession = async () => {
        let ls = identitySession;
        await link.removeSession("anchor-link-demo-multipass", ls.auth, ls.chainId);

        if (
            identitySession &&
            identitySession.auth.actor.equals(ls.auth.actor) &&
            identitySession.auth.permission.equals(ls.auth.permission)
        ) {
            console.log(identitySession);
            setIdentitySession();
        }
        setSessions(
            sessions.filter(
                (s) =>
                    !(
                        s.auth.actor.equals(ls.auth.actor) &&
                        s.auth.permission.equals(ls.auth.permission)
                    )
            )
        );
        setTransactionId(null);
    };


    const signTransaction = async () => {
        setErrorMsg(null);
        try {
            const action = {
                authorization: [
                    {
                        actor: String(identitySession.auth.actor),
                        permission: String(identitySession.auth.permission),
                    },
                ],
                account: tradeData.actorName,
                name: "transfer",
                data: {
                    from: String(identitySession.auth.actor),
                    to,
                    asset_ids: [tradeData.asset_id],
                    memo,
                },
            };

            // !Call transact on the session (compatible with eosjs.transact)
            const response = await identitySession.transact(
                { actions: [action] }
                // { broadcast: true } // true is only working with EOS token
            );

            // !Update application state with the responses of the transaction
            if (response?.processed?.id) {

                setTransactionId(response?.processed);
            }
            sendTransferDetail(response?.processed);
        } catch (error) {
            setErrorMsg(error.message);
        }
    };

    const [saveTransferDetail] = useSaveNftTransferDetailMutation();
    const sendTransferDetail = async (toSaveData) => {

        setErrorMsg(null);

        let data = {
            transactionObj: toSaveData
        };

        try {
            const transferDetail = await saveTransferDetail(data).unwrap();
            console.log(1, transferDetail);
            dispatch(setTradeData(null));
            navigate('/nft-payment-success')
        } catch (err) {
            if (!err) setErrorMsg(t("error.noServerResponse"));
            else if (err?.data?.message) setErrorMsg(err?.data?.message);
            else setErrorMsg(t("error.failedToAddTheOrder"));
        }
    };


    useEffect(() => {
        if (
            identitySession &&
            String(identitySession.auth.actor) !== tradeData.from
        ) {
            removeSession();
            Swal.fire({
                title: `${t("alert")}`,
                html: `${t("pleaseSignInWith")} <b>${tradeData.from}</b> ${t("accountToCompleteTransaction")}`,
                icon: "error",
            });
        }
        // eslint-disable-next-line
    }, [identitySession, tradeData.from]);

    useEffect(() => {
        establishLink();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="pageContent">
                <Container>
                    <strong><h1>#{nftData.asset_id}</h1></strong>
                    <Row >
                        <Col className="text-left">
                            <div class="card">
                                <div class="card-body">
                                    <img src={nftData.mutable_data[0]} alt="" srcset="" className={"rounded-circle mx-auto d-block"} />
                                    {/* <hr />
                                    <a href={`${nftData}`} class="btn btn-primary mx-auto d-block">VIEW ON BLOCK EXPLORER</a> */}
                                </div>
                            </div>
                        </Col>
                        <Col >
                            <div>
                                {identitySession ? (
                                    <>
                                        <Alert variant="secondary">
                                            <Alert.Heading className="text-primary">
                                                {t("nft.great")}!
                                            </Alert.Heading>
                                            <p>
                                                {t("nft.youAreCurrentlySignedInAs")}{" "}
                                                <Alert.Link
                                                    className="text-primary"
                                                    href={`https://bloks.io/account/${String(
                                                        identitySession.auth.actor
                                                    )}`}
                                                >
                                                    {String(identitySession.auth.actor)}
                                                </Alert.Link>
                                                .
                                            </p>
                                            <hr />
                                            <Form.Control
                                                type="text"
                                                value={tradeData.from}
                                            />
                                            <Form.Control
                                                type="text"
                                                value={to}
                                                onChange={(e) => setTo(e.target.value)}
                                                placeholder={t("nft.senderAccountName")}
                                            />
                                            <Form.Control
                                                type="text"
                                                value={tradeData.asset_id}
                                            />
                                            <Form.Control
                                                type="text"
                                                onChange={(e) => setMemo(e.target.value)}
                                                placeholder={t("nft.enterMemo")}
                                            />
                                            <Button
                                                onClick={signTransaction}
                                                disabled={transactionId ? true : false}
                                            >
                                                {t("nft.signTransaction")}
                                            </Button>{" "}
                                            <Button onClick={removeSession} className="bg-danger">
                                                {t("nft.removeOrChangeAccount")}
                                            </Button>
                                        </Alert>
                                    </>
                                ) : (
                                    <>
                                        <div className={''}>
                                            <div className={"container d-flex align-items-center justify-content-center"}>
                                                <h5>{t("nft.connectToTheAccountInWhichTheNFTIsMinted")}</h5>
                                            </div>
                                            <div className={"container d-flex align-items-center justify-content-center"}>
                                                <Button onClick={addAccount}>
                                                    {t("nft.selectYourAnchorWallet")}
                                                </Button>
                                            </div>
                                        </div>
                                    </>

                                )}
                            </div>
                        </Col>
                    </Row >
                </Container >
            </div >
        </>
    );
};

export default TransferNft;
