import { createSlice } from "@reduxjs/toolkit";

const extraReducer = createSlice({
  name: "extra",
  initialState: { data: null, nftDetails: {}, nftPriceList: {}, lang: "en" },
  reducers: {
    setSocialSignupData: (state, action) => {
      state.social = action.payload;
    },
    setNftTransfer: (state, action) => {
      state.nftDetails = action.payload;
    },
    setInvestmentData: (state, action) => {
      state.invest = action.payload;
    },
    setTradeData: (state, action) => {
      state.trade = action.payload
    },
    setNftPriceList: (state, action) => {
      state.nftPriceList = action.payload
    },
    setNftList: (state, action) => {
      state.nftList = action.payload
    },
    setDefaultLang: (state, action) => {
      state.lang = action.payload
    }
  },
});

export const { setSocialSignupData, setInvestmentData, setTradeData, setNftTransfer, setNftPriceList, setNftList, setDefaultLang } =
  extraReducer.actions;

export default extraReducer.reducer;

export const selectSocialSignupData = (state) => state.extra.social;
export const selectInvestmentData = (state) => state.extra.invest;
export const selectTradeData = (state) => state.extra.trade;
export const selectNftTransfer = (state) => state.extra.nftDetails;
export const selectNftPriceList = (state) => state.extra.nftPriceList;
export const selectNftList = (state) => state.extra.nftList;
export const selectDefaultLang = (state) => state.extra.lang
